import React, { useEffect } from "react";

import "./gamedetailcontent.css";

import ReactTextMoreLess from 'react-text-more-less';
import { useState } from "react";
import { S3_BASE_URL } from "../../helpers/helper";
import offline from '../../assets/images/off_line.png';
import hybrid from '../../assets/images/hy_brid.png';
import online from '../../assets/images/online.png';
import { FaUsersLine } from "react-icons/fa6";

import { cost } from "synonyms/dictionary";

const GameDetailContent = (gameDetail) => {
  const isAdmin = false;
  const [collapsed, setCollapsed] = useState(true);
  const [gameType, setGameType] = useState('');
  const [headCout, setHeadCount] = useState('');
  const [duration, setDuration] = useState('');
  const [gameTpeImg, setGameTypeImg] = useState()

useEffect(()=>{
if(gameType === 'Hybrid'){
  setGameTypeImg(hybrid)
}
else if(gameType === "Offline"){
  setGameTypeImg(offline);

}
else if(gameType === "Online"){
  setGameTypeImg(online)
}
},[gameType])

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

const capitalizeFirstAlpabate = (name) => {
  if (typeof name !== 'string' || name.length === 0) {
    return '';
  }
  let spaceTrimName = name.replace(/\s/g, '');
  return spaceTrimName.charAt(0).toUpperCase() + spaceTrimName.slice(1).toLowerCase();
}

useEffect(()=>{
  if(gameDetail && gameDetail.gameDetail && gameDetail.gameDetail.data){
    let capsLetter = gameDetail.gameDetail.data.gameType ? capitalizeFirstLetter(gameDetail.gameDetail.data.gameType) : '';
    setGameType(capsLetter);
    setHeadCount(gameDetail.gameDetail.data.maxPlayers)
    setDuration(gameDetail.gameDetail.data.duration)
  }
},[gameDetail])

  return (
    <div className="g-detail-content">
      <div className="g-detail-card">
        <h4>About the Game</h4>
        {!isAdmin ? (
          <ReactTextMoreLess
            collapsed={collapsed}
            text={gameDetail && gameDetail.gameDetail && gameDetail.gameDetail.data
              ? gameDetail.gameDetail.data.description
              : ""}
            lessHeight={72}
            className={"about-game"}
            showMoreText="... +Show More"
            showMoreElement={
              <span>
                ... <span className="show-more-text">+Show More</span>
              </span>
            }
            showLessElement={<span className="show-more-text"> -Show Less</span>}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        ) : (
          <>
            <div className="form-group">
              <textarea
                style={{
                  resize: "horizontal",
                  width: "960px",
                  minHeight: "150px",
                  maxWidth: "100%",
                }}
                type="address"
                className="form-control"
                id="address"
                name="address"
                rows="10"
                cols="20"
                maxlength={200}
                placeholder="Enter Address"
                value={"About the game"}
              />
              <div className="btn-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ float: "right", position: "relative" }}
                >
                  Save
                </button>
              </div>
            </div>
            <hr className="seperator" />
          </>
        )}
      </div>
      <div className="g-detail-card">
        <h4>Game Features</h4>
        {!isAdmin ? (
          <ul className="list-arrow list-icon">
            {/* The Split function is used to split te features based on semicolon(.) as planned */}
            {gameDetail && gameDetail.gameDetail && gameDetail.gameDetail.data
              ? gameDetail.gameDetail.data.features
                .split("*")
                .map((feature) => {
                  if (feature !== "")
                    return <li key={JSON.stringify(feature)}>{feature}</li>;
                  return null;
                })
              : ""}
          </ul>
        ) : (
          <>
            <div className="form-group">
              <textarea
                style={{
                  resize: "horizontal",
                  width: "960px",
                  minHeight: "150px",
                  maxWidth: "100%",
                }}
                type="address"
                className="form-control"
                id="address"
                name="address"
                rows="10"
                cols="20"
                maxlength={200}
                placeholder="Enter Address"
                value={"Game Features"}
              />
              <div className="btn-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ float: "right" }}
                >
                  Save
                </button>
              </div>
            </div>
            <hr className="seperator" />
          </>
        )}
        {/* {
          !isAdmin && gameDetail && gameDetail.gameDetail && gameDetail.gameDetail.data ? (
            <div className="game-other-info">
              { gameType && <div className="game-info-item">{`Type: ${gameType} `}
              <img src={gameTpeImg} style={{height:'20px'}}></img>
              </div>}
              {duration && <div className="game-info-item">Duration: {duration} mins</div>}
              {headCout && <div className="game-info-item">{`Head Count: ${headCout} `}
              <FaUsersLine></FaUsersLine>
              </div>}
            </div>
          ) : null
        } */}
        
      </div>
      {/* <div className="g-detail-card objective">
        <h4>Game Objectives</h4>
        {!isAdmin ? (
          <ul className="list-check list-icon">
            
            {gameDetail && gameDetail.gameDetail && gameDetail.gameDetail.data && gameDetail.gameDetail.data.objectives
              ? gameDetail.gameDetail.data.objectives
                  .map((objective) => {
                    
                    if(objective!=="")
                    return <li key={JSON.stringify(objective)}>{objective.title}</li>;
                  })
              : ""}
          </ul>
        ) : (
          <>
            <div className="form-group">
              <textarea
                style={{
                  resize: "horizontal",
                  width: "960px",
                  minHeight: "150px",
                  maxWidth: "100%",
                }}
                type="address"
                className="form-control"
                id="address"
                name="address"
                rows="10"
                cols="20"
                maxlength={200}
                placeholder="Enter Address"
                value={"Game Objectives "}
              />
              <div className="btn-group">
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ float: "right" }}
                >
                  Save
                </button>
              </div>
            </div>
            <hr className="seperator" />
          </>
        )}
      </div> */}
      {
        gameDetail && gameDetail.gameDetail && gameDetail.gameDetail.data
        && gameDetail.gameDetail.data.playedOrganization && gameDetail.gameDetail.data.playedOrganization.length > 0 &&
        <div className="g-detail-card">
          <h4>Played by Other Organisations</h4>
          <div className="org-wrapper">

            {gameDetail && gameDetail.gameDetail
              ? gameDetail.gameDetail.data.playedOrganization.map((orgImage) => {
                return (
                  <div className="org-card">
                    <div className="org-detail">
                      <div className="org-img">
                        <img src={S3_BASE_URL + orgImage} alt="OrgImage" />
                      </div>
                    </div>
                  </div>
                );
              })
              : null}

            {/* <div className="org-desc">
                <h5>Infosys Limited</h5>
                <span>February 26, 2021</span>
              </div> */}

            {/* <div className="org-trophy">
              <img src={trophy} alt="trophy" />
              <span>560</span>
            </div> */}

            {/* <div className="org-card">
            <div className="org-detail">
              <div className="org-img">
                <img src={tata} alt="org name" />
              </div>
              <div className="org-desc">
                <h5>Tata Consultancy Services</h5>
                <span>February 15, 2021</span>
              </div>
            </div>
            <div className="org-desc">
              <h5>Tata Consultancy Services</h5>
            </div>
          </div> */}
          </div>
        </div>
      }
    </div>
  );
};

export default GameDetailContent;
