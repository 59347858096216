import * as ActionTypes from "../constants/demoRequestConstants";
import { axiosApiInstance, BASE_URL, encryptData } from "../../helpers/helper";

const getAllDemoRequests = (searchText) => async (dispatch) => {
  dispatch({ type: ActionTypes.FETCH_DEMO_REQUEST_REQUEST });
  try {
    const { data } = await axiosApiInstance.get(
      BASE_URL + "/api/demo-request-new/all?search=" + searchText
    );
   
    dispatch({
      type: ActionTypes.FETCH_DEMO_REQUEST_SUCCESS,
      payload: data.data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FETCH_DEMO_REQUEST_FAILURE,
      payload: error.message
    });
  }
};





export { getAllDemoRequests };
