import React, { useEffect, useRef, useState } from "react";
import "./modal.css";

import Modal from "./modal";
import loginicon from "../../assets/images/login.svg";
import { useHistory } from "react-router-dom";
import { S3_BASE_URL } from "../../helpers/helper";

import { FaCircleChevronLeft } from "react-icons/fa6";
import { FaCircleChevronRight } from "react-icons/fa6";




const ImgSliderModal = ({ modalid, toggle, setOpenImgSliderModal, startIndex, onClose, images }) => {

    const history = useHistory();

    const [currentIndex, setCurrentIndex] = useState(startIndex);

    const modalRef = useRef(null);

    const videoRef = useRef(null);
    const handleVideoEnded = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    const handleVideoPaused = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener("ended", handleVideoEnded);
            videoRef.current.addEventListener("pause", handleVideoPaused);

        }

        if (videoRef.current) {
            videoRef.current.play();
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener("ended", handleVideoEnded);
            }
        };
    }, [videoRef]);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };
    const handleClick = () => {
        setOpenImgSliderModal(false);
    };


    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose(); // Run the onClose function when clicking outside the modal
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside); // Clean up listener
        };
    }, []);
    return (
        <Modal modalid={modalid} toggle={toggle}>
            <div className="flex bg-white p-[10px] rounded-[6px] w-[700px] h-[650px]"
                ref={modalRef}
            >
                <div className="relative flex flex-col justify-center items-center rounded-[6px] w-full h-full">
                    {/* <button
                        className="top-[-1.5rem] right-2 z-10 absolute text-[43px] text-gray-500 hover:text-gray-800"
                        onClick={onClose}
                    >
                        &times;
                    </button> */}
                    {/* <div className="top-[-10px] right-[-20px] z-10 absolute flex justify-center items-center bg-themeColor rounded-full w-[30px] h-[30px] cursor-pointer" data-dismiss="modal" aria-label="Close"
                        onClick={onClose}
                    >
                        <div className="top-[3px] right-[-6px] close-btn-icon" ></div>
                    </div> */}


                    <div className="relative flex justify-between items-center rounded-[6px] w-full h-full">
                        {/* <button
                            className="left-0 z-10 absolute p-2 text-xl focus:outline-none"
                            onClick={handlePrev}
                        >
                            &larr;
                        </button> */}
                        <FaCircleChevronLeft
                            className="left-0 z-10 absolute p-2 w-[60px] h-[60px] text-[#9e9898ab] text-xl cursor-pointer focus:outline-none"
                            onClick={handlePrev}
                        />

                        <div className="relative z-0 flex justify-center items-center rounded-[6px] w-full h-full overflow-hidden">
                            {/* <img
                                src={images[currentIndex]?.desktopBannerPath}
                                alt={`Image ${currentIndex + 1}`}
                                className="w-full h-full object-contain"
                            /> */}

                            {
                                images[currentIndex]?.desktopBannerPath.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/) ? (
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src={`https://www.youtube.com/embed/${images[currentIndex]?.desktopBannerPath.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)[1]}?controls=1&modestbranding=1&rel=0&showinfo=0&iv_load_policy=3`}
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className="z-0 rounded-md w-full h-full object-fill"
                                    ></iframe>
                                ) : images[currentIndex]?.desktopBannerPath.match(/\.(mp4|webm|ogg)$/i) ? (
                                    // Check if the path is a video URL
                                    <video fetchpriority="high" autoPlay muted disablePictureInPicture id="video_banner" ref={videoRef} style={{ height: '100% !important' }} >
                                        <source
                                            src={S3_BASE_URL + images[currentIndex]?.desktopBannerPath}
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                ) : (
                                    // Fallback to image if it's not a video or YouTube URL
                                    <img
                                        src={S3_BASE_URL + images[currentIndex]?.desktopBannerPath}
                                        alt="Left Section Image"
                                        className="z-0 rounded-md w-full h-full object-fill"
                                    />
                                )
                            }
                        </div>
                        {/* <button
                            className="right-0 z-10 absolute p-2 text-xl focus:outline-none"
                            onClick={handleNext}
                        >
                            &rarr;
                        </button> */}
                        <FaCircleChevronRight
                            className="right-0 z-10 absolute p-2 w-[60px] h-[60px] text-[#9e9898ab] text-xl cursor-pointer focus:outline-none"
                            onClick={handleNext}
                        />
                    </div>
                    <div className="bottom-[15px] z-10 absolute flex justify-center mt-4">
                        {images.map((_, index) => (
                            <span
                                key={index}
                                className={`h-2 w-2 mx-1 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'
                                    }`}
                            ></span>
                        ))}
                    </div>

                </div>
            </div>
        </Modal>
    );
};

export default ImgSliderModal;
