import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";


import {
  organisationAddUserReducer,
  organisationGetUsersReducer,
  organisationLeaveReducer,
  organisationUpdateReducer,
  organisationDeleteUserReducer,
  organisationEditUserReducer,
  organisationDeleteReducer,
  organisationGetAllOrganisationReducer,
  organisationGetUserByOrganisationReducer,
  organisationToggleOrganisationReducer,
  organisationToggleOrgUserReducer,
  organisationContactAdminReducer,
  organisationCreateSampleCSV,
  addOrganisationByAdminReducer,
  getMasterOtpReducer,
  updateMasterOtpReducer,
  addUserDummyEmailReducer,
} from "../reducers/organisationApiReducer"
import {
  userSigninReducer,
  userRegisterReducer,
  userUpdateReducer,
  getUserReducer,
  getFavGamesReducer,
  logOutReducer,
  userGetAllReducer,
  userForgotPasswordReducer,
  userChangePasswordReducer,
  sendOtpReducer,
  verifyOtpReducer,
  deleteUserBulkReducer,
  downloadOrgUsersReducer,
  checkUserReducer,
  activateCompanyReducer,
  getUserPointsInfoReducer
} from "../reducers/userReducer";
import {
  createPollReducer, updatePollReducer, fetchOrgPollReducer, deletePollReducer, voteReducer, getPollReducer, pollResultsReducer, updatePublishedStatusReducer,
  updateResultLiveStatusReducer, livePollsReducer, fetchPollDetailsWithResultsReducer, updateIsDeleteStatusReducer, Admin_Poll_Reducer, FetchPoll_Admin
} from "../reducers/pollsApiReducers";
import {
  homepageAddNewGameReducer,
  homepageAddToFavouriteReducer,
  homepageRemoveFromFavouriteReducer,
  homepageGetAllGamesReducer,
  homepageGetBannerGamesReducer,
  homepageGetGamesByCategoryReducer,
  homepageGetGamesByObjectiveReducer,
  homepageGetGamesByCategoryForBannerReducer,
  homepageGetGamesBySloganReducer,
  homepageSearchV3Reducer,
  homepageSearchReducer,
  homepageUpdateBannerReducer,
  homepageUpdateGameReducer,
  homepageGetSocialLinksReducer,
  homepageUpdateSocialLinksReducer,
  homepageGetDescriptionsReducer,
  homepageUpdateDescriptionsReducer,
  homepagecontactus,
  homepageUpdateGamePosition,
  homepageAddBannerGame,
  homepageDeleteBannerGame,
  homepageUpdateBannerGame,
  homepageGetMostPlayedGamesReducer,
  getSubscribedGamesReducer,
  getCurrentlyPlayingGamesReducer,
  getPreviouslyPlayedGamesReducer,
  getAllSlogansReducer,
  addSloganReducer,
  deleteSloganReducer,
  customizeBannerReducer,
  backToDefaultBannerReducer,
  homepageAddBannerGameBulkReducer,
  homepageUpdateBannerBulkReducer,
  updateSloganBulkReducer
} from "../reducers/homepageReducer";
import { GetAllPointsReducer,GetInfoStreakReducer,GetTasksReducer,GetUserBadges } from "../reducers/pointSystemReducer";
import {
  gameDetailGetGameReducer,
  gameGetAllCategoryReducer,
  getAllPlansReducer,
  getHRIdReducer,
  getPlanDetailsReducer,
  addPurchaseOrderReducer,
  addSubscribedGamesReducer,
  addPlanToUserReducer,
  selectedGamesIdReducer,
  selectedPlanIdReducer,
  addFeedbackReducer,
  organisationGetMyGamesReducer,
  organisationToggleLiveGamesReducer,
  gamesScheduledByMeReducer,
  gamesScheduledByOthersReducer,
  gameGetAllObjectivesReducer,
  gameGetServerOtpReducer,
  gameBulkUpdateReducer,
  gameVerifyReviewExistReducer,
  addCategoryReducer,
  updateCategoryReducer,
  addObjectiveReducer,
  updateObjectiveReducer,
  deleteCategoryReducer,
  deleteObjectiveReducer,
  gameGetRegionReducer,

} from "../reducers/gameDetailReducer";
import { actingOwnerChangeReducer, addDemoRequestReducer, addWebinarReducer, deleteWebinarReducer, downloadChatsReducer, downloadDemoRequestsReducer, downloadEmployeeDatabaseReducer, downloadWebinarParticipantsReducer, getAllWebinarCategoriesReducer, getAllWebinarReducer, getNewTokenReducer, getNotificationsReducer, toggleWebinarAccessReducer, updateWebinarReducer, uploadEmployeeDetailsReducer, uploadFileReducer, webinarChatsBackupReducer, webinarParticipantsBackupReducer, updateSubdomainOrgId, rootReducer, rootTeamsGamesReducer } from "../reducers/commonApiReducer";
import { reportsGetOverallReducer, reportForGameReducer, downloadOverallReportReducer, downloadGameReportReducer, getGameReviewReducer, DownloadGuestSessionReportReducer } from "../reducers/reportsReducers";
import { plansContactUsReducer, plansGetMyPlansReducer, plansDownloadTransactionByIdReducer, addOrgDetailsReducer, getOrganisationByEmailReducer, addPlanAdminReducer, addPlanToOrgByAdminReducer, getOrganisationByIdReducer } from "../reducers/plansApiReducers";
import { offlinePaymentCheckoutReducer, getAllPaymentAdminReducer, ofllinePaymentVerifyReducer, offlienPaymentAdminReducer } from '../reducers/paymentApiReducer';
import { gameCreateSessionReducer, gameDeleteSessionReducer, gameUpdateSessionReducer, getGameSessionsReducer, toggleCreateSessionAccessReducer, getPublicGameSessionsReducer } from "../reducers/sessionsApiReducers";
import { downloadRegisteredUsersReducer, downloadSubscribedUsersReducer, getUsersCountReducer, updateSSOdetailsReducer, getDiwaliClaimReducer, getGameLinksReducer, updateGameLinksReducer, toggleGameLinksActiveStatus, toggleGameLinksActiveReducer, downloadGameLinkReportReducer ,addXoxodayGameReducer, getXoxodayGameReducer, getXoxodayActiveGamesReducer, getUsersPointsReducer,pollQuestionPublishReducer} from "../reducers/adminApiReducers";
import { addWordsListReducer, } from "../reducers/diwaliGameApiReducers";
import { getSSOReducer } from "../reducers/xoxodayApiReducers";
import { getVoteReducer } from "../reducers/voteAPIReducer";
import { getOrgTeamsReducer, getOrgTeamsGameSessionsReducer, getTeamsGamesReport, getAllTeamsReportReducer } from "../reducers/teamApiReducers";
import { getAllOfflineQuotesReducer } from "../reducers/offlineQuoteApiReducer";
import { getFeatureBannersReducer } from "../reducers/featureBannerReducer";
import {getDateFilterReducer, getOrgGamesReportReducer} from "../reducers/dateFilterReducer";
import { getAllOrganizationLogosReducer } from "../reducers/organizationLogoApiReducer";
import { userTestimonialReducer } from "../reducers/userTestimonialReducers";
import { addEventQuestionReducer, getAllEventBookingReducer, getEventQuestionReducer } from "../reducers/bookAnEventReducers";
import formReducer from '../reducers/bookEventFilterReducer'; 
import calendarformReducer from '../reducers/calendarFilterReducer'; 
import {getAllQuarterInfoReducer,getAllCalendarDataReducer ,getOcasionGamesOnId} from "../reducers/calendarReducer";
const reducer = combineReducers({
  getAllTeamsReportsInfo: getAllTeamsReportReducer,
  getTeamsReport:getTeamsGamesReport,
  getUserPointsInfo:getUserPointsInfoReducer,
  getOrgGamesReports: getOrgGamesReportReducer,
  getDateFilterValue:getDateFilterReducer,
  getOrgsTeams: getOrgTeamsReducer,
  getOrgTeamsGameSession: getOrgTeamsGameSessionsReducer,
  offlineQuotes:getAllOfflineQuotesReducer,
  organizationLogos:getAllOrganizationLogosReducer,
  userTestimonial:userTestimonialReducer,
  featureBanners:getFeatureBannersReducer,
  // FetchPolls: FetchPoll_Admin,
  // softDelete: updateIsDeleteStatusReducer,
  // organisationPoll: fetchPollDetailsWithResultsReducer,
  // livePolls: livePollsReducer,
  // updatePublished: updatePublishedStatusReducer,
  // updateResultLive: updateResultLiveStatusReducer,
  // pollResult: pollResultsReducer,
  // vote: voteReducer,
  // getAllVote : getVoteReducer,
  // poll: getPollReducer,
  // deletePoll: deletePollReducer,
  // getAllPolls: fetchOrgPollReducer,
  // updatePole: updatePollReducer,
  // polls: createPollReducer,
  // Admin_Polls: Admin_Poll_Reducer,
  notifications: getNotificationsReducer,
  uploadedFile: uploadFileReducer,
  checkUser: checkUserReducer,
  userGetAll: userGetAllReducer,
  userSignin: userSigninReducer,
  userRegister: userRegisterReducer,
  userUpdate: userUpdateReducer,
  userForgotPassword: userForgotPasswordReducer,
  userChangePassword: userChangePasswordReducer,
  getUser: getUserReducer,
  getFavGames: getFavGamesReducer,
  logOut: logOutReducer,
  allGames: homepageGetAllGamesReducer,
  allPoints:GetAllPointsReducer,
  loginStreak:GetInfoStreakReducer,
  tasks:GetTasksReducer,
  userBadges:GetUserBadges,
  mostPlayedGames: homepageGetMostPlayedGamesReducer,
  addNewGame: homepageAddNewGameReducer,
  bannerGames: homepageGetBannerGamesReducer,
  updateBanner: homepageUpdateBannerReducer,
  addToFavourite: homepageAddToFavouriteReducer,
  removeFromFavourite: homepageRemoveFromFavouriteReducer,
  updateGame: homepageUpdateGameReducer,
  gamesByCategory: homepageGetGamesByCategoryReducer,
  gamesByCategoryForBanner: homepageGetGamesByCategoryForBannerReducer,
  gamesBySlogan: homepageGetGamesBySloganReducer,
  homeSearchV3:  homepageSearchV3Reducer,
  homeSearch: homepageSearchReducer,
  getSocialLinks: homepageGetSocialLinksReducer,
  updateSocialLinks: homepageUpdateSocialLinksReducer,
  getDescription: homepageGetDescriptionsReducer,
  updateDescription: homepageUpdateDescriptionsReducer,
  homeContactUs: homepagecontactus,
  gameDetail: gameDetailGetGameReducer,
  gameAllCategory: gameGetAllCategoryReducer,
  gameAllRegion: gameGetRegionReducer,
  updatePosition: homepageUpdateGamePosition,
  addBannerGame: homepageAddBannerGame,
  bulkAddBannerGame: homepageAddBannerGameBulkReducer,
  deleteBannerGame: homepageDeleteBannerGame,
  updateBannerGame: homepageUpdateBannerGame,
  updateBannerGameBulk: homepageUpdateBannerBulkReducer,
  getAllPlans: getAllPlansReducer,
  getHRId: getHRIdReducer,
  getPlanDetails: getPlanDetailsReducer,
  addPurchaseOrder: addPurchaseOrderReducer,
  addSubscribedGames: addSubscribedGamesReducer,
  addPlanToUser: addPlanToUserReducer,
  selectedGamesId: selectedGamesIdReducer,
  selectedPlanId: selectedPlanIdReducer,
  subscribedGames: getSubscribedGamesReducer,
  overallReports: reportsGetOverallReducer,
  guestSessionReportLink: DownloadGuestSessionReportReducer,///////
  gameReport: reportForGameReducer,
  overAllReportFile: downloadOverallReportReducer,
  gameReportFile: downloadGameReportReducer,
  plansContactUsInfo: plansContactUsReducer,
  getMyPlans: plansGetMyPlansReducer,
  downloadTransactionById: plansDownloadTransactionByIdReducer,
  addFeedback: addFeedbackReducer,
  addOrgDetails: addOrgDetailsReducer,
  offlinePaymentCheckout: offlinePaymentCheckoutReducer,
  getOrganisation: getOrganisationByEmailReducer,
  getOrganisationById: getOrganisationByIdReducer,
  getCurrentlyPlayingGames: getCurrentlyPlayingGamesReducer,
  getPreviouslyPlayedGames: getPreviouslyPlayedGamesReducer,
  orgGetMyGames: organisationGetMyGamesReducer,
  toggleLiveGame: organisationToggleLiveGamesReducer,
  gamesScheduledByMe: gamesScheduledByMeReducer,
  gamesScheduledByOthers: gamesScheduledByOthersReducer,
  updateOrganisation: organisationUpdateReducer,
  leaveOrganisation: organisationLeaveReducer,
  gameSessions: getGameSessionsReducer,
  publicGameSessions: getPublicGameSessionsReducer,
  createSession: gameCreateSessionReducer,
  updateSession: gameUpdateSessionReducer,
  deleteSession: gameDeleteSessionReducer,
  orgUsers: organisationGetUsersReducer,
  deleteOrgUser: organisationDeleteUserReducer,
  addUser: organisationAddUserReducer,
  editOrgUser: organisationEditUserReducer,
  getAllObjectives: gameGetAllObjectivesReducer,
  deleteOrganisation: organisationDeleteReducer,
  gamesByObjective: homepageGetGamesByObjectiveReducer,
  downloadEmployeeDatabase: downloadEmployeeDatabaseReducer,
  uploadEmployeeDatabase: uploadEmployeeDetailsReducer,
  getGameReview: getGameReviewReducer,
  gameServerOtp: gameGetServerOtpReducer,
  allPaymentForAdmin: getAllPaymentAdminReducer,
  gameBulkUpdate: gameBulkUpdateReducer,
  offlinePaymentVerify: ofllinePaymentVerifyReducer,
  allOrganisationData: organisationGetAllOrganisationReducer,
  usersByOrganisation: organisationGetUserByOrganisationReducer,
  toggleOrganisation: organisationToggleOrganisationReducer,
  toggleOrgUser: organisationToggleOrgUserReducer,
  sendOtpToUser: sendOtpReducer,
  verifyOtp: verifyOtpReducer,
  contactOrgAdmin: organisationContactAdminReducer,
  createSamplecsv: organisationCreateSampleCSV,
  checkReviewExist: gameVerifyReviewExistReducer,
  getUsersCount: getUsersCountReducer,
  downloadRegisteredUsers: downloadRegisteredUsersReducer,
  downloadSubscribedUsers: downloadSubscribedUsersReducer,
  getSlogans: getAllSlogansReducer,
  addedSlogan: addSloganReducer,
  deletedSlogan: deleteSloganReducer,
  udpatedSlogans: updateSloganBulkReducer,
  addedOrganisationByAdmin: addOrganisationByAdminReducer,
  addedPlan: addPlanAdminReducer,
  addedPlanToOrg: addPlanToOrgByAdminReducer,
  offlinePaymentAdmin: offlienPaymentAdminReducer,
  toggledSessionAccess: toggleCreateSessionAccessReducer,
  deletedUsers: deleteUserBulkReducer,
  customizedSlogans: customizeBannerReducer,
  backToDefaultBanner: backToDefaultBannerReducer,
  allWebinars: getAllWebinarReducer,
  toggleWebinarInfo: toggleWebinarAccessReducer,
  addedWebinar: addWebinarReducer,
  deletedWebinar: deleteWebinarReducer,
  updatedWebinar: updateWebinarReducer,
  downloadChatsURL: downloadChatsReducer,
  downloadParticipantsURL: downloadWebinarParticipantsReducer,
  addedDemoRequest: addDemoRequestReducer,
  downloadedDemoRequests: downloadDemoRequestsReducer,
  actingOwnerChanged: actingOwnerChangeReducer,
  addedCategory: addCategoryReducer,
  updatedCategory: updateCategoryReducer,
  addedObjective: addObjectiveReducer,
  updatedObjective: updateObjectiveReducer,
  deletedCategory: deleteCategoryReducer,
  deletedObjective: deleteObjectiveReducer,
  participantsBackup: webinarParticipantsBackupReducer,
  chatsBackup: webinarChatsBackupReducer,
  getMasterOtp: getMasterOtpReducer,
  setMasterOtp: updateMasterOtpReducer,
  addUsersDummyEmail: addUserDummyEmailReducer,
  newToken: getNewTokenReducer,
  updatedSSODetails: updateSSOdetailsReducer,
  downloadOrgUsers: downloadOrgUsersReducer,
  companyActiveInfo:activateCompanyReducer,
  allWebinarCategories: getAllWebinarCategoriesReducer,
  subdomainOrgId: updateSubdomainOrgId,
  filter: rootReducer,
  teamsGamesFilters: rootTeamsGamesReducer,
  diwaliGameDetails: addWordsListReducer,
  diwaliClaims: getDiwaliClaimReducer,
  gameLinksInfo: getGameLinksReducer,
  updateGameLinksInfo: updateGameLinksReducer,
  toggleGameLinkActive: toggleGameLinksActiveReducer,
  DDDTGameLinkDownloadReport: downloadGameLinkReportReducer,
  addXoxodayGame:addXoxodayGameReducer,
  xoxoGames: getXoxodayGameReducer,
  SSOLink:getSSOReducer,
  xoxoOrganizationActiveGames: getXoxodayActiveGamesReducer,
  getUsersPoints:getUsersPointsReducer,
  pollPublishQuestion:pollQuestionPublishReducer,
  askEventQuest: addEventQuestionReducer,
  getAllEventQuest: getEventQuestionReducer,
  getAllEventBookings: getAllEventBookingReducer,
  filterFormData:formReducer,
  calendarfilterFormData:calendarformReducer,
  allQuartersInfo:getAllQuarterInfoReducer,
  calendarData:getAllCalendarDataReducer,
  occasionGames:getOcasionGamesOnId,
});

const middleware = [
  thunk,
  process.env.NODE_ENV === "development" && logger
].filter(Boolean);
const store = createStore(reducer, applyMiddleware(...middleware));
// const store = createStore(reducer, applyMiddleware(thunk, logger));

export default store;
