import React, { useEffect, useState } from 'react';

import './leaderboard.css';
import morevertical from '../../assets/images/more-vertical.svg';
import { useSelector } from 'react-redux';
import { REPORT_LEADERBOARD_TABLE_DATA } from '../../constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { BASE_URL, axiosApiInstance } from '../../helpers/helper';
import { BASE_URL, decryptData, encryptData, failureAlert, successAlert } from "../../helpers/helper";
import { center, flex, game, guest, report, state } from 'synonyms/dictionary';
import { useDispatch } from 'react-redux';
import { getGameReport, DownloadGuestSessionReport } from '../../redux/actions/reportsActions';
import axios from 'axios';


const GameTeamsLeaderBoard = ({ teamData }) => {
    const mode = window.location.pathname.split("/")[1]
    const gameSessionReport = useSelector(state => state.gameReport);
    const { gameReport } = gameSessionReport;
    const getRole = { 'ORG_SUPER_ADMIN': 'OWNER', 'ORG_ADMIN': 'ADMIN', 'EMPLOYEE': 'EMPLOYEE', 'USER': 'EMPLOYEE' };
    const [tableHeader, setTableHeader] = useState(REPORT_LEADERBOARD_TABLE_DATA);
    const [expandedRow, setExpandedRow] = useState(null);

    const dispatch = useDispatch();

    const toggleExpandRow = (index) => {
        setExpandedRow(expandedRow === index ? null : index);
    };

    function capitalizeWords(str) {
        if (!str) {
            return '';
        }
        return str.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    }

    function formatTime(timeString) {
        const parts = timeString.split(':').map(Number);
        
        let hours = 0, minutes = 0, seconds = 0;
      
        if (parts.length === 2) {
          [minutes, seconds] = parts;
        } else if (parts.length === 3) {
          [hours, minutes, seconds] = parts;
        } else {
          return '00:00:00';
        }
      
        // Check for NaN values
        if (isNaN(minutes) || isNaN(seconds) || isNaN(hours)) {
          return '00:00:00';
        }
      
        // Adjust for cases where minutes overflow into hours
        hours += Math.floor(minutes / 60);
        minutes = minutes % 60;
      
        return [
          String(hours).padStart(2, '0'),
          String(minutes).padStart(2, '0'),
          String(seconds).padStart(2, '0')
        ].join(':');
      }

    return (
        <div>
            {/* table for desktop */}
            <div className="overflow-x-auto leader-table report-table">
                <table className="leader-board-table">
                    <thead>
                        <tr id='report-tb-head'>
                            <th style={{ display: 'flex' }}>Team Name</th>
                            <th style={{ textAlign: 'start' }}>Team Captain</th>
                            <th>Score</th>
                            <th  className='flex justify-center items-center'>Total Time</th>
                            <th >No of Members</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gameReport && gameReport.data && gameReport.data?.sortReport.length > 0 ? gameReport.data?.sortReport.map((report, index) => (
                            <React.Fragment key={index}>
                                <tr onClick={() => toggleExpandRow(index)} style={{ cursor: 'pointer' }}>
                                    <td style={{
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <div className="table-detail">
                                            <div className="table-desc">
                                                <h4>{report?.name && report?.name !== "" ? capitalizeWords(report?.name) : ''}</h4>
                                            </div>
                                        </div>
                                    </td>

                                    <td style={{
                                        alignItems: "center"
                                    }}>
                                        <div className="table-detail">
                                            <div className="table-desc">
                                                <h4>{report?.teamCaptain?.firstName && report?.teamCaptain?.firstName !== "" ? report?.teamCaptain?.firstName : ''}</h4>
                                            </div>
                                        </div>
                                    </td>

                                    <td>{report?.totalTeamPoints}</td>

                                    <td >
                                        <div className="reports-dropdown-view" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                            {report?.totalTeamTime ? formatTime(report?.totalTeamTime) : '00:00:00'}
                                        </div>
                                    </td>
                                    <td >{report?.members ? report?.members.length : 0}</td>
                                </tr>
                                {expandedRow === index && (
                                    <tr>
                                        <td colSpan="5" className='p-0'>
                                            <div className="expanded-row-content">
                                               
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'start' }}>Member Name</th>
                                                            <th style={{ textAlign: 'start' }}>Member Email</th>
                                                            <th>Member score</th>
                                                            <th >Member time</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {report?.members && report?.members.length > 0 ? report?.members.map((member, idx) => (
                                                            <tr>
                                                                <td style={{ textAlign: 'start' }}>{member.firstName ? `${capitalizeWords(member.firstName)} ${capitalizeWords(member.lastName)}` : ''}</td>
                                                                <td style={{ textAlign: 'start' }}>{member.email}</td>
                                                                <td>{member.totalPoints}</td>
                                                                <td >{member.totalTime ? formatTime(member.totalTime) : '00:00:00'}</td>

                                                            </tr>
                                                        )) : <tr>
                                                            <td colSpan={4}>No Memebers found</td>
                                                        </tr>}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        )) : null}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default GameTeamsLeaderBoard;