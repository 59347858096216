import React, { useEffect, useState } from "react";
import "./analytics.css";
import { Link } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';

import { useDispatch, useSelector } from "react-redux";
import PageLayout from "../../components/pagelayout/pagelayout";
import { failureAlert, IsAdmin, successAlert, axiosApiInstance, BASE_URL } from "../../helpers/helper";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { logOut } from "../../redux/actions/userAction";
import AnalyticsCard from "../../components/Analytics/AnalyticsCard";
import { downloadRegisteredUsers, downloadSubscribedUsers, getUsersCount } from "../../redux/actions/adminApiActions";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer } from "react-toastify";
import { downloadDemoRequests } from "../../redux/actions/commonActions";
import moment from "moment";
import Select from "react-select";
import { downloadFile } from "../../helpers/downloadFile";
import { CSVLink } from "react-csv";
import axios from "axios";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
);

const Analytics = (props) => {


    const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];

    const doughnutOptions = {
        cutout: 50,
        plugins: {
            legend: {
                position: 'bottom',
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            },
            title: {
                display: true,
                text: 'Active Users',
            },
        },
    };

    const options = {
        maintainAspectRatio: false,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        plugins: {
            legend: {
                position: 'bottom',
                backgroundColor: 'rgba(53, 162, 235, 0.5)'
            },
            title: {
                display: true,
                text: 'Game Played',
            },
        },
    };

    const dispatch = useDispatch();
    const isAdmin = IsAdmin();
    const [loaded, setLoaded] = useState(true);
    const [gamePlayedCount, setGamePlayedCount] = useState('');
    const [activeUserCount, setActiveUserCount] = useState('');
    const [gameCountByUser, setGameCountByUser] = useState([]);
    const [gameCountByDate, setGameCountByDate] = useState([]);
    const [activeUserCountWithFilter, setActiveUserCountWithFilter] = useState();
    const [dateFilter, setDateFilter] = useState({ startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });
    const [buttonSelected, setButtonSelected] = useState(1);
    const [organizationFilter, setOrganizationFilter] = useState({ organizationName: 'All Organization', organizationId: 'all' });
    const [gameFilter, setGameFilter] = useState({ title: 'All Game', id: 'all' });
    const [customDate, setCustomDate] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [gameList, setGameList] = useState([]);
    const [gameCountByDateArray, setGameCountByDateArray] = useState([]);
    const [notificationDetails, setNotificationDetails] = useState({ message: "", userType: null, redirectLink: "" });

    const GetUsersCount = useSelector(state => state.getUsersCount);
    const { usersCount } = GetUsersCount;
    const DownloadRegisteredUsers = useSelector(state => state.downloadRegisteredUsers);
    const DownloadSubscribedUsers = useSelector(state => state.downloadSubscribedUsers);
    const DownloadDemoRequests = useSelector(state => state.downloadedDemoRequests);

    const userTypes = [
        { label: "User", value: "USER" },
        { label: "Employee", value: "EMPLOYEE" },
        { label: "Admin", value: "ORG_ADMIN" },
        { label: "Owner", value: "ORG_SUPER_ADMIN" },
    ]

    const doughnutData = {
        labels: ["New User", "Old User"],
        datasets: [
            {
                label: "# of Votes",
                data: [activeUserCountWithFilter?.[1], (activeUserCountWithFilter?.[0] - activeUserCountWithFilter?.[1])],
                backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                ],
                borderColor: [
                    "#e25569",
                    "#e25569",
                    "#e25569",
                ],
                borderWidth: 1,
            }
        ]
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: 'No Of Game Played',
                data: gameCountByDateArray,
                backgroundColor: '#d18e8e',
                borderRadius: 3,
                borderSkipped: false,
                borderColor: '#966363',
                borderWidth: 2,
            },
        ],
    };

    useEffect(() => {
        if (!gameCountByDate) {
            return;
        }
        let currData = Array(31).fill(0);
        labels.map((data, i) => {
            if (gameCountByDate[i]?.createdAt?.split('-')[2]) {
                currData[gameCountByDate[i]?.createdAt?.split('-')[2] - 1] = gameCountByDate[i]?.count;
            }
        });
        setGameCountByDateArray(currData);
    }, [gameCountByDate])

    useEffect(() => {
        dispatch(getUsersCount());
        window.socket?.on("addNotification", (data) => {
            successAlert("Notification sent");
        })
    }, [window.socket]);

    const getActiveUserByDate = () => {
        axiosApiInstance.get(BASE_URL + "/api/dashboard/getGoogleToken").then(data => {
            let token = data?.data;
            axios({
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: {
                    "reportRequests": [
                        {
                            "viewId": "256899622",
                            "dateRanges": [
                                {
                                    "startDate": dateFilter?.startDate,
                                    "endDate": dateFilter?.endDate
                                }
                            ],
                            "metrics": [
                                {
                                    "expression": "ga:users"
                                },
                                {
                                    "expression": "ga:newUsers"
                                }
                            ]
                        }
                    ]
                },
                url: 'https://analyticsreporting.googleapis.com/v4/reports:batchGet',
            })
                .then((response) => {
                    setActiveUserCountWithFilter(response?.data?.reports?.[0]?.data?.totals?.[0]?.values);
                });
        });
    };

    const getGameCountByUser = () => {
        let { startDate, endDate } = dateFilter;
        axiosApiInstance.get(BASE_URL + `/api/dashboard/getGameCountByUser/${organizationFilter?.organizationId}/${gameFilter?.id}/${startDate}/${endDate}`).then(data => {
            setGameCountByUser(data?.data?.data);
        })
    };

    const getGamePlayedByDate = () => {
        let { startDate, endDate } = dateFilter;
        axiosApiInstance.get(BASE_URL + `/api/dashboard/getGamePlayedByDate/${organizationFilter?.organizationId}/${gameFilter?.id}/${startDate}/${endDate}`).then(data => {
            setGameCountByDate(data?.data?.data);
        });
    }

    useEffect(() => {
        axiosApiInstance.get(BASE_URL + "/api/dashboard/totalGamePlayedCount").then(data => {
            setGamePlayedCount(data?.data?.count);
        });
        axiosApiInstance.get(BASE_URL + "/api/organization/all?search=&sendAll=true").then(data => {
            setOrganizationList(data?.data?.data?.allOrganisationDetails);
        });
        axiosApiInstance.get(BASE_URL + "/api/game/default/all/filter").then(data => {
            setGameList(data?.data?.data);
        });

        getGameCountByUser();
        getGamePlayedByDate();
        axiosApiInstance.get(BASE_URL + "/api/dashboard/getGoogleToken").then(data => {
            let token = data?.data;
            axios({
                method: 'post',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                data: {
                    "reportRequests": [
                        {
                            "viewId": "256899622",
                            "dateRanges": [
                                {
                                    "startDate": "1daysAgo",
                                    "endDate": "today"
                                }
                            ],
                            "metrics": [
                                {
                                    "expression": "ga:users"
                                },
                                {
                                    "expression": "ga:newUsers"
                                }
                            ]
                        }
                    ]
                },
                url: 'https://analyticsreporting.googleapis.com/v4/reports:batchGet',
            })
                .then((response) => {
                    setActiveUserCount(response?.data?.reports?.[0]?.data?.totals?.[0]?.values);
                });
        });
        getActiveUserByDate();
    }, []);

    useEffect(() => {
        getActiveUserByDate();
        getGameCountByUser();
        getGamePlayedByDate();
    }, [dateFilter])

    const onSubmitFilter = () => {
        getActiveUserByDate();
        getGameCountByUser();
        getGamePlayedByDate();
    };

    const onResetFilter = () => {
        setCustomDate(false);
        setDateFilter({ startDate: moment().subtract(1, 'day').format('YYYY-MM-DD'), endDate: moment().format('YYYY-MM-DD') });
        setOrganizationFilter({ organizationName: 'All Organization', organizationId: 'all' });
        setGameFilter({ title: 'All Game', id: 'all' });
        setButtonSelected(1);
        getActiveUserByDate();
        getGameCountByUser();
        getGamePlayedByDate();
    };

    useEffect(() => {
        if (
            (GetUsersCount && GetUsersCount.loading) ||
            (DownloadRegisteredUsers && DownloadRegisteredUsers.loading) ||
            (DownloadSubscribedUsers && DownloadSubscribedUsers.loading) ||
            (DownloadDemoRequests && DownloadDemoRequests.loading)
        ) {
            setLoaded(false);
        }
        else {
            setLoaded(true);
        }
    }, [GetUsersCount, DownloadRegisteredUsers, DownloadSubscribedUsers, DownloadDemoRequests]);
    const downloadSubscribedUsersData = async () => {
        const response = await dispatch(downloadSubscribedUsers());
        if (response && response.data && response.data.data && response.data.data.downloadUrl) {
            downloadFile(response.data.data.downloadUrl);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }
    const downloadRegisteredUsersData = async () => {
        const response = await dispatch(downloadRegisteredUsers());
        if (response && response.data && response.data.data && response.data.data.downloadUrl) {
            downloadFile(response.data.data.downloadUrl);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }
    const downloadDemoRequestsData = async () => {
        const response = await dispatch(downloadDemoRequests());
        if (response && response.data && response.data.data && response.data.data.downloadUrl) {
            downloadFile(response.data.data.downloadUrl);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }
    const signOut = async () => {
        await dispatch(logOut());
        if (isAdmin) props.history.push("/admin");
        else props.history.push("/");
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setNotificationDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleSendNotifications = (e) => {
        e.preventDefault();
        if (window.socket) {
            setNotificationDetails({ message: "", userType: null, redirectLink: "" });
            window.socket.emit("addNotification", notificationDetails);
        }
        else {
            failureAlert("Something went wrong!");
        }
    }
    return (
        <div className='admin-homepage'>
            <PageLayout
                sidebartitle=""
                active={"Analytics"}
                sideBarContents={sidebarContentAdmin}
                profile
                {...props}
                signOut={signOut}
                isAdmin={isAdmin}
            >
                <LoadingComponent loaded={loaded} />
                <ToastContainer position="bottom-center" />
                <div className="analytics-container">
                    <div className="">
                        <span className="font-bold text-3xl text-gray-800">Analytics</span>
                    </div>
                    {/* <div className="download-btn-grp">
                        <button className="btn btn-primary" onClick={downloadDemoRequestsData}>Download Demo Requests</button>
                        <button className="btn btn-primary" onClick={downloadRegisteredUsersData}>Download Registered Users Data</button>
                        <button className="btn btn-primary" onClick={downloadSubscribedUsersData}>Download Subscribed Users Data</button>
                    </div> */}
                    <div className="analyticscard-container">
                        <AnalyticsCard title="Total Registered Users" value={
                            <div className="grid grid-cols-2">
                                <div className="font-bold text-5xl text-gray-600">{usersCount?.data?.registeredUsers ? usersCount?.data?.registeredUsers : 0}</div>
                                <div className="text-right justify-self-end">
                                    <svg onClick={downloadRegisteredUsersData} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg>
                                </div>
                            </div>
                        } />
                        <AnalyticsCard title="Total Subscribers" value={
                            <div className="grid grid-cols-2">
                                <div className="font-bold text-5xl text-gray-600">{usersCount?.data?.subscribedUsers ? usersCount?.data?.subscribedUsers : 0}</div>
                                <div className="text-right justify-self-end">
                                    <svg onClick={downloadSubscribedUsersData} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg>
                                </div>
                            </div>
                        } />
                        <AnalyticsCard title="Total Game Played" value={<div className="font-bold text-5xl text-gray-600">{gamePlayedCount ? gamePlayedCount : 0}</div>} />
                        <AnalyticsCard title="Today Active User" value={<div className="font-bold text-5xl text-gray-600">{activeUserCount ? activeUserCount[0] : 0}</div>} />
                        <AnalyticsCard title="" value={
                            <div className="grid grid-cols-2">
                                <div className="font-bold text-2xl text-gray-600">Demo <br />Requests</div>
                                <div className="text-right justify-self-end">
                                    <svg onClick={downloadRegisteredUsersData} style={{ height: '40px', margin: '4px', cursor: 'pointer' }} fill="#e25569" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M214.6 342.6L192 365.3l-22.6-22.6-128-128L18.7 192 64 146.7l22.6 22.6L160 242.7V64 32h64V64 242.7l73.4-73.4L320 146.7 365.3 192l-22.6 22.6-128 128zM32 416H352h32v64H352 32 0V416H32z" /></svg>
                                </div>
                            </div>
                        } />
                    </div>
                    <div className="grid lg:grid-cols-12 mt-6">
                        {customDate ? (
                            <>
                                <div className="col-span-2 mx-2">
                                    <input style={{ padding: '7px 7px 7px 7px' }} type="month" onChange={(e) => setDateFilter({ startDate: moment(e.target.value).startOf('months').format('YYYY-MM-DD'), endDate: moment(e.target.value).endOf('months').format('YYYY-MM-DD') })} id="default-input" className="block border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 border focus:border-blue-500 dark:focus:border-blue-500 rounded focus:ring-blue-500 dark:focus:ring-blue-500 w-full text-gray-900 text-sm dark:text-white dark:placeholder-gray-400" />
                                </div>
                                {/* <div className="col-span-2 mx-2">
                                    <input style={{ padding: '7px 7px 7px 7px' }} type="date" onChange={(e) => setDateFilter({ ...dateFilter, endDate: moment(e.target.value).format('YYYY-MM') })} value={dateFilter?.endDate} id="default-input" className="block border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-gray-700 border focus:border-blue-500 dark:focus:border-blue-500 rounded focus:ring-blue-500 dark:focus:ring-blue-500 w-full text-gray-900 text-sm dark:text-white dark:placeholder-gray-400" />
                                </div> */}
                            </>
                        ) : (
                            <div className="col-span-5">
                                <button onClick={() => { setButtonSelected(1); setDateFilter({ startDate: moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'), endDate: moment().subtract(1, 'day').endOf('day').format('YYYY-MM-DD') }); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 1) && 'bg-red-500 text-gray-100'}`}>Today</button>
                                <button onClick={() => { setButtonSelected(2); setDateFilter({ startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'), endDate: moment().endOf('isoWeek').format('YYYY-MM-DD') }); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 2) && 'bg-red-500 text-gray-100'}`}>This Week</button>
                                <button onClick={() => { setButtonSelected(3); setDateFilter({ startDate: moment().startOf('months').format('YYYY-MM-DD'), endDate: moment().endOf('months').format('YYYY-MM-DD') }); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 3) && 'bg-red-500 text-gray-100'}`}>This Month</button>
                                <button onClick={() => { setButtonSelected(4); setCustomDate(true); }} className={`bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-1.5 mx-1 px-4 border border-red-500 hover:border-transparent rounded ${(buttonSelected === 4) && 'bg-red-500 text-gray-100'}`}>Custom Month</button>
                            </div>
                        )}
                        <div className="col-span-2 mx-2">
                            <Select
                                classNamePrefix="react-select"
                                className=""
                                options={[...organizationList, { organizationName: 'All Organization', organizationId: 'all' }]}
                                menuPlacement={"auto"}
                                isMulti={false}
                                onChange={(value) => setOrganizationFilter(value)}
                                value={organizationFilter}
                                getOptionLabel={(option) => option.organizationName}
                                getOptionValue={(option) => option.organizationId}
                            />
                        </div>
                        <div className="col-span-2 mx-2">
                            <Select
                                classNamePrefix="react-select"
                                className=""
                                options={[...gameList, { title: 'All Game', id: 'all' }]}
                                menuPlacement={"auto"}
                                isMulti={false}
                                onChange={(value) => setGameFilter(value)}
                                value={gameFilter}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.id}
                            />
                        </div>
                        <div className="col-span-3 mx-4">
                            <button onClick={() => onSubmitFilter()} className="bg-transparent hover:bg-red-500 mx-2 px-4 py-1.5 border hover:border-transparent border-red-500 rounded font-semibold text-red-500 hover:text-white">APPLY</button>
                            <button onClick={() => onResetFilter()} className="bg-transparent hover:bg-red-500 mx-2 px-4 py-1.5 border hover:border-transparent border-red-500 rounded font-semibold text-red-500 hover:text-white">RESET</button>
                        </div>
                    </div>
                    <div className="grid lg:grid-cols-12">
                        <div className="justify-self-start col-span-7" style={{ margin: '40px 0px 40px 0px' }}>
                            <Bar style={{ height: '400px' }} options={options} data={chartData} />
                        </div>
                        <div className="justify-self-end col-span-5" style={{ margin: '80px 0px 40px 20px' }}>
                            <Doughnut
                                data={doughnutData}
                                options={doughnutOptions}
                            />
                            <div className="justify-center grid bg-green-200 border border-red-400">TOTAL : {activeUserCountWithFilter?.[0]}</div>
                        </div>
                    </div>
                    <div className="sm:-mx-6 lg:-mx-8 mt-4 overflow-x-auto">
                        <div className="inline-block sm:px-6 lg:px-8 py-2 min-w-full align-middle">
                            <div className="border-4 border-red-200 sm:rounded-lg overflow-hidden" style={{ maxHeight: '490px', overflowY: "scroll" }}>
                                <table className="min-w-full text-gray-100 text-sm">
                                    <thead className="top-0 sticky bg-red-400 font-medium text-xs uppercase">
                                        <tr className="bg-black bg-opacity-20">
                                            <th></th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                User Name
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Email
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Phone
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Onboard Type
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                Role
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left tracking-wider">
                                                No Game Played
                                            </th>
                                        </tr>
                                    </thead>
                                    {/* <div style={{ height: '200px', overflowX: "scroll" }}> */}
                                    <tbody className="bg-red-300 divide-y divide-red-300 h-2" >
                                        {
                                            (gameCountByUser?.length > 1) ? gameCountByUser.map((data, i) => (
                                                <tr className="bg-black bg-opacity-20">
                                                    <td className="pl-4">
                                                        {i + 1}
                                                    </td>
                                                    <td className="flex px-6 py-4 whitespace-nowrap">
                                                        <img className="w-5" src="https://www.cornwallbusinessawards.co.uk/wp-content/uploads/2017/11/dummy450x450.jpg" alt="" />
                                                        <span className="ml-2 font-medium">{data?.firstName}{' '}{data?.lastName}</span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.email}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.phoneNumber}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.onBoardType}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.role}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {data?.count}
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td className="p-4 text-center" colspan="8">NO DATA</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                    {/* </div> */}
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="text-right mb-4">
                        {(gameCountByUser?.length > 1) && <button className="bg-red-300 p-2 rounded"><CSVLink data={gameCountByUser}>Download CSV</CSVLink></button>}
                    </div>
                    {/* <div className="send-notifications">
                        <h4>Send Notifications</h4>
                        <form onSubmit={handleSendNotifications}>
                            <div className="form-group">
                                <input type="text" placeholder="Enter message to send" value={notificationDetails.message} name="message" onChange={handleChange} className="form-field" />
                            </div>
                            <div className="form-group">
                                <input type="text" placeholder="Enter redirect link" value={notificationDetails.redirectLink} name="redirectLink" onChange={handleChange} className="form-field" />
                            </div>
                            <div className="form-group">
                                <Select
                                    classNamePrefix="react-select"
                                    className="form-select"
                                    options={userTypes}
                                    menuPlacement={"auto"}
                                    isMulti
                                    onChange={(e) => handleChange({ target: { name: "userType", value: e } })}
                                    placeholder="Select User Type"
                                    value={notificationDetails.userType}
                                // onBlur={() => handleBlur({ target: { name: "category" } })}
                                />
                            </div>
                            <button className="btn btn-primary">Send</button>
                        </form>
                    </div> */}
                </div >
            </PageLayout >
        </div >
    )
}
export default Analytics;