import React, { useEffect, useState } from 'react';
// import Progress from 'react-progressbar';
import { useDispatch, useSelector } from 'react-redux';
import userTypes, { OrgRoles } from '../../helpers/userTypes';
import EventBookingHistory from './EventBookingHistory';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet-async';


const BookingTransactionHistory = (props) => {
    const dispatch = useDispatch();

    return (
        <div className="!max-w-full plandetail-card">
            <Helmet>
                <title>Event Booking Transaction History</title>
                <meta name="description" content="Event Booking Transaction History" />
                <meta name="keywords" content="Event Booking Transaction History" />
            </Helmet>
            <ToastContainer position='bottom-center' />
            {/* transaction history */}
            <h4 className='font-sans font-semibold text-[20px] sm:text-[23px] md:text-[24px] lg:text-[25px]'>Event Booking Transaction History</h4>

            {(props.userType === userTypes.ORG_SUPER_ADMIN || props.userType === userTypes.ORG_ADMIN) && <div className="transaction-table-sect">
                <EventBookingHistory />
            </div>}

        </div>
    );
};

export default BookingTransactionHistory;
