export const GET_ORG_TEAMS_REQUEST = "GET_ORG_TEAMS_REQUEST";
export const GET_ORG_TEAMS_SUCCESS = "GET_ORG_TEAMS_SUCCESS";
export const GET_ORG_TEAMS_FAIL = "GET_ORG_TEAMS_FAIL";

export const GET_ORG_TEAM_SESSION_REQUEST = "GET_ORG_TEAM_SESSION_REQUEST";
export const GET_ORG_TEAM_SESSION_SUCCESS = "GET_ORG_TEAM_SESSION_SUCCESS";
export const GET_ORG_TEAM_SESSION_FAIL = "GET_ORG_TEAM_SESSION_FAIL";

export const GET_TEAMS_GAMES_REPORT_REQUEST = "GET_TEAMS_GAMES_REPORT_REQUEST";
export const GET_TEAMS_GAMES_REPORT_SUCCESS = "GET_TEAMS_GAMES_REPORT_SUCCESS";
export const GET_TEAMS_GAMES_REPORT_FAIL = "GET_TEAMS_GAMES_REPORT_FAIL";

export const GET_ALL_TEAMS_REPORT_REQUEST = "GET_ALL_TEAMS_REPORT_REQUEST";
export const GET_ALL_TEAMS_REPORT_SUCCESS = "GET_ALL_TEAMS_REPORT_SUCCESS";
export const GET_ALL_TEAMS_REPORT_FAIL = "GET_ALL_TEAMS_REPORT_FAIL";