import React, { useState } from "react";
import PageLayout from "../../components/pagelayout/pagelayout";

import LoadingComponent from "../../components/loader/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./index.css";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
// import PaymentDetailsModal from "../modal/paymentDetailsModal";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { useEffect } from "react";
import { getAllPaymentForAdmin } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { failureAlert, IsAdmin, successAlert } from "../../helpers/helper";

import { SYMBOLS } from "../../constants";
import { getAllDemoRequests } from "../../redux/actions/demoRequestApiActions";

const DemoRequest = (props) => {
  const useSearch = () => useDebouncedSearch((text) => searchDetails(text));
  const customStyles = {
    headCells: {
      style: {
        "font-family": "firaSans-semibold",
        "font-size": "15px",
        "border-bottom": "1px solid Black",
        "border-top": "1px solid Black"
      }
    },
    cells: {
      style: {
        "font-family": "firaSans-regular",
        "font-size": "14px",
        "text-align": "center"
      }
    },
    header: {
      style: {
        "font-family": "firaSans-semibold",
        color: "#737373"
      }
    }
  };
  const searchDetails = (text) => {
    dispatch(getAllDemoRequests(text));
  };
  const { searchText, setSearchText, results } = useSearch();
  const demoRequestData = useSelector(
    (state) => state.demoRequest.demoRequests
  );
  const isLoading = useSelector((state) => state.demoRequest.loading);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const [detailsState, setDetailState] = useState({});
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [offdata, setTransactionData] = useState([]);
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [loaded, setLoaded] = useState(true);

 


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); 
    const year = date.getFullYear();
  
    // Get the time in 12-hour format with AM/PM
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hours}:${minutes} ${ampm}`;
  
    // Format the final string
    return `${month} ${day}, ${year}, ${formattedTime}`;
  };
  
 


  useEffect(() => {
    dispatch(getAllDemoRequests(""));
  }, []);

  useEffect(() => {
  
    if ((demoRequestData && isLoading) || isLoading) {
      setLoaded(false);
    } else setLoaded(true);
  }, [demoRequestData, isLoading]);
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };


  const columns = [
  
    {
      name: "First Name",
      // selector: "id",
      selector: (row) => row.firstName,
      center: true
    },
    {
      name: "Last Name",
      // selector: "companyName"
      selector: (row) => row.lastName
    },
    {
      name: "Email ",
      // selector: "companyName"
      selector: (row) => row.email
    },
    {
      name: "Phone  ",
      // selector: "companyName"
      selector: (row) => row.phone
    },
    {
      name: "Company Name ",
      // selector: "companyName"
      selector: (row) => row.companyName
    },

    {
      name: "Created Date ",
      selector: (row) => formatDate(row.createdAt)
    }
  ];
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  return (
    <div className="admin-homepage">
      <PageLayout
        sidebartitle=""
        active={"Demo Requests"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        {...props}
        signOut={signOut}
        {...props}
        isAdmin={isAdmin}
      >
        <LoadingComponent loaded={loaded} />
        <div className="container-payment">
          <div className="payment">
            <h1 className="title">Demo Request Details</h1>
          </div>
        </div>
    
        <div className="search-bar-container">
          <div className="input-icon search-bar">
            <img src={search} alt="search" />
            <input
              type="text"
              name="search"
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
            />
          </div>

          {/* <button
            onClick={handleDownload}
            className="btn btn-primary play-game-btn"
          >
            Download CSV
          </button> */}
        </div>
      
        <div className="data-table">
          <DataTable
            title="Demo Requests"
            columns={columns}
            // onRowClicked={handleChange}
            data={demoRequestData} // add for checkbox selection
            className="rdt_TableHead"
            responsive="true"
            striped="true"
            highlightOnHover="true"
            pagination="true"
            customStyles={customStyles}
          />
      
        </div>
       
      </PageLayout>
    </div>
  );
};

export default DemoRequest;
