import React, { useEffect, useState, useRef } from "react";

import PageLayout from '../../components/pagelayout/pagelayout';
import ScheduledGames from '../../components/scheduledGames/scheduledGames';
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import MyFavourites from "../../components/myFavourites/myFavourites";
import { sidebarContentScheduledByOthers, sideBarContentMyFavourites, sidebarContentScheduledByMe } from "../../helpers/sidebarContentMyGames";
import { logOut } from "../../redux/actions/userAction";
import Roles from '../../helpers/userTypes';
import Reports from "../reports/reports";
import PollDetailsTable from "../../components/pollDetailsTable/pollDetailsTable";
import DefaultGames from '../../components/defaultgames/DefaultGames';
import Header from "../../components/header/header";
import img1 from '../../assets/icons/image1.png';
import LoadingComponent from "../../components/loader/LoadingComponent";
import WebinarDescriptionModal from "../../components/modal/webinardescriptionmodal";
import WebinarPlayCard from "../../components/playCard/webinarPlayCard";
import arrowleft from '../../assets/images/paginationarrow.svg';
import lohri from '../../assets/images/LohriBanner2.png';
import search from '../../assets/images/search.svg';
import Playcardlarge from '../../components/playCard/playcardlarge';
import iconfilter from "../../assets/icons/iconfilter.png";
import { fetchOccasionGames } from "../../redux/actions/calendarActions";
import LikeShareModal from '../../components/modal/likesharemodal';
import PlayCard from "../../components/playCard/playCard_new";
import Footer from "../../components/footer/footer";
import CalendarGamesFilterModal from "../../components/modal/calendarGamesFilterModal";
import { gameAllCategory } from "../../redux/actions/gameDetailAction";
import virtual from '../../assets/icons/iconvirtual.png';
import time from '../../assets/icons/icon_time.png';
import participant from '../../assets/icons/icon_participants.png';
import { Link } from 'react-router-dom';
import "./ocassionGames.css";
import img3 from '../../assets/icons/28states.jpg';
import { S3_BASE_URL, axiosApiInstance, BASE_URL, decryptData, encryptData, GetUserType, IsAdmin, REG_EX_URL_FORMAT, successAlert, failureAlert } from "../../helpers/helper";
const OccasionGames = (props) => {

  const allQuarterInfoData = useSelector((state) => state.occasionGames);
  const [openFilterModal, setOpenBookEventFilterModal] = useState(false);
  const [filtersCount, setFiltersSelectedCount] = useState('');
  const { id } = useParams();
  const [loaded, setLoaded] = useState(false);
  const [allGames, setAllGames] = useState([]);
  const [filteredGames, setFilteredGames] = useState([]);
  const dispatch = useDispatch();
  const isAdmin = IsAdmin();
  const signOut = async () => {
    await dispatch(logOut());
    props.history.push("/");
  };
  const [page, setPage] = useState(1);
  const changePage = (newPage) => {

  }

  const debounceTimeoutRef = useRef(null);
  const [mobile, setMobileBanner] = useState(null);
  const [desktop, setDesktopBanner] = useState(null);
  const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);
  const [clearFilter, setClearFiltertrue] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [gameDetails, setGameDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("")
  const [webinarDetails, setWeinarDetails] = useState(null);
  const [openWebinarDescriptionModal, setOpenWebinarDescriptionModal] = useState(false);
  const { gameCategory } = useSelector((state) => state.gameAllCategory);
  const storedFormData = useSelector((state) => state.calendarfilterFormData);
  const clickFunction = (webinar) => {
    setWeinarDetails(webinar);
    setOpenWebinarDescriptionModal(true);
  }


  useEffect(() => {
    dispatch(gameAllCategory());
  }, [])
  useEffect(() => {
    if (searchTerm) {
      setLoaded(false);
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      debounceTimeoutRef.current = setTimeout(() => {
        setCurrentPage(1);
        dispatch(fetchOccasionGames(id, searchTerm));
      }, 1500);
    } else {
      setLoaded(false);
      dispatch(fetchOccasionGames(id, searchTerm));
    }

    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [searchTerm, id, dispatch]);


  useEffect(() => {
    if (allQuarterInfoData) {
      const alldata = allQuarterInfoData?.occasionGames?.data?.games;
      const desktopBaner = allQuarterInfoData?.occasionGames?.data?.desktopBanner;
      const mobileBanner = allQuarterInfoData?.occasionGames?.data?.mobileBanner;

      setDesktopBanner(desktopBaner);
      setMobileBanner(mobileBanner);
      setAllGames(alldata);
      if (allQuarterInfoData.loading) {
        setLoaded(false);
      } else {
        setLoaded(true);
      }
    }
  }, [allQuarterInfoData]);
  const cardsPerPage = 12;


  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);


  const totalPages = Math.ceil(allGames?.length / cardsPerPage);


  useEffect(() => {
    setClearFiltertrue(false);
    // if (allGames) {
    //   console.log(allGames, "////////////////////////////////////////////")
    // }
    // if (currentPage && allGames && !openFilterModal) {
    //   const startIndex = (currentPage - 1) * cardsPerPage;
    //   const endIndex = currentPage * cardsPerPage;
    //   setCurrentData(allGames.slice(startIndex, endIndex));
    // }
    // if (currentPage && filteredGames && openFilterModal) {
    //   const startIndex = (currentPage - 1) * cardsPerPage;
    //   const endIndex = currentPage * cardsPerPage;
    //   setCurrentData(filteredGames.slice(startIndex, endIndex));
    // }
  }, [currentPage, allGames, filteredGames]);


  const goToPage = (page) => {
    if (page < 1) {
      setCurrentPage(1);
    } else if (page > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(page);
    }
  };
  const getObjectKeysCountAndValues = (obj) => {

    if (!obj || Object.keys(obj).length === 0) {
      return null;
    }

    const result = Object.entries(obj).reduce((acc, [key, value]) => {
      if (key === 'id') {
        return acc;
      }
      if (value !== undefined && value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
        acc.push({
          key,
          value: Array.isArray(value) ? value.length : value,
        });
      }
      return acc;
    }, []);


    return {
      count: result.length,
      keysWithValues: result,
    };
  };

  useEffect(() => {
    if (storedFormData) {
      if (storedFormData.formData) {
        const result = getObjectKeysCountAndValues(storedFormData.formData);

        setFiltersSelectedCount(result.count);
      }
    }
  }, [storedFormData]);

  const componentStyleValue = {
    mobile: "md:!w-[190px] md:!h-[280px] sm:!w-[155px] sm:!h-[238px] lg:!w-[200px] lg:!h-[300px] !w-[155px] !h-[238px]",
  }

  const openFilter = () => {
    setOpenBookEventFilterModal(true);
  };


  const handleGamesChange = (data) => {
    setFilteredGames(data?.games);
  };

  const handleClearFilters = async (clear) => {
    if (clear) {
      setClearFiltertrue(true);
      dispatch(fetchOccasionGames(id, ''));
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };



  const [expandedCardIndex, setExpandedCardIndex] = useState(null);

  const toggleDescription = (index) => {
    setExpandedCardIndex(expandedCardIndex === index ? null : index);
  };
  const formatGameType = (gameType) => {
    if (gameType === "ONLINE") {
      return "Online";
    }
    if (gameType === "ONSITE") {
      return "Onsite";
    }
    if (gameType === "HYBRID") {
      return "Hybrid";
    }
    return gameType;
  };
  return (
    <div >
      {
        openFilterModal &&
        <CalendarGamesFilterModal modalid={"filter-modal"} toggle={openFilterModal} experiences={gameCategory?.data} onCategoriesChange={handleGamesChange} setOpenBookEventFilterModal={setOpenBookEventFilterModal} onClearFilters={handleClearFilters} />
      }
      {
        openShareModal && (
          <LikeShareModal
            toggle={openShareModal}
            setOpenShareModal={setOpenShareModal}
            shareLink={shareLink}
          />
        )
      }
      <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />
      <div className="mt-[65px] md:mt-[72px] p-0 w-full h-auto">
        <div className="section section1" id="windows-section">
          <div className="image-container">


            <div className="flex justify-center items-center m-0 carousel-games-item">
              <img
                src={S3_BASE_URL + desktop}
                className="desktopView w-full h-full object-cover"
                alt={`Banner `}
              />

              <img
                src={S3_BASE_URL + mobile}
                alt={`Logo Mobile`}
                className="w-full h-full mobileView object-contain"
                style={{
                  height: 'calc(100vh - 72px)',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />





            </div>




          </div>


        </div>
        {/* <div className="w-full">
          {!loaded ? (
            <div className="bg-gray-200 w-full animate-pulse skeleton-animation" style={{ height: 'calc(70vh)' }}></div>
          ) : (
            <div className="bg-cover bg-center w-full">

              <img
                className="md:hidden w-full"
                src={S3_BASE_URL + mobile}
                alt="Mobile background"
                style={{
                  height: 'calc(100vh - 72px)',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              <img
                className="md:block hidden w-full"
                src={S3_BASE_URL + desktop}
                alt="Desktop background"
                style={{
                  height: 'calc(100vh - 72px)',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
          

            </div>

          )}
        </div> */}
        {/* <div className="flex justify-between items-center bg-white mx-auto mt-12 mb-12 p-0 w-[90%]">

          <div className="lg:mr-4 mb-0 lg:mb-0 lg:ml-1">
            <button onClick={() => openFilter()} className="mr-3 filter-button-with-badge">
              <img src={iconfilter} alt="Icon" className="mr-2 w-4 h-4" />
              <span>Filters</span>
              {filtersCount > 0 && <span className="badge">{clearFilter ? '' : filtersCount}</span>}
            </button>
          </div>
          <div class="shadow mr-0 input-icon">
            <img src={search} alt="search" />
            <input type="text" name="search" placeholder="Search a game"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
        </div> */}
        <LoadingComponent loaded={loaded} />
        {/* {
          !loaded ? (
         
            <div className="flex justify-center items-center mr-[20px] mb-5">
              {Array.from({ length: 6 }).map((_, indx) => (
                <div
                  key={indx}
                  className="z-[80] flex justify-center items-center mr-[20px] mb-5 rounded-[10px] md:w-[220px] lg:w-[220px] md:h-[320px] lg:h-[320px]"
                >
                  <div className="flex bg-slate-100 rounded-[10px] w-[200px] h-[300px] skeleton-animation"></div>
                </div>
              ))}
            </div>
          ) : (
            currentData && currentData.length > 0 ? (
              <div
                className="gap-y-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 mx-auto w-[90%] h-auto"
                style={{
                  columnGap: 'calc((100% - (5 * 190px)) / 8)' 
                }}
              >
                {currentData.map((game, index) => (
                  game.isWebinar ? (
                    <WebinarPlayCard
                      key={JSON.stringify(game)}
                      gameDetail={game}
                      title={game.title}
                      srcImage={[game.mobileCoverMedia]}
                      setOpenShareModal={setOpenShareModal}
                      setShareLink={setShareLink}
                      isFromWebinar={true}
                      webinarClickFunction={() => clickFunction(game)}
                      setGameDetails={setGameDetails}
                      setOpenMobileHoverCard={setOpenMobileHoverCard}
                    />
                  ) : (
                    <PlayCard
                      key={JSON.stringify(game)}
                      gameDetail={game}
                      title={game.title}
                      srcImage={game.coverMedia}
                      setOpenShareModal={setOpenShareModal}
                      setShareLink={setShareLink}
                      // setHover={setHover}
                      // setHoverSlogan={() => setHoverSlogan(slogan.id)}
                      setGameDetails={setGameDetails}
                      setOpenMobileHoverCard={setOpenMobileHoverCard}
                      componentStyle={componentStyleValue}
                    />
              
                  )
                ))}
              </div>
            ) : (
              <div className="!h-40 no-game-wrapper">
                <div>
                  <h3 className="no-game">
                    The game you are searching for is currently not available.
                  </h3>
                  <span
                    className="no-game-span"
                    onClick={() => props.history.push("/")}
                  >
                    Go to Homepage
                  </span>
                </div>
              </div>
            )
          )
        } */}




        <div className="mx-auto !p-4 container">
        <div class="sm:block z-0 hidden circle-shadow-top"></div>
       
          <div className="gap-6 grid grid-cols-1 lg:grid-cols-2">
            {allGames &&
              allGames.map((game, index) => (
                <div
                  key={index}
                  className="z-10 flex lg:flex-row flex-col items-start lg:space-x-6 space-y-4 lg:space-y-0 p-4 lg:p-2 singleGameItem"
                >
                    
                  <div className="flex-shrink-0 m-0 sm:m-3 w-[200px] h-[300px]">

                    <img
                      src={S3_BASE_URL + game?.coverMedia[1]}
                      alt="Description"
                      className="block mx-auto sm:mx-0 w-[200px] sm:w-auto h-[300px] sm:h-auto gameCard object-cover"
                    />
                  </div>

                  <div className="flex flex-col !ml-0 pt-2 w-full">
                    <h2 className="singlegamemodaltitle">
                      {game?.title && (
                        <>
                          {game.title.split(' ').slice(0, -1).join(' ')}{' '}
                          <span className="highlight">{game.title.split(' ').slice(-1)}</span>
                        </>
                      )}
                    </h2>

                    <div className="show-on-desktop flex flex-wrap justify-between items-center mb-3 p-2 sm:p-0">

                      <div className="flex items-center mb-4 sm:mb-0 w-1/2 sm:w-1/3">
                        <img
                          src={virtual}
                          alt="Logo 1"
                          className="mr-1 gameLogo object-cover"
                        />
                        <span className="text-[13px] text-center sm:text-left lg:text-[14px] self-center singlegamemodalitem">
                          <span className="font-bold">Format</span>: {formatGameType(game?.gameType)}
                        </span>
                      </div>


                      <div className="flex items-center mb-4 sm:mb-0 w-1/2 sm:w-1/3">
                        <img
                          src={participant}
                          alt="Logo 2"
                          className="mr-1 gameLogo object-cover"
                        />
                        <span className="text-[13px] text-center sm:text-left lg:text-[14px] self-center singlegamemodalitem">
                          <span className="font-bold">Duration</span>: {game?.duration} Mins
                        </span>
                      </div>


                      <div className="flex items-center w-full sm:w-1/3">
                        <img
                          src={time}
                          alt="Logo 3"
                          className="mr-1 gameLogo object-cover"
                        />
                        <span className="text-[13px] text-center sm:text-left lg:text-[14px] self-center singlegamemodalitem">
                          <span className="font-bold">HeadCount</span>: {game?.maxPlayers}
                        </span>
                      </div>
                    </div>
                    <div className="flex sm:flex-row flex-col sm:flex-wrap justify-between items-center mb-3 p-0 sm:p-0 show-on-mobile">
                      <div className="flex items-center mb-4 sm:mb-0 w-full sm:w-1/3 bgPink">
                        <img src={virtual} alt="Logo 1" className="mr-1 gameLogo object-cover" />
                        <span className="text-[13px] text-center sm:text-left lg:text-[14px] self-center singlegamemodalitem">
                          <span className="font-bold">Format</span>: {formatGameType(game?.gameType)}
                        </span>
                      </div>
                      <div className="flex items-center mb-4 sm:mb-0 w-full sm:w-1/3 bgPink">
                        <img src={participant} alt="Logo 2" className="mr-1 gameLogo object-cover" />
                        <span className="text-[13px] text-center sm:text-left lg:text-[14px] self-center singlegamemodalitem">
                          <span className="font-bold">Duration</span>: {game?.duration} Mins
                        </span>
                      </div>
                      <div className="flex items-center w-full sm:w-1/3 bgPink">
                        <img src={time} alt="Logo 3" className="mr-1 gameLogo object-cover" />
                        <span className="text-[13px] text-center sm:text-left lg:text-[14px] self-center singlegamemodalitem">
                          <span className="font-bold">HeadCount</span>: {game?.maxPlayers}
                        </span>
                      </div>
                    </div>

                    <div className="flex flex-col flex-grow items-start">
                      <div className="w-full max-w-[680px]">
                        <p
                          className={`singlemodalPara mt-8 max-w-full ${expandedCardIndex === index ? "" : "max-h-[200px] overflow-y-auto"
                            } inline`}
                        >
                          {expandedCardIndex === index
                            ? game?.description
                            : game?.description.length > 320
                              ? `${game?.description.substring(0, 320)}...`
                              : game?.description}
                        </p>
                        {game?.description.length > 320 && (
                          <button
                            onClick={() => toggleDescription(index)}
                            className="mt-0 showMoreBtn"
                          >
                            {expandedCardIndex === index ? "Show Less" : "Show More"}
                          </button>
                        )}
                      </div>
                    </div>




                    <div className="flex-shrink-0 mt-2 mt-5 text-left">
                      <Link
                        to={`/game-detail/${game?.id}/${game?.title.replace(/ /g, '-')}`}
                        className="hover:opacity-80 px-4 py-2 rounded w-[155px] text-white transition-opacity duration-300 btn btn-primary"
                      >
                        Play Now
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
           <div class="sm:block circle-shadow-bottom z-0 hidden"></div>
        </div>






      </div>
      <Footer {...props} isAdmin={isAdmin} />
    </div>
  );

}

export default OccasionGames
