import React, { useEffect, useState } from "react";

import "./reportstable.css";
import arrow from "../../assets/images/arrow-right.svg";
import { useSelector, useDispatch } from "react-redux";
import search from "../../assets/images/search.svg";
import { getOverallReports } from "../../redux/actions/reportsActions";
import { useHistory } from "react-router-dom";
import { axiosApiInstance, BASE_URL, encryptData, EXTRAMILE_SUPERADMIN_EMAIL, S3_BASE_URL } from "../../helpers/helper";
import arrowleft from '../../assets/images/paginationarrow.svg';
import filtericon from '../../assets/images/filtericon.png';

import useConstant from "use-constant"
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useAsync } from 'react-async-hook';
import LoadingComponent from "../loader/LoadingComponent";
import ROLES from "../../helpers/userTypes";
import FilterModal from "../modal/filtermodal";

import PlayIcon from "../../assets/backgrounds/team logo.png"
import { getTeamsGamesReport } from "../../redux/actions/teamAction";


const useDebouncedSearch = (searchFunction) => {
  const [searchText, setSearchText] = useState('');
  const [recordsCount, SetRecordsCount] = useState(5);
  const [role, setRole] = useState("");

  const debouncedSearchFunction = useConstant(() =>
    AwesomeDebouncePromise(searchFunction, 500)
  );
  const results = useAsync(
    async () => {
      return debouncedSearchFunction(searchText, role);
    },
    [debouncedSearchFunction, searchText]
  );
  return {
    searchText,
    setSearchText,
    results,
    recordsCount,
    SetRecordsCount,
    role,
    setRole
  };
};

const TeamReportsTable = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const useSearch = () => useDebouncedSearch(searchFunction);
  const searchFunction = (text, role) => {
    dispatch(getOverallReports({ text }));
    if (role === ROLES.EMPLOYEE)
      SetRecordsCount(5);
    else
      SetRecordsCount(5);
  }
  const { searchText, setSearchText, SetRecordsCount, recordsCount, results, role, setRole } = useSearch();

  const UserInfo = useSelector((state) => state.getUser);
  const { userInfo } = UserInfo;

  // const OverallReports = useSelector((state) => state.overallReports);
  // const { overallReports, loading } = OverallReports;
  const [overallReports, setOverallReports] = useState([])
  const [page, setPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [filterState, setFilterState] = useState({
    initiatedBy: '',
    from: '',
    to: '',
    min: '',
    max: '',
    gameName: ''
  });

  const TeamsGamesData = useSelector((state) => state.getTeamsReport);
  const { teamReport } = TeamsGamesData;

  useEffect(() => {
    dispatch(getTeamsGamesReport())
  }, [])

  useEffect(() => {
    if (teamReport && teamReport?.data && teamReport?.data?.message === 'team session report' && teamReport?.data?.teamsScore) {
      setOverallReports(teamReport.data.teamsScore);
    }
  }, [TeamsGamesData])

  useEffect(() => {
    if (userInfo) {
      var { role } = userInfo.data;
      setRole(role);
    }
  }, [userInfo])
  useEffect(() => {
    if (ROLES.EMPLOYEE === role)
      SetRecordsCount(5);
    else
      SetRecordsCount(5);
  }, [role])


  const handleChange = (e) => {
    setSearchText(e.target.value);
    setPage(1);
  };

  const showAllRecords = () => {
    let recordCount = overallReports?.paginationData?.totalEntries;
    SetRecordsCount(recordCount);
    history.push('/my-games/my-reports/?page=1&reports=teams');
  };

  useEffect(() => {
    const value = sessionStorage.getItem("scrollPos");
    setScrollPos(value);
    sessionStorage.setItem("scrollPos", 0);
  }, []);
  useEffect(() => {
    dispatch(getOverallReports({ text: filterState.gameName, ...filterState }, page));
  }, [page]);
  const changePage = (newPage) => {
    window.scrollTo(0, 0);
    setPage(newPage);
    history.push('/my-games/my-reports?page=' + newPage + '&reports=teams');
  }
  useEffect(() => {
    if (results && results.loading ||
      TeamsGamesData && TeamsGamesData.loading
    ) {
      setLoaded(false);
    }
    else {
      setLoaded(true);
      if (scrollPos > 0) {
        const element = document.getElementsByClassName("main-wrapper")[0];
        element.scrollTo(0, scrollPos);
      }
    }
  }, [results])

  function formatTime(timeString) {
    const parts = timeString.split(':').map(Number);

    let hours = 0, minutes = 0, seconds = 0;

    if (parts.length === 2) {
      [minutes, seconds] = parts;
    } else if (parts.length === 3) {
      [hours, minutes, seconds] = parts;
    } else {
      return '00:00:00';
    }

    // Check for NaN values
    if (isNaN(minutes) || isNaN(seconds) || isNaN(hours)) {
      return '00:00:00';
    }

    // Adjust for cases where minutes overflow into hours
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;

    return [
      String(hours).padStart(2, '0'),
      String(minutes).padStart(2, '0'),
      String(seconds).padStart(2, '0')
    ].join(':');
  }

  return (
    <div>
      {/* table for desktop */}
      <LoadingComponent loaded={loaded} />
      <div className="c-white-card mb0 report-table">
        <div className="search-bar">
          <h5 >Reports By Teams</h5>
          <div className="right">
            {/* <div className="btn-tooltip">
              <img onClick={() => setOpenFilterModal(true)} src={filtericon} />
              <div className="tooltip">Add Filters</div>
            </div> */}
            {/* <div className="input-icon">
              <img src={search} alt="search" />
              <input
                type="text"
                name="search"
                placeholder="Search a game"
                value={searchText}
                onChange={handleChange}
              />
            </div> */}
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ textAlign: 'start' }}>Rank</th>
              <th style={{ textAlign: 'start' }}>Team Name</th>
              <th>Team Captain</th>
              {/* <th>Played On</th> */}
              <th>No of Players</th>
              <th>Team Score</th>
              <th>Total Play Time</th>
            </tr>
          </thead>
          <tbody>
            {
              overallReports.length > 0
                ? overallReports.map((game, index) => {
                  if (index < recordsCount) {
                    return (
                      <tr
                        onClick={() => {
                          const element = document.getElementsByClassName("main-wrapper")[0];
                          sessionStorage.setItem("scrollPos", element.scrollTop);
                          sessionStorage.setItem("teamReportId", encryptData(game.teamId));
                          history.push("/team-reports/" + game?.teamId);

                        }}
                        key={JSON.stringify(game.teamId)}
                      >
                        <td>
                          {index + 1}
                        </td>
                        <td>
                          <div className="table-detail">
                            <div className="bg-transparent mr-[12px] rounded-full w-[40px] h-[40px]">
                              <img
                                src={
                                  game?.teamLogo ?
                                    S3_BASE_URL + game.teamLogo
                                    : PlayIcon
                                }
                                alt="table"
                                className="rounded-full w-full h-full object-cover"
                              />
                            </div>
                            <div className="table-desc">
                              <h4>{game?.title}</h4>
                            </div>
                          </div>
                        </td>
                        <td>


                          {
                            game?.captainInfo && game?.captainInfo?.firstName ? game.captainInfo.firstName + " " + game.captainInfo.lastName : ''
                          }
                        </td>

                        <td>
                          <b>{game?.members.length}</b>
                        </td>
                        <td>
                          <b>{game?.totalMembersPoints}</b>
                        </td>
                        <td>
                          <b>{game?.totalMembersTime ? formatTime(game?.totalMembersTime) : '00:00:00'}</b>
                        </td>

                      </tr>
                    );
                  } else {
                    return (<div key={JSON.stringify(game)} />);
                  }
                })
                : (!overallReports && <>No record found.</>)}
          </tbody>
        </table>

      </div>
      {/* table for mobile */}
      <div className="table-responsive">
        {
          overallReports?.length > 0
            ? overallReports.map((game, index) => {
              if (index < recordsCount) {
                return (
                  <div
                    className="c-white-card report-t-card"
                    onClick={() => {
                      const element = document.getElementsByClassName("main-wrapper")[0];
                      sessionStorage.setItem("scrollPos", element.scrollTop);
                      sessionStorage.setItem("teamReportId", encryptData(game.teamId));
                      history.push("/team-reports/" + game?.teamId);

                    }}
                    key={JSON.stringify(game.teamId)}
                  >
                    <div className="report-t-top">
                      <div className="table-detail">
                        <div className="bg-transparent mr-[12px] rounded-full w-[40px] h-[40px]">
                          <img src={
                            game?.teamLogo ?
                              S3_BASE_URL + game.teamLogo
                              : PlayIcon
                          } alt="table"
                            className="rounded-full w-full h-full object-cover"
                          />
                        </div>
                        <div className="table-desc">
                          <h4>{game?.title}</h4>
                          <span>

                          </span>
                        </div>
                        <div className="flex-col ml-5 table-desc">
                          <h4>Team Score</h4>
                          <span className="flex justify-center items-center w-full text-center">
                            {game?.totalMembersPoints}
                          </span>
                        </div>
                      </div>
                      {game.status === "LIVE" ? (
                        <div className="live table-upadte">live</div>
                      ) : game.status === "COMPLETED" ? (
                        <div className="complete table-upadte">complete </div>
                      ) : null}
                    </div>
                    <div className="report-t-bottom">
                      <div className="report-t-left">
                        <span>Team Captain:</span>
                        <h6>
                          {
                            game?.captainInfo && game?.captainInfo?.firstName ? game.captainInfo.firstName + " " + game.captainInfo.lastName : ''
                          }
                        </h6>
                      </div>
                      <div className="report-t-right">
                        <span>No of Players:</span>
                        <h6 className="text-center">{game.members.length}</h6>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (<div key={JSON.stringify(game)} />);
              }
            })
            : (!overallReports && <>No record found.</>)}

        {/* {recordsCount &&
          overallReports &&
          overallReports.data &&
          overallReports.data.overAllTableReports &&
          ((role === ROLES.EMPLOYEE && overallReports?.paginationData?.totalEntries <= 5) || (role !== ROLES.EMPLOYEE && overallReports?.paginationData?.totalEntries <= 5) ||
            recordsCount ===
            overallReports?.paginationData?.totalEntries) ? null : (
          <div className="report-viewall">
            <button
              type="submit"
              className="btn btn-secondry"
              onClick={showAllRecords}
            >
              View all reports
            </button>
          </div>
        )} */}
      </div>
      {
        recordsCount >= 10 &&
        <div className="pagination-wrapper">
          <button
            className={page > 1 ? "pagination-left enable" : "pagination-left"}
            onClick={() => {
              if (page > 1)
                changePage(page - 1);
              window.scrollTo(0, 0);
            }}
          >
            <img src={arrowleft} alt="arrow left" />
          </button>
          <div className="pagination-number">
            <h5>{page}</h5>
            <span>of {Math.ceil(recordsCount / 12)}{" "}pages</span>
          </div>
          <button
            className={(page < Math.ceil(recordsCount / 12)) ? "pagination-right enable" : "pagination-right"}
            onClick={() => {
              if (page < Math.ceil(recordsCount / 12))
                changePage(page + 1);
            }}
          >
            <img src={arrowleft} alt="arrow right" />
          </button>
        </div>
      }
      <FilterModal filterState={filterState} setFilterState={setFilterState} modalid={'filter-modal'} SetRecordsCount={SetRecordsCount} toggle={openFilterModal} setOpenFilterModal={setOpenFilterModal} setPage={setPage} />
    </div>
  );
};

export default TeamReportsTable;
