import * as ActionTypes from "../constants/pointSystemConstants";


function GetAllPointsReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_ALL_POINTS_REQUEST:
      return { loading: true };
    case ActionTypes.GET_ALL_POINTS_SUCCESS:
      return { loading: false, topThreeUsers: action.payload };
    case ActionTypes.GET_ALL_POINTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
function GetInfoStreakReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_LOGIN_STREAK_REQUEST:
      return { loading: true };
    case ActionTypes.GET_LOGIN_STREAK_SUCCESS:
      return { loading: false, loginStreakData: action.payload };
    case ActionTypes.GET_LOGIN_STREAK_FAIL:
      return { loading: false, error: action.payload };

      // case ActionTypes.ADD_BADGE_ID:
      //   return {
      //     ...state,
      //     completedBadgeIds: [...state.completedBadgeIds, action.payload],
      //   };
    default:
      return state;
  }
}
function GetUserBadges(state = { badgeids: [] }, action) {
  switch (action.type) {
    case ActionTypes.ADD_BADGE_ID:
      if (!state.badgeids.includes(action.payload)) {
        return {
          ...state,
          badgeids: [...state.badgeids, action.payload],
        };
      }
      return state;

    default:
      return state;
  }
}
function GetTasksReducer(state = {}, action) {
  switch (action.type) {
    case ActionTypes.GET_TASK_REQUEST:
      return { loading: true };
    case ActionTypes.GET_TASK_SUCCESS:
      return { loading: false, tasks: action.payload };
    case ActionTypes.GET_TASK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}


export {
 
  GetAllPointsReducer,
  GetInfoStreakReducer,
  GetUserBadges,
  GetTasksReducer
  
};
