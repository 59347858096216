import React, { useEffect, useState, useRef } from 'react';
import './dashboard.css';
import { uploadFile } from "../../redux/actions/commonActions";
import { BASE_URL, S3_BASE_URL, failureAlert, axiosApiInstance, successAlert } from "../../helpers/helper";
import DataTable from "react-data-table-component";
import { getAllFeatureBanners } from "../../redux/actions/featureBannerActions";

import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer } from "react-toastify";
import active from "../../assets/icons/Most-Active-User.png";
import categorySpecialist from "../../assets/icons/Category-Specialist.png";
import consistentPlayer from "../../assets/icons/Consistent-Player.png";
import starfilled from "../../assets/icons/starfilled.jpg";
import starempty from "../../assets/icons/Starempty.png";
import vector from "../../assets/icons/Vector.png";
import target from "../../assets/icons/Target.png";
import crown from "../../assets/icons/crown.png";
import dummy from "../../assets/icons/dummyuser.png";
import mask from "../../assets/icons/Mask.png";

import startbadge from "../../assets/icons/StarBadge.png";
import categoryExplorer from "../../assets/icons/Category-Explorer.png";
import EventEnthusiast from "../../assets/icons/Event-Enthusiast.png";
import FeedbackGuru from "../../assets/icons/Feedback-Guru.png";
import LuckyDrawMaster from "../../assets/icons/Lucky-Draw-Master.png";
import MilestoneChampion from "../../assets/icons/Milestone-Champion.png";
import mostActiveuser from "../../assets/icons/Most-Active-User.png";
import ParticipationChamp from "../../assets/icons/Participation-Champ.png";
import PointHoarder from "../../assets/icons/Point-Hoarder.png";
import RisingStar from "../../assets/icons/Rising-Star.png";
import SuperProfileCompleter from "../../assets/icons/Super-Profile-Completer.png";
import TeamBuilder from "../../assets/icons/Team-Builder.png";

import { fetchTopThreeUsersByPoints, fetchLoginStreakInfoforLoginUser } from "../../redux/actions/pointSystemActions";
import { getUserPointsInfo } from '../../redux/actions/userAction';
import { Player } from "@lottiefiles/react-lottie-player";
import lightFile from "./light-animation.json"
import Header from '../../components/header/header';
import SideBar from '../../components/sidebar/sidebar';
import PageLayout from "../../components/pagelayout/pagelayout";
import sidebarContentAdmin from '../../helpers/sidebarContentAdmin';
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import title_stars from "../../assets/icons/title_stars.png";
import winningstrek from "../../assets/icons/Winning-Streak.png";
import progressImage from "../../assets/icons/image400.png";
import inactivebadge from "../../assets/icons/Badge_Disable.png";
import { Helmet } from 'react-helmet-async';
const BadgesDetails = (props) => {
  const completedBadgeIds = JSON.parse(localStorage.getItem('badgeIds'));

  const { userInfo } = useSelector((state) => state.getUser);


  const isAdmin =
    userInfo && userInfo.data && userInfo.data.role === "EXTRAMILE_SUPERADMIN";

  const badgeItemsArr = [
    {
      id: 1,
      image: ParticipationChamp,
      title: 'Participation Champ',
      description: 'Awarded for participating in 10 games',
      requirement: 'Play 10 different games on the platform',
      backgroundColor: '#e91e631f',
      progressColor: '#D83307',
    },
    {
      id: 2,
      image: winningstrek,
      title: 'Winning Streak',
      description: 'Achieved by winning first place in 5 games',
      requirement: 'Be the 1st Winner in 5 games',
      backgroundColor: '#e91e631f',
      progressColor: '#03A9F4',
    },
    {
      id: 3,
      image: RisingStar,
      title: 'Rising Star',
      description: 'Earned by finishing in the top 3 positions in 10 games.',
      requirement: 'Secure 1st, 2nd, or 3rd place in 10 games',
      backgroundColor: '#e91e631f',
      progressColor: '#4caf50',
    },
    {
      id: 4,
      image: consistentPlayer,
      title: 'Consistent Player',
      description: 'Awarded for maintaining a 15-day login streak',
      requirement: 'Log in every weekday (excluding weekends) for 15 consecutive days.',
      backgroundColor: '#e91e631f',
      progressColor: '#ffeb3b',
    },
    {
      id: 5,
      image: SuperProfileCompleter,
      title: 'Super Profile Completer',
      description: 'Awarded for completing the profile 100%',
      requirement: 'Add name, profile photo, phone number, and subscribe to notifications',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    {
      id: 6,
      image: TeamBuilder,
      title: 'Team Builder',
      description: 'Earned for creating your first team',
      requirement: 'Successfully create a team in the platform',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    {
      id: 7,
      image: active,
      title: 'Leadership Ace',
      description: 'Awarded for appointing a captain in your team',
      requirement: 'Assign a captain for the first time',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    {
      id: 8,
      image: FeedbackGuru,
      title: 'Feedback Guru',
      description: 'Achieved by submitting feedback for 5 different games',
      requirement: 'Submit feedback on 5 games',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    {
      id: 9,
      image: categoryExplorer,
      title: 'Category Explorer',
      description: 'Awarded for participating in at least one game from all categories',
      requirement: 'Play a game in every available category.',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    // {
    //   id: 10,
    //   image: EventEnthusiast,
    //   title: 'Event Enthusiast',
    //   description: 'Earned by attending 5 live streams',
    //   requirement: 'Participate in 5 fortnightly 30-minute live streams',
    //   backgroundColor: '#e91e631f',
    //   progressColor: '#43a047',
    // },
    // {
    //   id: 11,
    //   image: LuckyDrawMaster,
    //   title: 'Lucky Draw Master',
    //   description: 'Awarded for redeeming your first raffle ticket',
    //   requirement: 'Use 100 points to redeem a raffle ticket',
    //   backgroundColor: '#e91e631f',
    //   progressColor: '#43a047',
    // },
    {
      id: 12,
      image: mostActiveuser,
      title: 'Most Active User',
      description: 'Badge awarded for being recognized as the most active user in a week',
      requirement: 'Earn the "Most Active User/Week" recognition',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    {
      id: 13,
      image: categorySpecialist,
      title: 'Category Specialist',
      description: 'Awarded for earning 100 points in each category streak',
      requirement: 'Complete at least one streak in all categories',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    {
      id: 14,
      image: PointHoarder,
      title: 'Point Hoarder',
      description: 'Achieved by accumulating 500 points',
      requirement: 'Have 500 or more points in your account at once',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
    {
      id: 15,
      image: MilestoneChampion,
      title: 'Milestone Champion',
      description: 'Awarded for earning 1,000 cumulative points.',
      requirement: 'Reach 1,000 total points from all activities combined',
      backgroundColor: '#e91e631f',
      progressColor: '#43a047',
    },
  ];


  return (
    <div className='flex flex-col items-start w-full'>
      <Helmet>
        <title>Badges</title>
        <meta name="description" content="Badges" />
        <meta name="keywords" content="Badges" />
      </Helmet>
      <div className="flex lg:flex-row flex-col-reverse justify-start lg:justify-between items-center w-full">
        <div className="mx-auto px-1 py-6 container-fluid">
          <div className="relative mb-6">
          <div class="sm:block hidden badge-detail-shdw-yellow "></div>
          <div class="sm:block hidden badge-detail-shdw-blue "></div>
          <div class="sm:block hidden badge-detail-shdw-pink"></div>
            <img src={title_stars} alt="Arc Image" className="top-[-20px] left-[60px] absolute w-14 h-auto transform -translate-x-1/2" />
            <h1 className="w-full font-bold text-2xl text-left">
              Your <span className="text-[#D83307]">Badges</span>
            </h1>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 relative">
            {badgeItemsArr
              .sort((a, b) => {
                const isACompleted = completedBadgeIds.includes(a.id);
                const isBCompleted = completedBadgeIds.includes(b.id);
                return isBCompleted - isACompleted;
              }).map((card) => (
                <>
                 
                <div className="bg-white p-4 badgedetailsCardShadow flex items-center mb-0 " key={card.id}>
                 
                  <div className="flex items-center w-full">
                    {completedBadgeIds.includes(card.id) ? (
                      <div className={`flex items-center justify-center rounded-md mb-0 w-[100px] h-[100px]`} style={{ backgroundColor: card.backgroundColor }}>
                        <img src={card.image} alt="Badge Image" className="w-18 h-18 object-cover rounded-full" />
                      </div>
                    ) : (
                      <div className={`flex items-center justify-center rounded-md mb-0 w-[100px] h-[100px] bg-gray-300`}>
                        <img src={inactivebadge} alt="Inactive Badge Image" className="w-18 h-18 object-cover rounded-full opacity-50" />
                      </div>
                    )}
                    <div className="flex-1 ml-5">
                      <p className="badgeCardTitle">
                        {card.title.split(" ").length > 1
                          ? card.title.split(" ").slice(0, -1).join(" ") + " "
                          : ""}
                        <span className="text-[#D83307]">
                          {card.title.split(" ").slice(-1)}
                        </span>
                      </p>
                      <p className="badgeLightCardPara">{card.description}</p>
                      <p className="badgeNormalCardPara">Requirement : {card.requirement}</p>
                      <div className="relative mb-0">
                        <div
                          className={`progress-wrapper mt-1 ${!completedBadgeIds.includes(card.id) ? 'border-2 border-white bg-white' : ''}`}
                          style={{
                            width: '100%',

                            position: 'relative',
                          }}
                        >
                          <progress
                            id="progress1"
                            value={completedBadgeIds.includes(card.id) ? 100 : 0}
                            max="100"
                            className="progress-bar w-full h-4 "
                            style={{
                              height: '10px!important', border: '1px solidrgb(244, 242, 242)', backgroundColor: completedBadgeIds.includes(card.id) ? 'transparent linear-gradient(90deg, #FCE38A 0%, #F38181 100%) 0% 0% no-repeat padding-box' : '#eee', // No need for a background, as it’s handled by the gradient
                            }}
                          />
                        </div>
                        {completedBadgeIds.includes(card.id) && (
                          <img
                            src={progressImage}
                            alt="Progress Image"
                            className="car-image absolute top-[14px] right-[-50px]"
                          />
                        )}
                      </div>


                    </div>
                  </div>
                </div>
                </>
              ))}
          </div>

        </div>
      </div>
    </div>
  );
};

export default BadgesDetails;
