import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux"
import "./modal.css";
import Modal from "./modal";
import Select from "react-select";
import { useSelector } from "react-redux";
import ROLES from "../../helpers/userTypes";
import { getOrganisationUsers, organisationAdduser, organisationEdituser } from "../../redux/actions/organisationActions";
import Roles from '../../helpers/userTypes';
import LoadingComponent from "../loader/LoadingComponent";
import PhoneInput from "react-phone-input-2";
import { getUser, update } from "../../redux/actions/userAction";

const RoleOptions = {
    owner: [
        { label: "Employee", value: "EMPLOYEE" },
        { label: "Admin", value: "ADMIN" },
        { label: "Owner", value: "OWNER" }
    ],
    admin: [
        { label: "Employee", value: "EMPLOYEE" },
        { label: "Admin", value: "ADMIN" }
    ]
}
const EditUserDetailModal = ({
    modalid,
    toggle,
    setOpenEditUserModel,
    userInfoData,
    successAlert
}) => {

    const [userDetails, setUserDetail] = useState({
        // email: '',
        firstName: userInfoData.firstName,
        lastName: userInfoData.lastName,
        phoneNumber: userInfoData.phoneNumber
    })

    const [touch, setTouch] = useState({
        // email: false,
        firstName: false,
        lastName: false,
        phoneNumber: false
    })

    const [enableSave, setEnableSave] = useState(false)
    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')

    const [validPhone, setValidPhone] = useState(true);

const dispatch = useDispatch()


useEffect(()=>{
if(userDetails && (userDetails.firstName !== userInfoData.firstName || userDetails.lastName !== userInfoData.lastName || userDetails.phoneNumber !== userInfoData.phoneNumber)){
    setEnableSave(true)
}
else{
    setEnableSave(false)
}
},[userDetails])

    const handleBlur = (e) => {
        e.preventDefaulte()
        const name = e.target.name
        handleBlur((preState) => ({
            ...preState,
            [name]: true
        }))
    }

    const handleChange = (e) => {
        e.preventDefault()
        const firstNameFormat = /[a-zA-Z]{3,15}/

        let val = e.target.value;
        const name = e.target.name
        setUserDetail(prevState => ({
            ...prevState,
            [name]: capitalizeFirstAlpabate(val)
        }))
        if (!firstNameFormat.test(val) && name === 'firstName') {
            setFirstNameError('Please enter valid first name')
            return
        }
        if (!firstNameFormat.test(val) && name === 'lastName') {
            setLastNameError('Please enter valid last name')
            return
        }
setLastNameError('')
        setFirstNameError('')
      
    }

    const capitalizeFirstAlpabate = (name) => {
        if (typeof name !== 'string' || name.length === 0) {
          return '';
        }
        let spaceTrimName = name.replace(/\s/g, '');
        return spaceTrimName.charAt(0).toUpperCase() + spaceTrimName.slice(1).toLowerCase();
      }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!firstNameError && !lastNameError && userDetails.phoneNumber && userDetails.firstName && userDetails.lastName && validPhone && userInfoData && userInfoData?.email){

            await dispatch(update(userInfoData?.email, userDetails));
            dispatch(getUser())

        setOpenEditUserModel(false)
        }



    }

    return (
        <Modal modalid={modalid} toggle={toggle}>
            <div className="modal-body">
                <div className="close-icon" data-dismiss="modal" aria-label="Close" onClick={() => setOpenEditUserModel(false)}>
                    <div
                        className="close-btn-icon"
                    ></div>
                </div>
                <div className="flex flex-col justify-center items-center bg-white p-[32px] rounded-[10px] w-full lg:w-[520px] h-[500px]">
                    {/* <LoadingComponent loaded={loaded} /> */}
                    <div className="mb-[30px] font-sans font-semibold text-[32px]">{"Edit User Details"}</div>
                    <form className="flex flex-col w-full md:min-w-[370px]"  >
                        <div className="adduser-body">

                            <div className="form-group">
                                <label>First Name *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    name="firstName"
                                    placeholder=""
                                    value={userDetails.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    maxLength={20}
                                    required
                                />
                                <div className="error-message">{firstNameError ? firstNameError : ''}</div>
                            </div>
                            <div className="form-group">
                                <label>Last Name *</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    name="lastName"
                                    placeholder=""
                                    maxLength={20}
                                    value={userDetails.lastName}
                                    onChange={handleChange}
                                    required
                                />
                                <div className="error-message">{lastNameError ? lastNameError : ''}</div>

                            </div>
                            <div className="form-group">
                                <label>Mobile No *</label>
                                <PhoneInput
                                    type="numbers"
                                    id="phoneNumber"
                                    value={userDetails.phoneNumber}
                                    // disabled={disabled.mobile}
                                    name="phoneNumber"
                                    enableLongNumbers
                                    placeholder="+91 98765-43210"
                                    country="in"
                                    onChange={(val, country, e, formattedValue) => {
                                        if (country && (country.format && formattedValue && formattedValue.length === country.format.length) || (val.length <= country.dialCode.length))
                                            setValidPhone(true);
                                        else
                                            setValidPhone(false);
                                        setUserDetail(prevState => ({
                                            ...prevState,
                                            phoneNumber: val
                                        }))
                                    }}

                                />
                                <div className="error-message">{validPhone === false && userDetails.phoneNumber ? 'Invalid mobile no' : ''}</div>

                            </div>

                        </div>
                        <div className="flex flex-row justify-evenly items-center mt-[20px]">
                            <button
                                className="w-[120px] btn btn-outline btn-secondry"
                                onClick={() => setOpenEditUserModel(false)}
                            >
                                Cancel
                            </button>
                            <button type="submit" className={enableSave ? "btn btn-primary w-[105px]  sm:w-[120px] lg:w-[120px] md:w-[120px]" : "btn btn-primary  w-[105px]  sm:w-[120px] lg:w-[120px] md:w-[120px] disabled"}
                                onClick={handleSubmit}
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </Modal>
    );
};
export default EditUserDetailModal;
