import React, { useEffect, useState } from 'react';

import Header from '../../components/header/header';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AddUser from '../../components/adduser/AddUser';
import '../../components/reportstable/reportstable.css';
import '../customegame/customegame.css';
import "./manageteam.css";
import '../../components/plandetail/plandetail.css';
//import '../../components/databasecontent/index.css';
import '../../components/adduser/index.css';
import '../../components/paymentDetails/paymentDetails.css';
import UsersData from '../../components/usersData/usersData';
import { useDispatch } from 'react-redux';
import { logOut } from '../../redux/actions/userAction';
import CreateTeam from '../create teams';
import { closeExpand } from '../../redux/actions/teamAction';
import EditTeams from '../../components/adduser/EditTeams';

const ManageTeam = (props) => {
    const [addUserTeam, setAddUserTeam] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const [isEditComponent, setIsEditComponent] = useState(false);
    const tabCount = new URLSearchParams(props.location.search).get('tab');


    const dispatch = useDispatch();

    useEffect(() => {
        if (tabCount) {
            let numTabCount = Number(tabCount)
            setActiveTab(numTabCount)

        }
    }, [tabCount])

    const signOut = async () => {
        await dispatch(logOut());
        if (props.history)
            props.history.push("/");
    };
    const handleAddUserTeam = (e, value) => {
        e.preventDefault();
        setAddUserTeam(value)

    }


    return (
        <div>
            <Header signOut={signOut} {...props} profile />
            <main className="conatiner-960 container" 
            // style={{ width:'83%', maxWidth:'83%'}}

            >
                <div className="manageteam-wrapper">
                    <div className="database-heading plandetail-premium">
                        <h5>Manage Team</h5>
                    </div>
                    {/* <Tabs className="cg-tabs databse-tabs reports-tab"> */}
                    {/* <TabList>
                            <Tab onClick={(e)=> handleAddUserTeam(e, true)}>User data</Tab>
                            <Tab onClick={(e)=> handleAddUserTeam(e, true)}>Add Users/User</Tab>
                            <Tab onClick={(e)=> handleAddUserTeam(e, false)}>Create Teams</Tab>
                        </TabList> */}
                    <Tabs selectedIndex={activeTab} onSelect={(index) => setActiveTab(index)} className="cg-tabs databse-tabs reports-tab">
                        <TabList>
                            <Tab onClick={(e) => handleAddUserTeam(e, true)}>User data</Tab>
                            <Tab onClick={(e) => handleAddUserTeam(e, true)}>Add Users/User</Tab>
                        
                        </TabList>
                        <TabPanel>
                            <UsersData />
                        </TabPanel>
                        <TabPanel>
                         
                            <AddUser />

                        </TabPanel>
                    
                    </Tabs>
                </div>
            </main>
        </div>
    );
};

export default ManageTeam;