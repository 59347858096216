import React, { useEffect, useState, useRef } from "react";
import "reactjs-popup/dist/index.css";
import Slider from "react-slick";
import axios from "axios";
import "./dashboard.css";
import Header from "../../components/header/header";
import BannerCard from "../../components/bannerCarousel/bannerCarousel";
import PlayCard from "../../components/playCard/playCard_new";
import PlayCardHeading from "../../components/playCardHeading/playCardHeading";
import SlogansPlayCardHeading from "../../components/playCardHeading/slogansPlayCardHeading";
import Footer from "../../components/footer/footer";
import arrayMove from "array-move";
import Select from 'react-select'
import Sortablelist, { SortableItem } from 'react-easy-sort'
// todo
import _debounce from "lodash/debounce"
import Pollimg from '../../assets/images/poll.png'

// image
import search from "../../assets/images/search.svg";
import bell from '../../assets/images/bell.svg';
import hand from '../../assets/images/hand.svg';
import arrowback from "../../assets/images/arrow-left.svg"
import deleteIconAdmin from "../../assets/images/deleteIconAdmin.svg";
import editIconAdmin from "../../assets/images/editIconAdmin.svg";

import NonLoggedInHomePage from "../../containers/nonLoggedIn";

import {
  getAllGames,
  getBannerGames,
  homeSearchV3,
  homeSearch,
  getContactUs,
  getCurrentlyPlayingGames,
  getPreviouslyPlayedGames,
  getAllSlogans,
  backToDefaultBanners,
  addBannerGameBulk,
  updateBannerGameBulk,
  updateBannersDetail,
  updateSloganBulk,
  addBannerGame,
  updateSlogan
} from "../../redux/actions/homepageActions";
import { useDispatch, useSelector } from "react-redux";
import { getFavGames, getUser, logOut } from "../../redux/actions/userAction";
import { getAllOrganisations } from "../../redux/actions/organisationActions";
import BannerEditCard from "../../components/bannerEdit/BannerEditCard";
import BannerDeleteModal from "../../components/bannerEdit/BannerDeleteModal";
import ShowAllModal from "../../components/bannerEdit/ShowAllModal";
import AddGameInSloganModal from "../../components/bannerEdit/AddGameInSoganModal";
import ConfirmSloganSortModal from "../../components/bannerEdit/ConfirmSloganSortModal";
import LikeShareModal from "../../components/modal/likesharemodal";
import PageLayout from "../../components/pagelayout/pagelayout";
import SortableListGrid from "../../components/sortableList/SortableListGrid";
import sidebarContentAdmin from '../../helpers/sidebarContentAdmin';
import { bulkUpdateGame } from "../../redux/actions/gameDetailAction";
import useDebouncedSearch from "../../helpers/debounce";
import PlayCardMobileHoverCard from "../../components/playCard/playCardMobileHoverCard";
import LoadingComponent from "../../components/loader/LoadingComponent";
import ROLES, { OrgRoles } from "../../helpers/userTypes";
import { axiosApiInstance, BASE_URL, decryptData, encryptData, GetUserType, IsAdmin, REG_EX_URL_FORMAT, successAlert, failureAlert } from "../../helpers/helper";
import ConfirmModal from "../../components/modal/confirmModal";
import BannerUploadCard from "../../components/bannerCarousel/bannerUploadCard";
import BannerCreateModalOrg from "../../components/bannerEdit/BannerCreateModalOrg";
import { getAllWebinars } from "../../redux/actions/commonActions";
import Webinar from "../../components/webinar/webinar";
import { ToastContainer } from "react-toastify";
import moment from "moment";
import Search, { MobileSearch } from "../../components/search/search";
import HomeBannerUpdateCard from "../../components/bannerEdit/homeBannerUpdateCard";
import { getSSORedirection } from "../../redux/actions/xoxodayActions";
// import barchart from "../../assets/barchart.png";
import SideViewCard from "./pollcardview/sideViewCard";

import { FaEdit } from "react-icons/fa";
import HomeAddGameInSloganModal from "../../components/bannerEdit/homeAddGameInSoganModal";
// import { BASE_URL, decryptData, encryptData, failureAlert, GetUserType, IsAdmin, REG_EX_URL_FORMAT, successAlert } from "../../../helpers/helper";
import FeatureBanner from "./featureBanner";
import PointSystem from "./pointSystem";
import { consistency, exist } from "synonyms/dictionary";
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
const Dashboard = ({ buttonExists, ...props }) => {

  const location = useLocation();
  const cameFromCalendar = location?.state?.fromCalendar;

  const [sloganUpdated, setSloganUpdated] = useState();
  const [searchText, setSearchText] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0);
  const banner = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "bannerslider",
    autoplay: true,
    autoplaySpeed: 5000,
    lazyLoad: "progressive",
    afterChange: (current) => setCurrentSlide(current),
    // fade:true
  };
  const gameslider = {
    speed: 500,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    className: "gameslider",
    cssEase: 'linear',
    lazyLoad: "progressive",
    responsive: [
      {
        breakpoint: 1980,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 8,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
          centerPadding: 0,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      }
    ],
  };

  const { duration, maxPlayers, gameType } = useSelector(state => state.filter.filters);
  const debouncedSearch = (text) => {
    dispatch(homeSearch(text, "", duration, maxPlayers, gameType));
  };

  const orgRoles = ["ORG_SUPER_ADMIN", "ORG_ADMIN", "EMPLOYEE"];
  const dispatch = useDispatch();
  const [BannerGames, setBannerGames] = useState([]);
  const [activeTabType, setActiveTabType] = useState("Banners")
  const BannerGamesState = useSelector((state) => state.bannerGames);
  const subdomainOrgId = useSelector((state) => state.subdomainOrgId.id);
  const subdomainOrgIdLoading = useSelector((state) => state.subdomainOrgId.loading);
  const allOrganisationData = useSelector((state) => state.allOrganisationData);
  const { bannerGames } = BannerGamesState;
  const UserInfo = useSelector((state) => state.getUser);
  const UserInfoLoading = useSelector((state) => state.getUser.loading);
  const { userInfo } = UserInfo;
  var role = ""
  if (userInfo)
    var { role } = userInfo.data;
  const AllGames = useSelector((state) => state.allGames);
  const { allGames } = AllGames;
  const SearchResults = useSelector((state) => state.homeSearch);
  const { searchResults } = SearchResults;

  const SearchResultsV3 = useSelector((state) => state.homeSearchV3);
  const { searchResultsV3 } = SearchResultsV3;

  const ContactUs = useSelector((state) => state.homeContactUs);
  const { contactUs } = ContactUs;
  const CurrentlyPlayingGames = useSelector(state => state.getCurrentlyPlayingGames);
  const { currentlyPlayingGames } = CurrentlyPlayingGames;
  const PreviouslyPlayedGames = useSelector(state => state.getPreviouslyPlayedGames);
  const { previouslyPlayedGames } = PreviouslyPlayedGames;

  const { updateGameInfo } = useSelector((state) => state.updateGame);

  const [bannerCreateModal, setBannerCreateModal] = useState(false);
  const [bannerDeleteModal, setBannerDeleteModal] = useState(false);
  const [showAllModal, setShowAllModal] = useState(false);
  const [addGameInSloganModal, setAddGameInSloganModal] = useState(false);
  const [confirmSloganSortModal, setConfirmSloganSortModal] = useState(false);
  const [bannerGameId, setBannerGameId] = useState("");
  const [gameDetails, setGameDetails] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState({ organizationId: "", organizationName: "ALL" });

  const [sloganFlag, setSloganFlag] = useState("");
  const [bannerEdit, setBannerEdit] = useState(false);
  const [bannerEditGameDetails, setBannerEditGameDetails] = useState({});

  const [openShareModal, setOpenShareModal] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [showBannerPreview, setShowBannerPreview] = useState(false);
  const [editBannerSubmitClicked, setEditBannerSubmitClicked] = useState(false);
  const [editBannerList, setEditBannerList] = useState([
    { id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" },
    { id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" },
    { id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" },
    { id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" },
    { id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" },
    { id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" }
  ]);
  const [existingBannerList, setExistingBannerList] = useState([]);

  const [hover, setHover] = useState(false);
  const [hoverSlogan, setHoverSlogan] = useState('');
  const isAdmin = IsAdmin();
  const [activeSloganType, setActiveSloganType] = useState("");
  const [openMobileHoverCard, setOpenMobileHoverCard] = useState(false);

  const [selectedSloganIndex, setSelectSloganIndex] = useState("")
  const [userType, setUserType] = useState("NONLOGIN");
  const [organizationSubscribedStaus, setOrganizationSubscribedStaus] = useState("NONSUBSCRIBED");
  const [selectedRegion, setSelectedRegion] = useState({ regionId: "ASIA", regionName: "ASIA" });

  const [sloganEditId, setSloganEditId] = useState('');

  const [subscribedOrganization, setSubscribedOrganization] = useState([]);
  const [unsubscribedOrganization, setUnSubscribedOrganization] = useState([]);

  const [slogansFor, setSlogansFor] = useState("NONLOGIN");
  const [bannersFor, setBannersFor] = useState("NONLOGIN");
  const [slogans, setSlogans] = useState([]);
  const GetSlogans = useSelector(state => state.getSlogans);
  const { allSlogans } = GetSlogans;
  const AddedSlogan = useSelector(state => state.addedSlogan);
  const DeletedSlogan = useSelector(state => state.deletedSlogan);
  const GameBulkUpdate = useSelector(state => state.gameBulkUpdate);
  const CustomizedSlogans = useSelector(state => state.customizedSlogans);
  const BackToDefaultBanner = useSelector(state => state.backToDefaultBanner);
  const UploadedFile = useSelector((state) => state.uploadedFile);
  const BulkAddBannerGame = useSelector(state => state.bulkAddBannerGame);
  const UpdateBannerGameBulk = useSelector(state => state.updateBannerGameBulk);
  const UpdateBannerGame = useSelector(state => state.updateBannerGame);
  const AllWebinars = useSelector(state => state.allWebinars);
  const { allWebinars } = AllWebinars;
  const UdpatedSlogans = useSelector(state => state.udpatedSlogans);
  const ToggleWebinarInfo = useSelector(state => state.toggleWebinarInfo);
  const [gameOrder, setGameOrder] = useState([]);
  const [gameId, setGameId] = useState([]);
  const [isSideCardShow, setSideCardShow] = useState(false);
  const [updateHomeBanner, setUpdateHomeBanner] = useState(false)
  const [homeBannerEditDetails, setHomeBannerEditDetails] = useState({
    imageDesktop: "",
    imageMobile: "",
    imageDesktopSize: "1850x420",
    imageMobileSize: "328x140",
    bannerId: '',
    editBannerId: ''

  })
  const [selectedBanner, setSelectedBanner] = useState({
    coverMedia: '',
    mobileCoverMedia: ''
  })
  const SSOLink = useSelector(state => state.SSOLink);

  const queryParams = new URLSearchParams(window.location.search);
  const pollId = queryParams.get("pollId");
  const inviteToken = queryParams.get("inviteToken");
  const [pollDetail, setPollDetail] = useState({})
  const [sideBarView, setSideBarView] = useState(false)

  const [onClickAddGame, setOnClickAddGame] = useState(false);


  useEffect(() => {

    const userLoginInfo = decryptData(localStorage.getItem("userSignInInfo") || encryptData({}));
    if (userLoginInfo?.email) {
      setSideBarView(true)
    }
  }, [])


  useEffect(() => {
    // Dispatch homeSearch whenever filters or searchText changes    
    if (role && (role !== ROLES.EXTRAMILE_SUPERADMIN && searchText.length > 0 && searchText !== '') || role && (role !== ROLES.EXTRAMILE_SUPERADMIN && duration.length > 0) || role && (role !== ROLES.EXTRAMILE_SUPERADMIN && gameType.length > 0)) {
      // let searchFilter = {
      //   searchText,
      //   duration,
      //   gameType
      // }

      dispatch(homeSearchV3(searchText, duration, gameType, role));
    }
    else if (role && role === ROLES.EXTRAMILE_SUPERADMIN) {
      dispatch(homeSearch(searchText, "", duration, maxPlayers, gameType));

    }
    else {
      dispatch(homeSearchV3(searchText, duration, gameType, role = ''));
    }
  }, [searchText, duration, maxPlayers, gameType]);



  useEffect(() => {
    // getAllDetail()
    const decryptToken = decryptData(inviteToken)
  }, [inviteToken, pollId])

  useEffect(() => {

    // todo public poll
    if (userInfo?.data?.organizationId === pollDetail?.organizationId) {
      // todo
      // console.log("111111111111112222---->", pollDetail);
    }
  }, [userInfo?.data?.organizationId, pollDetail]);

  useEffect(() => {
    if (SSOLink.SSO?.data?.ssoToken) {
      const link = document.createElement('a');
      link.href = `https://stagingaccount.xoxoday.com/chef/v1/oauth/redirect/stores/${SSOLink.SSO?.data?.ssoToken}`;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.click();
    }
  }, [SSOLink])

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {

    if (!userInfo) {
      dispatch(getUser());
    }
    // dispatch(getAllOrganisations("", true));
    if (userInfo?.data && (OrgRoles.includes(userInfo.data.role) || userInfo.data.role === ROLES.EXTRAMILE_SUPERADMIN)) {
      dispatch(getFavGames(true));
      dispatch(getAllWebinars());
    }

    dispatch(getAllGames(false));
    dispatch(getContactUs());
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (userInfo?.data?.organizationId) {
        const { data } = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/getXoxoEnableStatus", {
          organizationId: userInfo?.data?.organizationId
        })
        if (data?.data?.isXoxoEnable) {
          if (userInfo?.data?.id) {
            const searchParams = new URLSearchParams(window.location.search);
            const xoxoValue = searchParams.get('xoxo');
            if (xoxoValue) {
              dispatch(getSSORedirection({ email: userInfo?.data?.email, url: "https://stagingaccount.xoxoday.com/chef/v1/oauth/sso/stores/company" }))
            }
          }
        }
      }
    })();
  }, [userInfo?.data]);

  useEffect(() => {
    dispatch(getAllGames());

    if (userInfo?.data?.role === ROLES.EMPLOYEE) {
      axios.get(BASE_URL + `/api/slogan/slogan/getOrgGames/${userInfo?.data?.organizationId}`).then((data) => {
        setGameId(data?.data?.data);
      });
    }
    if (userInfo?.data && (OrgRoles.includes(userInfo.data.role) || userInfo.data.role === ROLES.EXTRAMILE_SUPERADMIN)) {
      dispatch(getAllOrganisations("", true));
      dispatch(getFavGames(true));
      dispatch(getAllWebinars());
    }
    if (!userInfo?.data) {
      // setSlogansFor("NONLOGIN");
      // setBannersFor("NONLOGIN");
    } else if (userInfo?.data && (userInfo.data.role !== ROLES.EXTRAMILE_SUPERADMIN)) {
      setSlogansFor("LOGIN");
      setBannersFor("LOGIN");
      setBannerGames([])
      const subscriptionStatus = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"
      dispatch(getBannerGames(subdomainOrgId ? subdomainOrgId : (userInfo?.data?.organizationId ? userInfo?.data?.organizationId : ''), userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, false, isAdmin));
      dispatch(getAllSlogans(subdomainOrgId ? subdomainOrgId : (userInfo?.data?.organizationId ? userInfo?.data?.organizationId : ''), userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, false, isAdmin));
    }
  }, [userInfo, organizationSubscribedStaus]);

  useEffect(() => {
    setSelectedOrganization({ organizationId: "", organizationName: "ALL" });
    if (UserInfoLoading || subdomainOrgIdLoading || (userInfo?.data?.role === ROLES.EXTRAMILE_SUPERADMIN)) {
      return;
    }
    const subscriptionStatus = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"
    setBannerGames([])
    if (!subdomainOrgId) {
      dispatch(getBannerGames('', userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, '', isAdmin));
      dispatch(getAllSlogans(userInfo?.data?.organizationId, userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, '', isAdmin));

      return;
    }
    dispatch(getBannerGames(subdomainOrgId, userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, '', isAdmin));
    dispatch(getAllSlogans(subdomainOrgId, userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, '', isAdmin));
  }, [subdomainOrgId]);

  useEffect(() => {

    if (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN) {
      setBannerGames([])
      const subscriptionStatus = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"
      dispatch(getBannerGames((subdomainOrgId && (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN)) ? subdomainOrgId : userInfo?.data?.organizationId ? userInfo?.data?.organizationId : selectedOrganization.organizationId, isAdmin ? userType : userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, '', isAdmin));
      dispatch(getAllSlogans((subdomainOrgId && (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN)) ? subdomainOrgId : selectedOrganization.organizationId ? selectedOrganization.organizationId : userInfo?.data?.organizationId, isAdmin ? userType : userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, '', isAdmin));

      // dispatch(getAllSlogans((subdomainOrgId && (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN)) ? subdomainOrgId : selectedOrganization.organizationId, slogansFor));

      return;
    }

    const subscriptionStatus = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"

    dispatch(getBannerGames(selectedOrganization.organizationId, bannersFor, subscriptionStatus, '', isAdmin));
    // todo
    dispatch(getAllSlogans(selectedOrganization.organizationId, userType, subscriptionStatus, '', isAdmin));
  }, [selectedOrganization, slogansFor, bannersFor, organizationSubscribedStaus]);

  useEffect(() => {
    if (role === "ORG_ADMIN" || role === "ORG_SUPER_ADMIN") {
      dispatch(getCurrentlyPlayingGames());
      dispatch(getPreviouslyPlayedGames());
    }
    else if (role === "EMPLOYEE") {
      dispatch(getCurrentlyPlayingGames());

    }

  }, [role]);

  useEffect(() => {
    if (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN) {
      return;
    }
    if (activeTabType === "Banners") {
      dispatch(getBannerGames(selectedOrganization.organizationId, userType, organizationSubscribedStaus, "", isAdmin));

      // dispatch(getAllSlogans(selectedOrganization.organizationId, userType, organizationSubscribedStaus, selectedRegion?.regionId));

    } else if (activeTabType === "Slogans")
      dispatch(getAllSlogans(selectedOrganization.organizationId, userType, organizationSubscribedStaus, selectedRegion?.regionId));
  }, [selectedOrganization, userType, organizationSubscribedStaus, selectedRegion]);

  useEffect(() => {
    if (
      (BannerGamesState && BannerGamesState.loading) ||
      (AllGames && AllGames.loading) ||
      (SearchResults && SearchResults.loading) ||
      // (results && results.loading) ||
      (GetSlogans && GetSlogans.loading) ||
      (AddedSlogan && AddedSlogan.loading) ||
      (GameBulkUpdate && GameBulkUpdate.loading) ||
      (DeletedSlogan && DeletedSlogan.loading) ||
      (CustomizedSlogans && CustomizedSlogans.loading) ||
      (BackToDefaultBanner && BackToDefaultBanner.loading) ||
      (UploadedFile && UploadedFile.loading) ||
      (BulkAddBannerGame && BulkAddBannerGame.loading) ||
      (UpdateBannerGame && UpdateBannerGame.loading) ||
      (UpdateBannerGameBulk && UpdateBannerGameBulk.loading) ||
      (AllWebinars && AllWebinars.loading) ||
      (UdpatedSlogans && UdpatedSlogans.loading) ||
      (ToggleWebinarInfo && ToggleWebinarInfo.loading)
    )
      setLoaded(false);
    else
      setLoaded(true);
  }, [BannerGamesState, AllGames, SearchResults, GetSlogans, AddedSlogan, UpdateBannerGame,
    GameBulkUpdate, DeletedSlogan, CustomizedSlogans, BackToDefaultBanner, UploadedFile,
    BulkAddBannerGame, UpdateBannerGameBulk, AllWebinars, UdpatedSlogans, ToggleWebinarInfo
  ]);
  useEffect(() => {
    setBannerGames([])
    if (OrgRoles.includes(GetUserType())) {
      let list = [];
      if (allWebinars && allWebinars.data && userInfo && userInfo.data) {
        if (userInfo.data.role === ROLES.EMPLOYEE) {
          let isShowStatus = allWebinars && allWebinars.data.isShowStatus ? JSON.parse(allWebinars.data.isShowStatus) : [];
          if (isShowStatus.includes(userInfo.data.organizationId))
            list = [allWebinars.data];
        }
        else
          list = [allWebinars.data];
      }
      if (bannerGames && bannerGames.length > 0) {
        // if (bannerGames && bannerGames.data && bannerGames.data.length > 0) {
        list = [...list, ...bannerGames]
        // setCustomize(true);
      }
      else if (bannerGames && bannerGames.defaultBannerGames) {
        list = [...list, ...bannerGames.defaultBannerGames?.filter?.(banner => banner.isSubscribed)];
        // setCustomize(false);
      }
      setBannerGames(list);
    }
    // else if (bannerGames && bannerGames.data) {
    else if (bannerGames?.length) {
      // const banners = bannerGames.data.filter(banner => (
      const banners = bannerGames.filter(banner => (
        // (banner?.organizations?.length === 0 || !banner?.organizations) && ((bannersFor === "NONLOGIN") || (bannersFor === "SUBSCRIBED" && banner.isSubscribed))
        true
      ))
      let x = 6;
      let list = [];
      let list1 = [];
      banners.forEach(banner => {
        x = x - 1;
        list.push({ id: banner.id, coverMedia: banner.coverMedia, mobileCoverMedia: banner.mobileCoverMedia, redirectURL: banner.redirectURL });
        list1.push({ id: banner.id, coverMedia: banner.coverMedia, mobileCoverMedia: banner.mobileCoverMedia, redirectURL: banner.redirectURL });
      })
      for (var i = x; i > 0; i--) {
        list.push({ id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" });
        list1.push({ id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" });
      }
      setEditBannerList(list);
      setExistingBannerList(list1);
      setBannerGames(banners);
    }
    else {
      setBannerGames([]);
      setEditBannerList([{ id: null, coverMedia: null, mobileCoverMedia: null, redirectURL: "" }])
    }
  }, [bannerGames, allWebinars, userInfo, bannersFor, subdomainOrgId, selectedOrganization]);
  useEffect(() => {
    if (BannerGames && BannerGames?.length > 0 && userInfo && userInfo?.data) {
        const filteredBannerGames = BannerGames.filter(
            (element) => element.forLoggedIn && element.forOrganization && element.forSubscribed
        );
        setBannerGames(filteredBannerGames);
    }
}, [ userInfo]);
  useEffect(() => {
    dispatch(getAllGames());
  }, [updateGameInfo]);
  const [isFeatureSlogan, setIsFeatureSloganAdded] = useState(false);
  const [gamePosition, setGamePosition] = useState({
    RELEASES: [],
    PICKS: [],
    TRENDING: []
  });
  const [sloganData, setSloganData] = useState("");
  const [sloganType, setSloganType] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const updateGamePosition = (title) => {
    const gameArray = (gamePosition[title] || []).map((game) => ({ id: game.id, slogan: game.slogan, position: game.position, title: game.title }));
    const slogan = title;
    dispatch(bulkUpdateGame(gameArray, slogan)).then(() => dispatch(getAllGames()))
  };

  const onUpdateGameOrder = async () => {
    let currentSlogan = slogans[selectedSloganIndex - 1];
    let gameData = gameOrder?.map(data => {
      return { id: data.id }
    });
    let response = await dispatch(updateSlogan(currentSlogan?.id, {
      ...currentSlogan,
      games: [...gameData]
    }));
    if ((response.data.message) && (response.data.data)) {
      successAlert("Sucessfully Updated Games Position");
    }
    else {
      failureAlert("Failed To Update Games Position");
    }
    setConfirmSloganSortModal(false);
    // dispatch(getAllSlogans(selectedOrganization.organizationId, userType, organizationSubscribedStaus, selectedRegion?.regionId));
  };

  const positionSort = (a, b, slogan) => {
    if (!b.position)
      return -1;
    if (!a.position)
      return 1;
    if (!b.position[slogan])
      return -1;
    if (!a.position[slogan])
      return 1;
    if (a.position[slogan] < b.position[slogan])
      return -1;
    return 1;
  }
  const filterGames = (slogan) => {
    let games = allGames.data.filter((game) => {
      if (game.slogan && game.slogan.length > 0) {
        let flag = false;
        game.slogan.map(gameSlogan => {
          if (JSON.stringify(gameSlogan) === JSON.stringify(slogan)) {
            flag = true;
          }
        })
        return flag;
      }
    })
    return games.sort((a, b) => positionSort(a, b, slogan.id));
  }
  // const toDefaultBanners = async () => {
  //   if (userInfo && userInfo.data) {
  //     const response = await dispatch(backToDefaultBanners(userInfo.data.organizationId));
  //     if (response && response.status === 200) {
  //       const subscription = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"

  //       dispatch(getBannerGames((subdomainOrgId && (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN)) ? subdomainOrgId : selectedOrganization.organizationId, bannersFor, subscription,'',isAdmin));
  //       setCustomize(false);
  //       setOpenConfirmModal(false);
  //     }
  //   }
  // }
  useEffect(() => {
    if (allGames && allGames.data && allSlogans && allSlogans.data) {
      allSlogans.data.forEach(slogan => {
        setGamePosition(prevState => ({
          ...prevState,
          [slogan.id]: filterGames(slogan)
        }));
      })
    }
    if (allGames && allGames.data && searchResultsV3 && searchResultsV3.data) {
      searchResultsV3.data.forEach(slogan => {
        setGamePosition(prevState => ({
          ...prevState,
          [slogan.id]: filterGames(slogan)
        }));
      })
    }
  }, [allGames, allSlogans, searchResultsV3]);

  useEffect(() => {
    if (
      bannerCreateModal ||
      bannerDeleteModal ||
      showAllModal ||
      addGameInSloganModal
    )
      document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [
    bannerCreateModal,
    bannerDeleteModal,
    showAllModal,
    addGameInSloganModal,
  ]);

  useEffect(() => {
    if (!selectedSloganIndex || !allSlogans?.data?.[selectedSloganIndex - 1]?.games) {
      return;
    }
    let games = allSlogans?.data?.[selectedSloganIndex - 1]?.games;
    setGameOrder(games);
  }, [selectedSloganIndex, allSlogans]);

  // useEffect(() => {
  //   if (!selectedSloganIndex || !searchResultsV3?.data?.[selectedSloganIndex - 1]?.games) {
  //     return;
  //   }
  //   let games = searchResultsV3?.data?.[selectedSloganIndex - 1]?.games;
  //   setGameOrder(games);
  // }, [selectedSloganIndex, searchResultsV3]);

  // const onSortEndDrag = (oldIndex, newIndex, sloganTitle) => {
  //   setGamePosition((prevState) => ({
  //     ...prevState,
  //     [sloganTitle]: arrayMove(prevState[sloganTitle], oldIndex, newIndex)
  //   }))
  // };

  // Sorting the games
  const onSortEndDrag = (oldIndex, newIndex, sloganTitle) => {
    let currentOrder = [...gameOrder];
    let newArray = arrayMove(currentOrder, oldIndex, newIndex);
    setGameOrder([...newArray]);
  };

  const onSortEndSlogans = async (oldIndex, newIndex) => {
    let reOrderedSlogans = arrayMove(slogans, oldIndex, newIndex)
    setSlogans(reOrderedSlogans);
    let body = reOrderedSlogans.map((slogan, index) => ({ id: slogan.id, sloganType: slogan.sloganType, title: slogan.title, position: index + 1 }))
    const response = await dispatch(updateSloganBulk(body));

    dispatch(getAllSlogans((subdomainOrgId && (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN)) ? subdomainOrgId : selectedOrganization.organizationId ? selectedOrganization.organizationId : userInfo?.data?.organizationId, userType, slogansFor));
  }

  useEffect(() => {
    if (allSlogans && allSlogans.data) {
      let sloganArray = []
      allSlogans.data.map(slogan => {
        // if ((slogan.sloganType === slogansFor && !selectedOrganization.organizationId) || (slogan.sloganType === "ORGANIZATION" && selectedOrganization.organizationId)) {
        //   sloganArray.push(slogan);
        // }
        sloganArray.push(slogan);
      })

      setSlogans(sloganArray);

    }
  }, [allSlogans, slogansFor])

  const submitEditBanners = async () => {
    setEditBannerSubmitClicked(true);
    let flag = false;
    editBannerList.forEach((banner) => {
      if ((banner.coverMedia !== null && banner.mobileCoverMedia === null) || (banner.coverMedia === null && banner.mobileCoverMedia !== null))
        flag = true;
      if (banner.redirectURL !== "" && banner.redirectURL !== null && !REG_EX_URL_FORMAT.test(banner.redirectURL))
        flag = true;
    })
    if (JSON.stringify(editBannerList) === JSON.stringify(existingBannerList))
      flag = true;
    if (flag)
      return;

    // add new 
    let addlist = [];
    editBannerList.forEach((banner) => {
      if (banner.coverMedia !== null && banner.mobileCoverMedia !== null && banner.id === null) {
        let addBannerData = selectedOrganization?.organizationId ?
          {
            coverMedia: banner.coverMedia,
            mobileCoverMedia: banner.mobileCoverMedia,
            redirectURL: banner.redirectURL,
            forSubscribed: organizationSubscribedStaus === "SUBSCRIBED",
            forLoggedIn: userType === "LOGIN",
            // bannerGameType: bannersFor,
            // organizations: [{ id: selectedOrganization?.organizationId }],
            forOrganization: selectedOrganization?.organizationId ? true : false,
            organizationId: selectedOrganization?.organizationId,
          } :
          {
            coverMedia: banner.coverMedia,
            mobileCoverMedia: banner.mobileCoverMedia,
            redirectURL: banner.redirectURL,
            // bannerGameType: bannersFor,
            forSubscribed: organizationSubscribedStaus === "SUBSCRIBED",
            forLoggedIn: userType === "LOGIN",
          };
        addlist.push(addBannerData);
      }
    })
    //update existing
    let updateList = [];
    BannerGames.forEach((existing) => {
      editBannerList.forEach((banner) => {
        if (existing.id === banner.id && (existing.coverMedia !== banner.coverMedia || existing.mobileCoverMedia !== banner.mobileCoverMedia || existing.redirectURL !== banner.redirectURL)) {
          let addBannerData = selectedOrganization?.organizationId ?
            {
              id: banner.id,
              coverMedia: banner.coverMedia,
              mobileCoverMedia: banner.mobileCoverMedia,
              redirectURL: banner.redirectURL,
              forSubscribed: true,
              // bannerGameType: bannersFor,
              organizations: [{ id: selectedOrganization?.organizationId }],
            } :
            {
              id: banner.id,
              coverMedia: banner.coverMedia,
              mobileCoverMedia: banner.mobileCoverMedia,
              redirectURL: banner.redirectURL,
              forSubscribed: bannersFor === "SUBSCRIBED",
              // bannerGameType: bannersFor,
            };
          updateList.push(addBannerData);
        }
      })
    })
    await Promise.all([
      // addlist.length ? dispatch(addBannerGameBulk({ games: addlist })) : null,
      // updateList.length ? dispatch(updateBannerGameBulk({ games: updateList })) : null
      addlist.length ? dispatch(addBannerGame(addlist)) : null,

      updateList.length ? dispatch(updateBannersDetail(updateList)) : null

    ]).then(() => {
      const subscriptionStatus = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"

      dispatch(getBannerGames((subdomainOrgId && (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN)) ? subdomainOrgId : selectedOrganization.organizationId, userType, subscriptionStatus, "", isAdmin));
    })
  }

  useEffect(() => {
    let currentAllOrganization = allOrganisationData?.allOrganisationData?.data?.allOrganisationDetails;
    if (!currentAllOrganization) {
      return;
    };
    let organizationWithPlan = currentAllOrganization.filter((data) => {
      var expiresOn = moment(data?.expiresOn, "YYYY-MM-DD");
      var currentTime = moment().subtract(1, 'd');
      return currentTime.isBefore(expiresOn);
    });
    let organizationWithoutPlan = currentAllOrganization.filter((data) => {
      var expiresOn = moment(data?.expiresOn, "YYYY-MM-DD");
      var currentTime = moment().subtract(1, 'd');
      return expiresOn.isBefore(currentTime) || !data?.expiresOn;
    });
    setSubscribedOrganization(organizationWithPlan);
    setUnSubscribedOrganization(organizationWithoutPlan);
  }, [allOrganisationData]);

  useEffect(() => {
    setSelectedOrganization({ organizationId: "", organizationName: "ALL" });
  }, [activeTabType]);
  const bannerEditFn = async (id) => {
    if (id) {
      setHomeBannerEditDetails((prevVal) => ({ ...prevVal, bannerId: id }));
      let result = await axiosApiInstance.get(BASE_URL + `/api/banner/getBanners?id=${id}`)
      setSelectedBanner({
        coverMedia: result?.data?.coverMedia?.match(/\/([^/]+)$/)[1].replace(/^\d+_/, ''),
        mobileCoverMedia: result?.data?.mobileCoverMedia?.match(/\/([^/]+)$/)[1].replace(/^\d+_/, '')
      })
    }
    else {
      setHomeBannerEditDetails(Object.fromEntries(Object.entries(homeBannerEditDetails)?.map(([key]) => [key, ""])))
      setSelectedBanner({
        coverMedia: "",
        mobileCoverMedia: ""
      })
    }
    setUpdateHomeBanner(true)
  }

  const toDefaultBanners = async () => {
    if (userInfo && userInfo.data) {
      const response = await axiosApiInstance.delete(`${BASE_URL}/api/banner/delete/${homeBannerEditDetails?.bannerId}`, { organizationId: userInfo?.data?.organizationId });
      if (response && response.status === 200) {
        const subscriptionStatus = isAdmin ? organizationSubscribedStaus : (userInfo?.data?.organizationId && !userInfo?.data?.isPlanExpired) ? "SUBSCRIBED" : "UNSUBSCRIBED"

        // dispatch(getBannerGames((subdomainOrgId && (userInfo?.data?.role !== ROLES.EXTRAMILE_SUPERADMIN)) ? subdomainOrgId : selectedOrganization.organizationId, bannersFor, subscriptionStatus, '', isAdmin));
        dispatch(getBannerGames(subdomainOrgId ? subdomainOrgId : (userInfo?.data?.organizationId ? userInfo?.data?.organizationId : ''), userInfo ? "LOGIN" : "NONLOGIN", subscriptionStatus, '', false));

        setCustomize(false);
        setOpenConfirmModal(false);
      }
    }
  }
  const handleLoadedStatus = (status) => {

    setLoaded(status); // Update state based on the value received from the child
  };
  const [showAllGames, setshowAllGames] = useState(false);
  const EnablesAllGames = () => {

    buttonExists(false);
    setshowAllGames(true);
  };
  const handleAddSloganClick = (e) => {
    const featureSloganAdded = slogans.filter(obj => obj.sloganType === 'feature');

    setIsFeatureSloganAdded(featureSloganAdded.length > 0 ? true : false);
    setAddGameInSloganModal(true);
    setSloganData("Add");
  };
  const handleButtonExist = (exists) => {
    buttonExists(exists); // Callback passed from HomePage
  };


  return (
    <div className={loaded ? "" : "loading"} id='dashboard-element'>
      {/* {sideBarView ? <div className="top-[47%] sideviewCard">
        <div
          className="sideInnerviewCard"
        >
        </div>
        <div
          className="sideInnerviewCard"
        >
        </div>
        <div
          className="sideInnerviewCard"
          onClick={() => setSideCardShow(!isSideCardShow)}
        >
          <img src={Pollimg} alt="Pollimg" />
        </div>
        <div
          className="sideInnerviewCard"
        >
        </div>
      </div> : null}
      {isSideCardShow && <SideViewCard />} */}

      <LoadingComponent loaded={loaded} />
      <ToastContainer position="bottom-center" />
      {isAdmin && activeSloganType === "" &&
        <div className='admin-dashboard-btn-group container-fluid'>
          <div className={activeTabType === "Banners" ? "group-item active" : "group-item"} onClick={() => {
            setActiveTabType("Banners");
            setSlogansFor("NONLOGIN");
            setBannersFor("NONLOGIN");
          }}>
            Banners
          </div>
          <div className={activeTabType === "Slogans" ? "group-item active" : "group-item"} onClick={() => {
            setActiveTabType("Slogans");
          }}>
            Slogans
          </div>
          <div className={activeTabType === "Webinar" ? "group-item active" : "group-item"} onClick={() => {
            setActiveTabType("Webinar");
          }}>
            Webinar
          </div>
          <div className={activeTabType === "Feature" ? "group-item active" : "group-item"} onClick={() => {
            setActiveTabType("Feature");
          }}>
            Feature Banners
          </div>
        </div>
      }
      {userInfo && userInfo.data || showAllGames ? (
        <main className="padtop0 container mobileHoverCardParent" style={isAdmin ? { paddingTop: '0px', paddingBottom: '0px' } : { paddingTop: '65px' }}>
          {/* after purchasing a plan, until upload a CSV show this*/}
          <div className="hide upload-csv-section">
            <div className="upload-csv-title">
              <img src={bell} alt="bell" />
              <h5>Hey user, add CSV file for your users Id’s</h5>
            </div>
            <div className="upload-csv-butn">
              <img src={hand} alt="hand" />
              <button type="button">Upload CSV</button>
            </div>
          </div>
          {/* csv file ends */}
          <div className={isAdmin ? "sub-container pad0 !p-4" : "sub-container !p-4"}>
            <section className={`banner-section relative flex ${!isAdmin ? "flex-col" : ""}`}>
              {/* <section className="banner-section"> */}
              {!isAdmin ? (<>
                <div className="!p-0 profile-section">
                  <div className="flex flex-wrap justify-between items-center w-full">
                    <h5 className="profile-name">
                      {userInfo && userInfo.data && userInfo.data.firstName
                        ? "Hi " + userInfo.data.firstName + ","
                        : ""}
                        {/* Leaderboard */}
                    </h5>
                    {/* {
                   !isAdmin && userInfo.data.role === ROLES.ORG_SUPER_ADMIN ?
                     <button className="ml-3 btn btn-primary customizeButton" onClick={() =>
                       bannerEditFn()
                     }>Add Banner</button>
                     : ""
                 } */}

                    {!userInfo && <div className="block sm:hidden mb-2 w-full mobile-search-input self-end">
                      <MobileSearch searchText={searchText} setSearchText={setSearchText} />

                    </div>}
                  </div>
                  {allSlogans && <div className="sm:block hidden">

                  </div>}
                </div>
                <div className="sm:block z-10 hidden max-w-[485px] search-buttons">
                  <Search searchText={searchText} setSearchText={setSearchText} />
                </div>
              </>
              ) : null}
              {searchText.length === 0 && !isAdmin && !userInfo ? (
                <Slider {...banner} >
                  {BannerGames.map((game, i) => {
                    return (
                      <BannerCard
                        key={i}
                        isCurrentSlide={currentSlide}
                        index={i}
                        srcImage={game?.coverMedia}
                        title={game?.game && game?.game?.title}
                        redirectURL={game?.redirectURL}
                        mobileImage={game?.mobileCoverMedia}
                        setBannerCreateModal={setBannerCreateModal}
                        setOpenConfirmModal={setOpenConfirmModal}
                        customize={customize}
                        setCustomize={setCustomize}
                        bannerEditFn={bannerEditFn}
                        role={role}
                        setBannerEditGameDetails={() => setBannerEditGameDetails(game)}
                        isWebinar={game?.isShowStatus}
                        id={game?.id}
                        startsAt={game?.startsAt}
                        subdomainOrgId={subdomainOrgId}
                        setHomeBannerEditDetails={setHomeBannerEditDetails}
                        forOrganization={game?.forOrganization}
                      />
                    );
                  })}
                </Slider>
              ) : null}
              {/* {userInfo && !isAdmin && (

                <PointSystem userInfo={userInfo} selectedOrganization={selectedOrganization} />

              )} */}

              {userInfo && <div className="block sm:hidden mt-3 w-full mobile-search-input self-end">
                <MobileSearch searchText={searchText} setSearchText={setSearchText} />

              </div>}
              {isAdmin && activeTabType === 'Banners' && (
                <>
                  <div className="w-[100%] banner-editor">
                    <div className="head">
                      <h1>Default Banners</h1>
                      <Select
                        options={[{ label: "For Non-Subscribed Users", value: "NONSUBSCRIBED" }, { label: "For Subscribed Users", value: "SUBSCRIBED" }]}
                        defaultValue={{ label: "For Non-Subscribed Users", value: "NONSUBSCRIBED" }}
                        onChange={(e) => {
                          setBannersFor(e.value)
                          setOrganizationSubscribedStaus(e.value)
                        }}
                        value={bannersFor === "SUBSCRIBED" ? { label: "For Subscribed Users", value: "SUBSCRIBED" } : { label: "For Non-Subscribed Users", value: "NONSUBSCRIBED" }}
                        className="form-control"
                        placeholder="Slogans For"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minWidth: 200,
                            boxSizing: "content-box"
                          }),
                          menu: (base) => ({
                            ...base,
                            // width: 300
                          })
                        }}
                      />
                      <Select
                        options={(bannersFor === "SUBSCRIBED") ? [...subscribedOrganization, { organizationId: "", organizationName: "ALL" }] : [...unsubscribedOrganization, { organizationId: "", organizationName: "ALL" }]}
                        onChange={(value) => setSelectedOrganization(value)}
                        value={selectedOrganization}
                        className="form-control"
                        placeholder="Select Organization"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        getOptionLabel={(option) => option?.organizationName || option?.ownerEmail}
                        getOptionValue={(option) => option.organizationId}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minWidth: 200,
                            boxSizing: "content-box"
                          }),
                          menu: (base) => ({
                            ...base,
                            // width: 300
                          })
                        }}
                      />
                      <Select
                        options={[{ label: "For Non Login User", value: "NONLOGIN" }, { label: "For Login User", value: "LOGIN" }]}
                        defaultValue={{ label: "For Non Login User", value: "NONLOGIN" }}
                        onChange={(e) => setUserType(e.value)}
                        value={userType === "LOGIN" ? { label: "For Login User", value: "LOGIN" } : { label: "For Non Login User", value: "NONLOGIN" }}
                        className="form-control mx-2"
                        placeholder="Banner For"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minWidth: 200,
                            boxSizing: "content-box"
                          }),
                          menu: (base) => ({
                            ...base,
                          })
                        }}
                      />
                    </div>
                    <div className="banner-upload">
                      <div className="banner-upload-grp">
                        <div className="banner-grp-title">
                          <p className="head1">Banner </p>
                          <p className="head2">
                            <span>Mobile</span>
                            <span className="size">(328x140)</span>
                          </p>
                          <p className="head3">
                            <span>Desktop</span>
                            <span className="size">(1850x420)</span>
                          </p>
                          <p className="head4">Redirection URL</p>
                          <button className="btn head5">remove</button>
                        </div>
                        {
                          editBannerList.map((banner, index) => {
                            if (index === 0)
                              return <BannerUploadCard
                                key={index}
                                editBannerList={editBannerList}
                                setEditBannerList={setEditBannerList}
                                uploaded={banner.id !== null}
                                banner={banner}
                                userType={userType}
                                organizationId={selectedOrganization?.organizationId}
                                organizationSubscribedStaus={organizationSubscribedStaus}
                                isAdmin
                                disabled={false}
                                index={index + 1}
                                editBannerSubmitClicked={editBannerSubmitClicked}
                                setEditBannerSubmitClicked={setEditBannerSubmitClicked}
                              />
                            else
                              return <BannerUploadCard
                                editBannerList={editBannerList}
                                userType={userType}
                                organizationId={selectedOrganization?.organizationId}
                                organizationSubscribedStaus={organizationSubscribedStaus}
                                setEditBannerList={setEditBannerList}
                                uploaded={banner.id !== null}
                                isAdmin
                                disabled={editBannerList[index - 1].coverMedia === null || editBannerList[index - 1].mobileCoverMedia === null}
                                index={index + 1}
                                banner={banner}
                                editBannerSubmitClicked={editBannerSubmitClicked}
                                setEditBannerSubmitClicked={setEditBannerSubmitClicked}
                              />
                          })
                        }
                      </div>
                      <div className="btn-grp">
                        <button className={`btn btn-primary ${JSON.stringify(editBannerList) === JSON.stringify(existingBannerList) ? "disabled" : ""}`} onClick={submitEditBanners}>Update Banners</button>
                      </div>
                      <div className="preview-container">
                        <p className="preview" onClick={() => setShowBannerPreview(state => !state)}>
                          Preview
                          <div className={`dropdown-arrow ${showBannerPreview ? "rotate" : ""} `}>
                            <svg width="20" height="10" viewBox="0 0 8 6" fill="none">
                              <path
                                d="M1 1.5L4 4.5L7 1.5"
                                stroke="#e25569"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </p>
                        <div className={`${showBannerPreview ? "" : "hide"}`}>
                          <Slider {...banner}>
                            {
                              BannerGames.map((banner, i) => (
                                <BannerCard
                                  key={i}
                                  srcImage={banner.coverMedia}
                                  mobileImage={banner.mobileCoverMedia}
                                  setBannerCreateModal={setBannerCreateModal}
                                  setOpenConfirmModal={setOpenConfirmModal}
                                  customize={customize}
                                  setCustomize={setCustomize}
                                  role={role}
                                />
                              ))
                            }
                          </Slider>
                        </div>
                      </div>
                    </div>
                    {/* <div className="banner-edit">
                   {BannerGames.map((banner, index) => {
                     return (
                       <div className="banner-item">
                         <BannerEditCard
                           image={banner.coverMedia}
                           bannerCard={banner}
                           id={banner.id}
                           name={banner.game && banner.game.title}
                           setBannerGameId={setBannerGameId}
                           setBannerDeleteModal={setBannerDeleteModal}
                           setBannerEdit={setBannerEdit}
                           setBannerEditGameDetails={setBannerEditGameDetails}
                           setBannerCreateModal={setBannerCreateModal}
                         ></BannerEditCard>
                       </div>
                     );
                   })}
                   {BannerGames && BannerGames.length < 6 && (
                     <div className="add banner-item">
                       <div
                         className="Add container"
                         onClick={() => {
                           setBannerCreateModal(true);
                           setBannerEdit(false);
                         }}
                       >
                         <img
                           id="banner-add"
                           src="https://img.icons8.com/android/240/000000/plus.png"
                           alt="Add"
                         />
                       </div>
                     </div>
                   )}
                 </div> */}
                  </div>
                </>
              )}
              {isAdmin && activeTabType === 'Slogans' && activeSloganType === "" && (
                <>
                  <div className="w-[100%] banner-editor">
                    <div className="head">
                      <h1>Slogans</h1>
                      <Select
                        options={[{ label: "For Non-Subscribed Users", value: "NONLOGIN" }, { label: "For Subscribed Users", value: "SUBSCRIBED" }]}
                        defaultValue={{ label: "For Non-Subscribed Users", value: "NONLOGIN" }}
                        onChange={(e) => {
                          setSlogansFor(e.value)
                          setOrganizationSubscribedStaus(e.value)
                        }}
                        value={slogansFor === "SUBSCRIBED" ? { label: "For Subscribed Users", value: "SUBSCRIBED" } : { label: "For Non-Subscribed Users", value: "NONLOGIN" }}
                        className="form-control"
                        placeholder="Slogans For"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minWidth: 200,
                            boxSizing: "content-box"
                          }),
                          menu: (base) => ({
                            ...base,
                            // width: 300
                          })
                        }}
                      />
                      <Select
                        options={(slogansFor === "SUBSCRIBED") ? [...subscribedOrganization, { organizationId: "", organizationName: "ALL" }] : [...unsubscribedOrganization, { organizationId: "", organizationName: "ALL" }]}
                        onChange={(value) => setSelectedOrganization(value)}
                        value={selectedOrganization}
                        className="form-control"
                        placeholder="Select Organization"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        getOptionLabel={(option) => option?.organizationName || option?.ownerEmail}
                        getOptionValue={(option) => option.organizationId}
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minWidth: 200,
                            boxSizing: "content-box"
                          }),
                          menu: (base) => ({
                            ...base,
                            // width: 300
                          })
                        }}
                      />
                      <Select
                        options={[{ label: "For Non Login User", value: "NONLOGIN" }, { label: "For Login User", value: "LOGIN" }]}
                        defaultValue={{ label: "For Non Login User", value: "NONLOGIN" }}
                        onChange={(e) => setUserType(e.value)}
                        value={userType === "LOGIN" ? { label: "For Login User", value: "LOGIN" } : { label: "For Non Login User", value: "NONLOGIN" }}
                        className="form-control mx-2"
                        placeholder="Slogans For"
                        menuPlacement="auto"
                        menuPosition="fixed"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: 35,
                            minWidth: 200,
                            boxSizing: "content-box"
                          }),
                          menu: (base) => ({
                            ...base,
                            // width: 300
                          })
                        }}
                      />
                    </div>

                    <ul className='slogans-list'>
                      <Sortablelist
                        onSortEnd={onSortEndSlogans}
                      >
                        {
                          slogans.map((slogan, i) => {

                            return (<SortableItem key={JSON.stringify(slogan)}>
                              <li className={`slogans-item`} key={JSON.stringify(slogan)} id="admin-key">

                                <span>{slogan.title}  {slogan.sloganType === 'feature' && <span style={{ color: 'orange' }}>(Feature)</span>}</span>



                                <button className="btn btn-delete" onClick={() => {
                                  setBannerDeleteModal(true);
                                  setSloganData(slogan.id);
                                  setSloganFlag("Slogan");
                                }}>
                                  <span className="desktop">Delete</span>
                                  <img src={deleteIconAdmin} className="mobile" alt="search" />
                                </button>
                                <button className="btn btn-edit" onClick={() => {
                                  setActiveSloganType(slogan.id);
                                  setSelectSloganIndex(i + 1);
                                }}>
                                  <span className="desktop">Edit</span>
                                  <img src={editIconAdmin} className="mobile" alt="search" />
                                </button>

                              </li>
                            </SortableItem>)
                          })
                        }
                      </Sortablelist>

                      {/* {
                     slogans &&
                     slogans.map(slogan => (
                       <li className="slogans-item">
                         <span>{slogan.title}</span>
                         <button className="btn btn-delete" onClick={() => {
                           setBannerDeleteModal(true);
                           setSloganData(slogan.id);
                           setSloganFlag("Slogan");
                         }}>
                           <span className="desktop">Delete</span>
                           <img src={deleteIconAdmin} className="mobile" alt="search" />
                         </button>
                         <button className="btn btn-edit" onClick={() => setActiveSloganType(slogan.id)}>
                           <span className="desktop">Edit</span>
                           <img src={editIconAdmin} className="mobile" alt="search" />
                         </button>
                       </li>
                     ))
                   } */}
                    </ul>
                    <div className="add-slogan">
                      <button
                        onClick={handleAddSloganClick}
                        className='btn btn-add-slogan'
                      // onClick={() => {
                      //   setAddGameInSloganModal(true);
                      //   setSloganData("Add");
                      // }}
                      >Add Slogan</button>
                    </div>
                  </div>
                </>
              )}
              {isAdmin && activeTabType === "Webinar" && (
                <Webinar />
              )}
              {isAdmin && activeTabType === 'Feature' && (
                <>
                  <FeatureBanner />
                </>
              )}
              {bannerCreateModal && (
                <BannerCreateModalOrg
                  setBannerCreateModal={setBannerCreateModal}
                  bannerEdit={bannerEdit}
                  bannerEditGameDetails={bannerEditGameDetails}
                />
              )}
              {showAllModal && (
                <ShowAllModal
                  items={gamePosition[sloganType]}
                  setShowAllModal={setShowAllModal}
                  setBannerDeleteModal={setBannerDeleteModal}
                  setAddGameInSloganModal={setAddGameInSloganModal}
                  setSloganData={setSloganData}
                  setSloganFlag={setSloganFlag}
                  sloganData={sloganData}
                  setGameDetails={setGameDetails}
                  setConfirmSloganSortModal={setConfirmSloganSortModal}
                  sloganType={sloganType}
                  modalTitle={modalTitle}
                  onSortEndDrag={onSortEndDrag}
                  gameOrder={gameOrder}
                  setGameOrder={setGameOrder}

                />
              )}
              {bannerDeleteModal && (
                <BannerDeleteModal
                  bannerGameId={bannerGameId}
                  setBannerDeleteModal={setBannerDeleteModal}
                  sloganFlag={sloganFlag}
                  gameDetails={gameDetails}
                  setSloganFlag={setSloganFlag}
                  sloganData={sloganData}
                  games={gamePosition[activeSloganType]}
                  selectedOrganization={selectedOrganization.organizationId}
                  organizationSubscribedStaus={organizationSubscribedStaus}
                  selectedRegion={selectedRegion?.regionId}
                  userType={userType}
                  slogansFor={slogansFor}
                  gameOrder={gameOrder}
                  setGameOrder={setGameOrder}
                  allSlogans={allSlogans?.data}
                />
              )}
              {addGameInSloganModal && (
                <AddGameInSloganModal
                  setAddGameInSloganModal={setAddGameInSloganModal}
                  sloganData={sloganData}
                  existing={gamePosition[sloganData]}
                  userType={userType}
                  selectedOrganization={selectedOrganization}
                  organizationSubscribedStaus={organizationSubscribedStaus}
                  selectedRegion={selectedRegion?.regionId}
                  sloganEditId={sloganEditId}
                  selectedSlogan={slogans[selectedSloganIndex - 1]}
                  gameOrder={gameOrder}
                  isFeatureSlogan={isFeatureSlogan}
                />
              )}
              {confirmSloganSortModal && (
                <ConfirmSloganSortModal
                  setConfirmSloganSortModal={setConfirmSloganSortModal}
                  updateGamePosition={onUpdateGameOrder}
                  sloganCategory={sloganData}
                />
              )}
            </section>
            {openShareModal && (
              <LikeShareModal
                toggle={openShareModal}
                setOpenShareModal={setOpenShareModal}
                shareLink={shareLink}
              />
            )}
            {updateHomeBanner && (
              <HomeBannerUpdateCard
                setUpdateHomeBanner={setUpdateHomeBanner}
                // bannerEdit={bannerEdit}
                selectedBanner={selectedBanner}
                setHomeBannerEditDetails={setHomeBannerEditDetails}
                homeBannerEditDetails={homeBannerEditDetails}
                subdomainOrgId={subdomainOrgId}
                gameList={allGames?.data}
              />
            )}
            <ConfirmModal modalid="confirm-modal" toggle={openConfirmModal} setOpenConfirmModal={setOpenConfirmModal} title="To Default Banners" question="Are you sure to move to default banners?" confirmFunction={toDefaultBanners} />
            {
              searchText.length === 0 && duration.length === 0 && maxPlayers === "" && gameType.length === 0
                ? (
                  <div className="gameSlideContainer">
                    {
                      allSlogans && allSlogans.data &&
                      allSlogans.data.map(slogan => {

                        if (slogan.sloganType == 'feature' && !isAdmin) {

                        }
                        else {


                          return ((!isAdmin && (!subdomainOrgId && !activeSloganType)) || (!isAdmin && (subdomainOrgId && !activeSloganType)) || (isAdmin && activeSloganType === slogan.id)) && (
                            <section key={slogan.id} className={(hoverSlogan === slogan.id && hover ? "common-section hover" : "common-section gameList") + (isAdmin ? " margin1" : "")} >
                              {isAdmin &&
                                <>
                                  <div className="back-arrow">
                                    <img src={arrowback}
                                      onClick={(e) => {
                                        setActiveSloganType("");
                                        dispatch(getAllSlogans(selectedOrganization.organizationId, userType, organizationSubscribedStaus, selectedRegion?.regionId));
                                      }} alt="back" />
                                  </div>
                                  <br />
                                </>
                              }
                              {
                                isAdmin || !isAdmin ?
                                  (() => {
                                    let flag = false;
                                    for (const game of slogan?.games || []) {
                                      if (gameId.length >= 1 && !gameId.includes(game.id)) {
                                        continue; // Skip this iteration and go to the next game
                                      } else {
                                        flag = true;
                                        break; // Exit the loop
                                      }
                                    }
                                    return flag && (
                                      <SlogansPlayCardHeading
                                        title={slogan.title.split(" ")[0]}
                                        titleHighlight={slogan.title.split(" ").slice(1).join(" ")}
                                        link
                                        sloganData={slogan}
                                        isAdmin={isAdmin}
                                        setShowAllModal={setShowAllModal}
                                        setSloganType={setSloganType}
                                        setModalTitle={setModalTitle}
                                        gamecount={gamePosition[slogan.id] && gamePosition[slogan.id].length}
                                        sloganType={slogan.id}
                                        setSloganData={setSloganData}
                                        onUpdateGameOrder={onUpdateGameOrder}
                                        sloganUpdated={sloganUpdated}
                                        mainUserType={userType}
                                        sloganFor={slogansFor}
                                        clickAddGame={onClickAddGame}
                                        setOnClickAddGame={setOnClickAddGame}
                                        selectedOrganization={selectedOrganization}
                                      // searchFilter={false}

                                      />
                                    );
                                  })()
                                  : <SlogansPlayCardHeading
                                    title={slogan.title.split(" ")[0]}
                                    titleHighlight={slogan.title.split(" ").slice(1).join(" ")}
                                    link
                                    isAdmin={isAdmin}
                                    setShowAllModal={setShowAllModal}
                                    setSloganType={setSloganType}
                                    setModalTitle={setModalTitle}
                                    // gamecount={gamePosition[slogan.id] && gamePosition[slogan.id].length}
                                    gamecount={slogan?.games?.length}
                                    sloganType={slogan.id}
                                    onUpdateGameOrder={onUpdateGameOrder}
                                    sloganUpdated={sloganUpdated}
                                    selectedOrganization={selectedOrganization}
                                  // searchFilter={false}

                                  />
                              }
                              {!isAdmin ? (
                                <Slider
                                  {...gameslider}>
                                  {
                                    // gamePosition[slogan.id] && gamePosition[slogan.id].map((game) => {
                                    slogan?.games?.map((game) => {
                                      if ((game.id.length >= 1) && !game.id.includes(game.id)) {
                                        return;
                                      }
                                      return (
                                        <PlayCard
                                          key={JSON.stringify(game)}
                                          gameDetail={game}
                                          title={game.title}
                                          srcImage={game.coverMedia}
                                          setOpenShareModal={setOpenShareModal}
                                          setShareLink={setShareLink}
                                          setHover={setHover}
                                          setHoverSlogan={() => setHoverSlogan(slogan.id)}
                                          setGameDetails={setGameDetails}
                                          setOpenMobileHoverCard={setOpenMobileHoverCard}
                                        />
                                      );
                                    })
                                  }
                                </Slider>
                              ) : (
                                <>
                                  <div className="drag-message">
                                    *Drag to rearrange the set and select save.
                                  </div>
                                  <br />
                                  <SortableListGrid
                                    // items={gamePosition[slogan.id]}
                                    items={gameOrder}
                                    onSortEnd={(oldIndex, newIndex) => onSortEndDrag(oldIndex, newIndex, slogans[selectedSloganIndex - 1]?.id)}
                                    setGameDetails={setGameDetails}
                                    setSloganFlag={setSloganFlag}
                                    setBannerDeleteModal={setBannerDeleteModal}
                                    setSloganData={setSloganData}
                                    sloganData={slogan.id}
                                  />
                                  <div className="add-save btn-group">
                                    <button
                                      type="submit"
                                      className="btn btn-secondry"
                                      style={{ float: "right" }}
                                      onClick={() => {
                                        // setAddGameInSloganModal(true);
                                        // setSloganData(slogan.id);
                                        setOnClickAddGame(true);
                                      }}
                                    >
                                      Add Games
                                    </button>
                                    <button
                                      type="submit"
                                      className="btn btn-primary"
                                      style={{ float: "right" }}
                                      onClick={() => {
                                        setConfirmSloganSortModal(true);
                                        setSloganData(slogan.id);
                                      }}
                                    >
                                      Save
                                    </button>
                                  </div>
                                  {/* <hr className="seperator" /> */}
                                </>
                              )}
                            </section>
                          );
                        }
                      })
                    }
                    {!isAdmin && orgRoles.includes(role) && currentlyPlayingGames && currentlyPlayingGames.data && currentlyPlayingGames.data.length > 0 &&
                      <section className={hoverSlogan === "Currently Active For Me" && hover ? "common-section hover" : "common-section gameList"}>
                        <PlayCardHeading
                          title="Currently"
                          titleHighlight="Active For Me"
                          link
                          isAdmin={isAdmin}
                          setShowAllModal={setShowAllModal}
                          setSloganType={setSloganType}
                          setModalTitle={setModalTitle}
                          sloganType={"Currently Active For Me"}
                          gamecount={currentlyPlayingGames && currentlyPlayingGames.data && currentlyPlayingGames.data.length}
                        />
                        <Slider {...gameslider}>
                          {[...currentlyPlayingGames.data].reverse().map((game) => {
                            return (
                              <PlayCard
                                key={JSON.stringify(game)}
                                title={game.title}
                                gameDetail={game}
                                srcImage={game.coverMedia}
                                setOpenShareModal={setOpenShareModal}
                                setShareLink={setShareLink}
                                setHover={setHover}
                                setHoverSlogan={() => setHoverSlogan("Currently Active For Me")}
                                setGameDetails={setGameDetails}
                                setOpenMobileHoverCard={setOpenMobileHoverCard}
                              />
                            );
                          })}
                        </Slider>
                      </section>
                    }
                    {!isAdmin && orgRoles.includes(role) && previouslyPlayedGames && previouslyPlayedGames.data && previouslyPlayedGames.data.length > 0 &&
                      <section className={hoverSlogan === "Previously Played By Me" && hover ? "common-section hover gameListHover" : "common-section gameList"}>
                        <PlayCardHeading
                          title="Previously"
                          titleHighlight="Played By Me"
                          link
                          isAdmin={isAdmin}
                          setShowAllModal={setShowAllModal}
                          setSloganType={setSloganType}
                          setModalTitle={setModalTitle}
                          sloganType={'Previously Played By Me'}
                          gamecount={previouslyPlayedGames && previouslyPlayedGames.data && previouslyPlayedGames.data.length}
                        />
                        <Slider {...gameslider}>
                          {previouslyPlayedGames.data.map((game) => {
                            return (
                              <PlayCard
                                key={JSON.stringify(game)}
                                title={game.title}
                                gameDetail={game}
                                srcImage={game.coverMedia}
                                setOpenShareModal={setOpenShareModal}
                                setShareLink={setShareLink}
                                setHover={setHover}
                                setHoverSlogan={() => setHoverSlogan("Previously Played By Me")}
                                previouslyPlayed
                                setGameDetails={setGameDetails}
                                setOpenMobileHoverCard={setOpenMobileHoverCard}
                              />
                            );
                          })}
                        </Slider>
                      </section>
                    }
                  </div>
                ) :
                (searchText.length > 0 || duration.length > 0 || gameType.length > 0) && role !== ROLES.EXTRAMILE_SUPERADMIN ?
                  (
                    <div className="gameSlideContainer">
                      {
                        searchResultsV3 && searchResultsV3.data &&
                        searchResultsV3.data.map(slogan => {
                          return ((!isAdmin && (!subdomainOrgId && !activeSloganType)) || (!isAdmin && (subdomainOrgId && !activeSloganType)) || (isAdmin && activeSloganType === slogan.id)) && (
                            <section key={slogan.id} className={(hoverSlogan === slogan.id && hover ? "common-section hover" : "common-section gameList") + (isAdmin ? " margin1" : "")} >
                              {
                                !isAdmin ?
                                  (() => {
                                    let flag = false;
                                    for (const game of slogan?.games || []) {
                                      if (gameId.length >= 1 && !gameId.includes(game.id)) {
                                        continue; // Skip this iteration and go to the next game
                                      } else {
                                        flag = true;
                                        break; // Exit the loop
                                      }
                                    }
                                    return flag && (
                                      <SlogansPlayCardHeading
                                        title={slogan.title.split(" ")[0]}
                                        titleHighlight={slogan.title.split(" ").slice(1).join(" ")}
                                        link
                                        sloganData={slogan}
                                        isAdmin={isAdmin}
                                        setShowAllModal={setShowAllModal}
                                        setSloganType={setSloganType}
                                        setModalTitle={setModalTitle}
                                        gamecount={gamePosition[slogan.id] && gamePosition[slogan.id].length}
                                        sloganType={slogan.id}
                                        setSloganData={setSloganData}
                                        onUpdateGameOrder={onUpdateGameOrder}
                                        sloganUpdated={sloganUpdated}
                                        mainUserType={userType}
                                        sloganFor={slogansFor}
                                        clickAddGame={onClickAddGame}
                                        setOnClickAddGame={setOnClickAddGame}
                                        selectedOrganization={selectedOrganization}
                                        searchFilter={true}

                                      />
                                    );
                                  })()
                                  : <SlogansPlayCardHeading
                                    title={slogan.title.split(" ")[0]}
                                    titleHighlight={slogan.title.split(" ").slice(1).join(" ")}
                                    link
                                    isAdmin={isAdmin}
                                    setShowAllModal={setShowAllModal}
                                    setSloganType={setSloganType}
                                    setModalTitle={setModalTitle}
                                    // gamecount={gamePosition[slogan.id] && gamePosition[slogan.id].length}
                                    gamecount={slogan?.games?.length}
                                    sloganType={slogan.id}
                                    onUpdateGameOrder={onUpdateGameOrder}
                                    sloganUpdated={sloganUpdated}
                                    selectedOrganization={selectedOrganization}
                                    searchFilter={true}

                                  />
                              }
                              {!isAdmin && (
                                <Slider
                                  {...gameslider}>
                                  {
                                    // gamePosition[slogan.id] && gamePosition[slogan.id].map((game) => {
                                    slogan?.games?.map((game) => {
                                      if ((game.id.length >= 1) && !game.id.includes(game.id)) {
                                        return;
                                      }
                                      return (
                                        <PlayCard
                                          key={JSON.stringify(game)}
                                          gameDetail={game}
                                          title={game.title}
                                          srcImage={game.coverMedia}
                                          setOpenShareModal={setOpenShareModal}
                                          setShareLink={setShareLink}
                                          setHover={setHover}
                                          setHoverSlogan={() => setHoverSlogan(slogan.id)}
                                          setGameDetails={setGameDetails}
                                          setOpenMobileHoverCard={setOpenMobileHoverCard}
                                        />
                                      );
                                    })
                                  }
                                </Slider>
                              )}

                            </section>
                          );
                        })
                      }
                      {!isAdmin && orgRoles.includes(role) && currentlyPlayingGames && currentlyPlayingGames.data && currentlyPlayingGames.data.length > 0 &&
                        <section className={hoverSlogan === "Currently Active For Me" && hover ? "common-section hover" : "common-section gameList"}>
                          <PlayCardHeading
                            title="Currently"
                            titleHighlight="Active For Me"
                            link
                            isAdmin={isAdmin}
                            setShowAllModal={setShowAllModal}
                            setSloganType={setSloganType}
                            setModalTitle={setModalTitle}
                            sloganType={"Currently Active For Me"}
                            gamecount={currentlyPlayingGames && currentlyPlayingGames.data && currentlyPlayingGames.data.length}
                          />
                          <Slider {...gameslider}>
                            {[...currentlyPlayingGames.data].reverse().map((game) => {
                              return (
                                <PlayCard
                                  key={JSON.stringify(game)}
                                  title={game.title}
                                  gameDetail={game}
                                  srcImage={game.coverMedia}
                                  setOpenShareModal={setOpenShareModal}
                                  setShareLink={setShareLink}
                                  setHover={setHover}
                                  setHoverSlogan={() => setHoverSlogan("Currently Active For Me")}
                                  setGameDetails={setGameDetails}
                                  setOpenMobileHoverCard={setOpenMobileHoverCard}
                                />
                              );
                            })}
                          </Slider>
                        </section>
                      }
                      {!isAdmin && orgRoles.includes(role) && previouslyPlayedGames && previouslyPlayedGames.data && previouslyPlayedGames.data.length > 0 &&
                        <section className={hoverSlogan === "Previously Played By Me" && hover ? "common-section hover gameListHover" : "common-section gameList"}>
                          <PlayCardHeading
                            title="Previously"
                            titleHighlight="Played By Me"
                            link
                            isAdmin={isAdmin}
                            setShowAllModal={setShowAllModal}
                            setSloganType={setSloganType}
                            setModalTitle={setModalTitle}
                            sloganType={'Previously Played By Me'}
                            gamecount={previouslyPlayedGames && previouslyPlayedGames.data && previouslyPlayedGames.data.length}
                          />
                          <Slider {...gameslider}>
                            {previouslyPlayedGames.data.map((game) => {
                              return (
                                <PlayCard
                                  key={JSON.stringify(game)}
                                  title={game.title}
                                  gameDetail={game}
                                  srcImage={game.coverMedia}
                                  setOpenShareModal={setOpenShareModal}
                                  setShareLink={setShareLink}
                                  setHover={setHover}
                                  setHoverSlogan={() => setHoverSlogan("Previously Played By Me")}
                                  previouslyPlayed
                                  setGameDetails={setGameDetails}
                                  setOpenMobileHoverCard={setOpenMobileHoverCard}
                                />
                              );
                            })}
                          </Slider>
                        </section>
                      }
                    </div>
                  )
                  : <LoadingComponent loaded={loaded} >
                    {
                      searchResults && searchResults.data ? (
                        <section className={hover ? "common-section hover gameListHover" : "common-section gameList"}>
                          {searchResults.data.length > 0 ? (
                            <div className="gameSlideContainer">
                              <PlayCardHeading title="Search" titleHighlight="Results..." link isAdmin={isAdmin}
                                setShowAllModal={setShowAllModal}
                                setSloganType={setSloganType}
                                setModalTitle={setModalTitle}
                                sloganType={'Search Results...'}
                                gamecount={searchResults && searchResults.data && searchResults.data.length}
                              />
                              <Slider {...gameslider}>
                                {searchResults.data.map((game) => {
                                  return (
                                    <PlayCard
                                      key={JSON.stringify(game)}
                                      title={game.title}
                                      gameDetail={game}
                                      srcImage={game.coverMedia}
                                      setOpenShareModal={setOpenShareModal}
                                      setShareLink={setShareLink}
                                      setHover={setHover}
                                      setHoverSlogan={setHoverSlogan}
                                      setGameDetails={setGameDetails}
                                      setOpenMobileHoverCard={setOpenMobileHoverCard}
                                    />
                                  );
                                })}
                              </Slider>
                            </div>
                          ) : (
                            <>
                              <PlayCardHeading title="Search" titleHighlight="Results..." />
                              <div className="no-data-home">
                                <h4> The game you are searching for is currently not available.</h4>
                              </div>
                            </>
                          )}
                        </section>
                      ) : null}
                  </LoadingComponent>
            }
          </div>
          {
            openMobileHoverCard &&
            <PlayCardMobileHoverCard
              manage={false}
              userInfo={userInfo}
              setOpenShareModal={setOpenShareModal}
              gameDetail={gameDetails}
              setOpenMobileHoverCard={setOpenMobileHoverCard}
              setShareLink={setShareLink}
            />
          }

        </main>
      ) : !isAdmin && !UserInfoLoading && !showAllGames ? (
        <NonLoggedInHomePage onStatusChange={handleLoadedStatus} onButtonClick={EnablesAllGames} onBtnClick={EnablesAllGames} onButtonExist={handleButtonExist} cameFromCalendar={cameFromCalendar} />
      ) : (
        <></>
      )}


      {contactUs && !isAdmin ? <Footer {...props} isAdmin={isAdmin} contactUs={contactUs.data} /> : ""}
    </div>
  );
};

const HomePage = (props) => {

  const [isScrolled, setIsScrolled] = useState(false);
  const [buttonExists, setButtonExists] = useState(false);
  const handleButtonExist = (exists) => {
    setButtonExists(exists);
  };
  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  if (isAdmin)
    return (
      <div className='admin-homepage'>
        <PageLayout
          sidebartitle=""
          active={"Homepage"}
          category
          sideBarContents={sidebarContentAdmin}
          profile
          {...props}
          signOut={signOut}
          {...props}
          isAdmin={isAdmin}
        >
          <Dashboard {...props} buttonExists={handleButtonExist} />
        </PageLayout>
      </div>
    )
  else
    return (
      <>
        <Helmet>
          <title>Home</title>
          <meta name="description" content="Home page" />
        </Helmet>
        <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} isScrolled={isScrolled} buttonExists={buttonExists} />
        <Dashboard {...props} buttonExists={handleButtonExist} />
      </>
    )

}

export default React.memo(HomePage);
