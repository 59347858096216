

import React, { useCallback, useEffect, useRef, useState } from 'react';


import { BASE_URL, S3_BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../header/header';
import uploadImg from "../../assets/teams-images/upload-btn.png"
import { FaUsersLine } from 'react-icons/fa6';
import arrowback from "../../assets/images/arrow-back.svg";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import { getUser, logOut } from '../../redux/actions/userAction';
import DeleteTeamModal from '../modal/deleteTeamModal';
import { uploadFile } from '../../redux/actions/commonActions';
import AddTeamModal from '../modal/addTeamModal';
import PlayIcon from "../../assets/backgrounds/favicon.png"
import EditTeamModal from '../modal/editTeamModal';
import Confirm from '../modal/confirm';
import TeamsLogoUpload from '../modal/uploadTeamsLogoModal';
import { BiSolidLeftArrow } from "react-icons/bi";
import { Helmet } from 'react-helmet-async';




const EditTeams = (props) => {


    const [loaded, setLoaded] = useState(true);
    const [teams, setTeams] = useState([]);
    const [isTeams, setIsTeams] = useState(true)
    const [selectedTeamInfo, setSelectedTeamInfo] = useState()


    const [file, setFile] = useState(null)

    const [openAddTeamModal, setOpenAddTeamModal] = useState(false);
    const [openEditTeamModal, setOpenEditTeamModal] = useState(false);

    const [openConfirmUploadModal, setOpenConfirmUploadModal] = useState(false)
    const [uploadFlag, setUploadFlag] = useState(false)

    const [editTeamInfo, setEditTeamInfo] = useState({})

    const [deleteTeamId, setDeleteTeamId] = useState('');
    const [toggalDeleteTeamModel, setToggalDeleteTeamModal] = useState(false);


    const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
    const { orgTeams } = getAllOrgsTeam;
    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;
    const fileUpload = useSelector(state => state.uploadedFile);
    const { uploadedFile } = fileUpload;
    const history = useHistory();
    const dispatch = useDispatch();

    // const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const teamId = queryParams.get('teamId');

    const fileInputRef = useRef(null);


    // useEffect(() => {
    //     if (uploadedFile && uploadedFile.data) {
    //         setTeamLogo(uploadedFile.data.path)

    //     }

    // }, [uploadedFile, fileUpload])


    // useEffect(() => {
    //     const callBack = async (teamLogo) => {

    //         const body = {
    //             teamcaptainInfo: null,
    //             usersTeam: null,
    //             teamLogoData: {
    //                 teamId: selectedTeamInfo.id,
    //                 logo: teamLogo ? teamLogo : null
    //             },
    //             teamName: {
    //                 name: null,
    //                 teamId: null,
    //             }
    //         }

    //         if (userInfo && userInfo?.data && userInfo?.data?.organizationId) {
    //             console.log("team body: " + JSON.stringify(body));

    //             const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/update-team", body);
    //             if (response && response.data && response.data.message === "org user Updated sucessfully") {
    //                 successAlert("sucessfully added user to team");

    //                 dispatch(getOrgTeams(userInfo.data.organizationId))


    //             }
    //             else {
    //                 failureAlert("!Opps something went wrong")
    //             }
    //             setSelectedTeamInfo(null)
    //         }
    //     }

    //     if (uploadedFile && uploadedFile.data) {
    //         let isImagePath = isImageFileType(uploadedFile.data.path)
    //         if (isImagePath) {
    //             callBack(uploadedFile.data.path)
    //         }

    //     }

    // }, [uploadedFile, fileUpload])


    // const handleCsvFileClick = () => {
    //     addTeamMembersCsv.current.click();
    // };


    const isImageFileType = (path) => {
        const validImageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
        const extension = path.split('.').pop().toLowerCase();
        return validImageExtensions.includes(extension);
    };

    useEffect(() => {
        if (userInfo && userInfo.data && userInfo.data.organizationId) {
            setTeams([])
            
            dispatch(getOrgTeams(userInfo.data.organizationId))
        }

    }, [userInfo])

    useEffect(() => {

        if (orgTeams && orgTeams.message === "Sucessfully fetch org teams") {
            setTeams(orgTeams.data);
            setIsTeams(true);


        }

        else if (orgTeams && orgTeams.message === "org teams not available") {
            setTeams([]);
            setIsTeams(false);
        }

    }, [getAllOrgsTeam])



    useEffect(() => {
        if (teams && teams.length === 0 && isTeams === false) {
            setIsTeams(true)
            history.push('/manage-users-team');

        }
    }, [teams])


    const handleEditTeamModal = (e, team) => {
        e.preventDefault();
        setEditTeamInfo(team);
        setOpenEditTeamModal(true);
    }


    const handleBack = () => {
        history.push('/manage-users-team')
    }


    const handleButtonClick = (team) => {
        setSelectedTeamInfo(team)
        setOpenConfirmUploadModal(true);

    };

    // const handleTeamLogoUpload = (e,) => {

    //     setOpenConfirmUploadModal(true);


    // }



    // const fileChanged = async (e) => {
    //     // e.persist();
    //     // const file = e.target.files[0]
    //     // e.target.value = null;
    //     const img = new Image();
    //     let width, height;
    //     img.onload = async function () {
    //         height = this.height;
    //         width = this.width;
    //         // if (e.target.name === "team-logo-upload") {
    //         if (width === 74 && height === 74) {
    //             const data = new FormData();
    //             data.append("team-logo", file);
    //             const response = await dispatch(uploadFile(data));
    //             if (response?.status?.includes?.("417"))
    //                 failureAlert("Uploaded file contains some malware!");
    //             else if (response?.status?.includes?.("500"))
    //                 failureAlert("File format not supported");
    //         }
    //         else {
    //             failureAlert("The team logo dimensions should be 74 x 74.")

    //         }
    //         setUploadFlag(false)

    //         // }
    //     }
    //     const _URL = window.URL || window.webkitURL;
    //     img.src = _URL.createObjectURL(file);
    // }

    const handleScheduleGame = () => {
        history.push('/schedule/teams-games')
    }
    // const handleEditTeamsClick = () => {
    //     history.push("/manage-edit-teams")
    // }

    const handleAddNewTeam = (e) => {
        e.preventDefault()
        setOpenAddTeamModal(true)
    }

    function capitalizeWords(str) {
        if (!str) {
            return '';
        }
        return str.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    }


    const handleDeleteTeam = async (teamId) => {
        setLoaded(false)
        if (!teamId) {
            return failureAlert("failed to delete team")
        }
        const body = {
            teamId: teamId
        }
        const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/delete-team", body);
        if (response && response.data && response.data.message === "sucessfully deleted team") {
            successAlert("Sucessfully deleted team")
            if (userInfo && userInfo.data && userInfo.data.organizationId) {

                dispatch(getOrgTeams(userInfo.data.organizationId));
            }
        }
        else {
            failureAlert("failed to delete team")
        }

        setDeleteTeamId('')
        setToggalDeleteTeamModal(false)
        setLoaded(true)
    }


    const handleTemmDeleteClick = (teamId) => {
        setDeleteTeamId(teamId)
        setToggalDeleteTeamModal(true)
    }

    const signOut = async () => {
        await dispatch(logOut());
        if (props.history)
            props.history.push("/");

    };

    function capitalizeWords(str) {
        if (!str) {
            return '';
        }
        return str.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    }

    return (<>
    <Helmet>
        <title>Edit Teams</title>
        <meta name="description" content="Edit Teams" />
        <meta name="keywords" content="Edit Teams" />
    </Helmet>
        <Header profile signOut={signOut} />
        <div className="flex flex-col justify-start items-center pt-[80px] md:pt-[110px] lg:pt-[110px] pr-5 md:pr-8 lg:pr-10 pb-5 pl-5 md:pl-8 lg:pl-10 w-[100%] h-[100vh] overflow-y-scroll">

            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            <div className="flex justify-start items-center w-full h-auto">
                <div className="flex items-center w-[65px] cursor-pointer" onClick={handleBack}>
                    <BiSolidLeftArrow className='w-[18px] h-[18px] text-black' />
                    <span className='ml-1 font-sans text-[18px] text-black'>Back</span>
                </div>

                {/* <img src={arrowback} alt="back" onClick={handleBack} className='flex w-[30px] h-[30px] hover:cursor-pointer' /> */}

            </div>
            <div className="relative flex justify-center items-center w-[100%]">
                <h1 className='font-sans font-semibold text-[32px] text-black sm:text-[35px] md:text-[40px] lg:text-[48px]'> Edit Teams</h1>
                {/* <div className="left-0 absolute md:flex lg:flex items-center hidden w-[90px] h-[30px] hover:cursor-pointer">
                    <BiSolidLeftArrow className='w-[22px] h-[22px] text-black'/>
                    <span className='ml-1 font-sans text-[22px] text-black'>Back</span>
                </div> */}
                {/* <img src={arrowback} alt="back" onClick={handleBack} className='left-0 absolute md:flex lg:flex hidden w-[30px] h-[30px] hover:cursor-pointer' /> */}
            </div>

            <div className="flex justify-center items-center mt-[25px] w-[100%]">
                <div className="flex flex-row items-center p-[15px] md:p-[20px] lg:p-[20px] md:pr-[30px] lg:pr-[30px] w-full h-[65px] md:h-[83px] lg:h-[83px]"
                    style={{
                        // background: 'linear-gradient(90deg, #F5899E 0%, #F7C2B5 100%)'
                        background: 'linear-gradient(90deg, #E6F4FC, #7FCFFF, #E6F4FC)'

                    }}
                >
                    <div className="flex justify-center items-center w-3/12 md:w-2/12 lg:w-2/12">
                        <span className='font-sans font-semibold text-[#23282E] text-[12px] sm:text-[14px] md:text-[15px] lg:text-[17px]'>Team Logo</span>
                    </div>
                    <div className="flex justify-start items-center w-3/12 md:w-2/12 lg:w-2/12">
                        <span className='pl-2 font-sans font-semibold text-[#23282E] text-[12px] sm:text-[14px] md:text-[15px] lg:text-[17px] whitespace-nowrap'>Team Name</span>
                    </div>

                    <div className="flex flex-row-reverse items-center w-6/12 md:w-8/12 lg:w-8/12">
                        <button
                            className={` btn btn-primary  w-[70px] h-[28px] md:w-[120px] md:h-[33px] lg:w-[120px] lg:h-[33px] !text-[12px] sm:!text-[14px] md:!text-[15px] lg:text-[17px]`}
                            style={{
                                padding: '0px',
                            }}
                            onClick={handleAddNewTeam}
                        >
                            +Add Team
                        </button>
                    </div>
                </div>

            </div>

            <div className="flex flex-col justify-start items-center w-full max-h-[400px] overflow-y-scroll no-scrollbar">
                {
                    teams && teams.length > 0 && teams.map((team, index) => {
                        return (
                            <div className="flex flex-row items-center border-[0.25px] mt-[20px] p-[10px] md:p-[20px] lg:p-[20px] pr-[15px] md:pr-[30px] lg:pr-[30px] rounded-[6px] w-full min-h-[83px] boreder-[#707070]">
                                <div className="relative flex justify-center items-center w-3/12 md:w-2/12 lg:w-2/12 h-full">
                                    {/* <div className="right-[0px] sm:right-[12px] md:right-[12px] lg:right-[30px] bottom-[0px] md:bottom-[-20px] lg:bottom-[-20px] absolute flex justify-center items-center rounded-[5px] w-[25px] sm:w-[28px] md:w-[28px] lg:w-[30px] h-[25px] sm:h-[28px] md:h-[28px] lg:h-[30px] cursor-pointer" onClick={(e) => handleButtonClick(team)}>
                                            <img src={uploadImg} alt="upload-imag" />
                                            <input
                                                type="file"
                                                className="hidden opacity-0 rounded-[6px] w-[30px] h-[30px] cursor-pointer"
                                                name={team.id}
                                                onChange={handleTeamLogoUpload} // Wrap this in an inline arrow function
                                                accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                                ref={fileInputRef}

                                            />
                                        </div> */}
                                    <div className="relative flex justify-center items-center rounded-full w-[45px] sm:w-[65px] md:w-[65px] lg:w-[74px] h-[45px] sm:h-[65px] md:h-[65px] lg:h-[74px]"
                                        style={{
                                            background: index % 2 === 0 ? 'transparent linear-gradient(180deg, #6A5AE0 0%, #A89DFF 100%) 0% 0% no-repeat padding-box' : 'transparent linear-gradient(180deg, #F0E2F0 0%, #A0DAEB 100%) 0% 0% no-repeat padding-box'
                                        }}
                                    >
                                        <div className="right-[-5px] sm:right-[-3px] md:right-[-2px] lg:right-[-5px] bottom-[-9px] md:bottom-[-9px] lg:bottom-[-8px] absolute flex justify-center items-center rounded-[5px] w-[25px] sm:w-[28px] md:w-[28px] lg:w-[35px] h-[25px] sm:h-[28px] md:h-[28px] lg:h-[35px] cursor-pointer" onClick={(e) => handleButtonClick(team)}>
                                            <img src={uploadImg} alt="upload-imag" onClick={() => handleButtonClick(team)} />
                                            {/* <input
                                                type="file"
                                                className="hidden opacity-0 rounded-[6px] w-[30px] h-[30px] cursor-pointer"
                                                name={team.id}
                                                onChange={handleTeamLogoUpload} // Wrap this in an inline arrow function
                                                accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                                ref={fileInputRef}

                                            /> */}
                                        </div>
                                        {
                                            team?.teamLogo ? (
                                                <img
                                                    src={`${S3_BASE_URL}${team.teamLogo}`}
                                                    alt="logo" className="rounded-full w-full h-full object-cover" />
                                            ) : null
                                        }

                                    </div>
                                </div>
                                <div className="flex flex-col justify-start items-center w-6/12 md:w-3/12 lg:w-3/12">
                                    <div className="flex flex-row items-center w-full h-[50%]">
                                        <p className='pl-2 sm:pl-1 md:pl-0 lg:pl-0 max-w-[55%] max-h-full font-sans font-semibold text-[#23282E] text-[12px] text-ellipsis sm:text-[14px] md:text-[15px] lg:text-[17px] whitespace-nowrap overflow-x-hidden'>{team?.name ? capitalizeWords(team.name) : ''}</p>
                                        <span className='ml-[3px] font-sans font-semibold text-[#23282E] text-[12px] sm:text-[14px] md:text-[15px] lg:text-[17px]'>{`(${team.members.length} Members)`}</span>

                                    </div>
                                    <div className="grid grid-flow-col w-full h-[50%]">
                                        <p className='pl-2 sm:pl-1 md:pl-0 lg:pl-0 w-full font-sans font-semibold text-[#696f76] text-[9px] text-ellipsis sm:text-[10px] md:text-[11px] lg:text-[11px] whitespace-nowrap overflow-hidden'>
                                            {
                                                team && team.members.length > 0 && team.members.map((member, idx) => {
                                                    const firstName = member.firstName ? capitalizeWords(member.firstName) : '';
                                                    const lastName = member.lastName ? capitalizeWords(member.lastName) : '';
                                                    const isLastMember = idx + 1 === team.members.length;

                                                    return (
                                                        <span key={idx}>
                                                            {firstName} {lastName}{!isLastMember && ','}
                                                        </span>
                                                    );
                                                })
                                            }
                                        </p>
                                    </div>


                                </div>

                                <div className="flex flex-row-reverse items-center w-3/12 md:w-7/12 lg:w-7/12">

                                    <div className="flex justify-center items-center ml-[15px] rounded-[6px] w-[25px] md:!w-[32px] lg:!w-[32px] h-[25px] md:!h-[32px] lg:!h-[32px] cursor-pointer">
                                        <div className="animated-btn-tooltip">


                                            <button class="w-[25px] md:!w-[32px] lg:!w-[32px] h-[25px] md:!h-[32px] lg:!h-[32px] session-delete-button"

                                                onClick={(e) => handleTemmDeleteClick(team.id)}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 69 14"
                                                    class="bin-top w-[9px] md:!w-[11px] lg:!w-[11px] svgIcon"
                                                >
                                                    <g clip-path="url(#clip0_35_24)">
                                                        <path
                                                            fill="black"
                                                            d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_35_24">
                                                            <rect fill="white" height="14" width="69"></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 69 57"
                                                    class="bin-bottom svgIcon"
                                                >
                                                    <g clip-path="url(#clip0_35_22)">
                                                        <path
                                                            fill="black"
                                                            d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                        ></path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_35_22">
                                                            <rect fill="white" height="57" width="69"></rect>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </button>
                                            <div className="left-[6px] md:!left-[15px] lg:!left-[15px] hover-tooltip" role="tooltip" style={{ top: '-24px' }}>
                                                <span>Delete</span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="flex flex-row justify-between items-center w-[25px] md:w-[32px] lg:w-[32px] h-[19px] cursor-pointer" onClick={(e) => { handleEditTeamModal(e, team) }}>
                                        <div className="bg-[#E25569] rounded-full w-[6px] md::w-[8px] lg:w-[8px] h-[6px] md:h-[8px] lg:h-[8px]"></div>
                                        <div className="bg-[#E25569] rounded-full w-[6px] md::w-[8px] lg:w-[8px] h-[6px] md:h-[8px] lg:h-[8px]"></div>
                                        <div className="bg-[#E25569] rounded-full w-[6px] md::w-[8px] lg:w-[8px] h-[6px] md:h-[8px] lg:h-[8px]"></div>

                                    </div>
                                    <div className="md:flex lg:flex flex-row items-center hidden mr-3 h-[19px]">
                                        <span className='font-sans text-[#696f76] text-[13px]'>(Click the menu icon to add the team name and participants.)</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="flex justify-center items-center mt-[40px] w-full h-[48px]">
                <button
                    className={`w-[130px] h-[40px] md:w-[160px] md:h-[48px] lg:w-[160px] lg:h-[48px] !text-[14px] md:text-[16px] lg:text-[16px] btn btn-primary  `}
                    onClick={handleScheduleGame}
                    disabled={teams && teams.length > 0 ? false : true}
                >
                    Create Game
                </button>
            </div>

            {
                toggalDeleteTeamModel &&

                <DeleteTeamModal toggle={toggalDeleteTeamModel} teamId={deleteTeamId} setOpenAddTeamModal={setToggalDeleteTeamModal} setDeleteTeamId={setDeleteTeamId} handleDeleteFun={handleDeleteTeam} textInfo={"Are you sure you want to delete this team?"} />
            }
            {
                openAddTeamModal &&
                <AddTeamModal modalid={"usersAddedModal"} setOpenAddTeamModal={setOpenAddTeamModal} toggle={openAddTeamModal} />
            }

            {
                <EditTeamModal modalid={"editTeamModal"} setOpenAddTeamModal={setOpenEditTeamModal} toggle={openEditTeamModal} editTeamInfo={editTeamInfo} />
            }

            {openConfirmUploadModal && (

                <TeamsLogoUpload modalid={"upload-team-logo"} setOpenAddTeamModal={setOpenConfirmUploadModal} toggle={openConfirmUploadModal} selectedTeamInfo={selectedTeamInfo} setSelectedTeamInfo={setSelectedTeamInfo} />

            )}
        </div >


    </>

    );
};
export default EditTeams;
