import React, { useEffect, useState } from 'react';

import './leaderboard.css';
import morevertical from '../../assets/images/more-vertical.svg';
import { useSelector } from 'react-redux';
import { REPORT_LEADERBOARD_TABLE_DATA } from '../../constants';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
// import { BASE_URL, axiosApiInstance } from '../../helpers/helper';
import { axiosApiInstance, BASE_URL, decryptData, encryptData, failureAlert, successAlert } from "../../helpers/helper";
import { center, flex, game, guest, report, state } from 'synonyms/dictionary';
import { useDispatch } from 'react-redux';
import { getGameReport, DownloadGuestSessionReport } from '../../redux/actions/reportsActions';
import axios from 'axios';
import { getTeamsGamesReport } from '../../redux/actions/teamAction';


const TeamLeaderBoard = ({ teamData }) => {
  const mode = window.location.pathname.split("/")[1]

  const getRole = { 'ORG_SUPER_ADMIN': 'OWNER', 'ORG_ADMIN': 'ADMIN', 'EMPLOYEE': 'EMPLOYEE', 'USER': 'EMPLOYEE' };
  const [tableHeader, setTableHeader] = useState(REPORT_LEADERBOARD_TABLE_DATA);

  const [userTeamGamesData, setUserTeamGamesData] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [teamUserId, setTeamUserId] = useState('');

  const TeamsGamesData = useSelector((state) => state.getTeamsReport);
  const {teamReport} = TeamsGamesData;

  const dispatch = useDispatch();

  useEffect(() => {
    const callBack = async () => {

      if (teamReport && teamReport?.data?.userData) {
        const userDataInfo = teamReport.data.userData.filter((item) => item.userId === teamUserId)

        setUserTeamGamesData(userDataInfo);
      }
   
    }
    if (teamUserId && teamUserId !== '' && teamReport && teamReport?.data && teamReport?.data?.message === 'team session report') {
      callBack()
    }
  
  }, [teamUserId, TeamsGamesData])


  const toggleExpandRow = (index, id) => {
    setTeamUserId(id)
    setExpandedRow(expandedRow === index ? null : index);
  };


  function capitalizeWords(str) {
    if (!str) {
      return '';
    }
    return str.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  }

  function formatTime(timeString) {
    const parts = timeString.split(':').map(Number);
    
    let hours = 0, minutes = 0, seconds = 0;
  
    if (parts.length === 2) {
      [minutes, seconds] = parts;
    } else if (parts.length === 3) {
      [hours, minutes, seconds] = parts;
    } else {
      return '00:00:00';
    }
  
    // Check for NaN values
    if (isNaN(minutes) || isNaN(seconds) || isNaN(hours)) {
      return '00:00:00';
    }
  
    // Adjust for cases where minutes overflow into hours
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;
  
    return [
      String(hours).padStart(2, '0'),
      String(minutes).padStart(2, '0'),
      String(seconds).padStart(2, '0')
    ].join(':');
  }

  return (
    <div>
      {/* table for desktop */}
      <div className="overflow-x-auto leader-table report-table">
        <table className="leader-board-table">
          <thead>
            <tr id='report-tb-head'>
              <th className='!pl-[8px] sm:!pl-[20pxlg] md:!pl-[20px] lg:!pl-[20px] !text-start'>Player Name</th>
              <th>Scores</th>
              <th style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>Time Taken</th>
              <th>User Type</th>
            </tr>
          </thead>
          <tbody>

            {
              teamData && teamData.length > 0 ? teamData.map((report, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr onClick={() => toggleExpandRow(index, report.userId)}>
                      <td
                        className='flex justify-start items-center !pl-[8px] sm:!pl-[20pxlg] md:!pl-[20px] lg:!pl-[20px]'
                      >
                        {(report?.firstName) ? (
                          <div className="table-detail">
                            <div className="name-initial">
                              <span>{(report?.firstName && report?.firstName.slice) ? report?.firstName.slice(0, 2).toUpperCase() : report?.email && report?.email.slice && report?.email.slice(0, 2).toUpperCase()}</span>
                            </div>
                            <div className="table-desc">
                              <h4>{report?.firstName && report?.firstName !== "" ? report?.firstName : report?.email}</h4>
                            </div>
                          </div>
                        ) : null

                        }
                      </td>
                      <td>{report?.totalPoints}</td>

                      <td>
                        <div className="reports-dropdown-view" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                          {report?.totalTime ? formatTime(report?.totalTime) : '00:00:00'}

                        </div>
                      </td>
                      <td>{getRole[report?.role] || 'GUEST USER'}</td>
                    </tr>
                    {expandedRow === index && userTeamGamesData && userTeamGamesData?.length > 0 && userTeamGamesData[0].userId === report.userId && (
                      <tr>
                        <td colSpan="5" className='p-0'>
                          <div className="expanded-row-content">
                            {/* <h4>Team Members:</h4>
                                <ul>
                                    {report?.members && report?.members.length > 0 ? report?.members.map((member, idx) => (
                                        <li key={idx}>
                                            <strong>{member.firstName}</strong>: {member.totalPoints} points
                                        </li>
                                    )) : <li>No members found</li>}
                                </ul> */}
                            <table>
                              <thead>
                                <tr>
                                  <th style={{ textAlign: 'start' }}>Game Name</th>
                                  <th> Score</th>
                                  <th> Time</th>

                                </tr>
                              </thead>
                              <tbody>
                                {userTeamGamesData && userTeamGamesData?.length > 0 ? userTeamGamesData.map((member, idx) => (
                                  <tr>
                                    <td style={{ textAlign: 'start' }}>{member.gameName ? `${capitalizeWords(member.gameName)} ${capitalizeWords(member.lastName)}` : ''}</td>
                                    <td>{member.totalPoints}</td>
                                    <td>{member.totalTime ? formatTime(member.totalTime) : '00:00:00'}</td>

                                  </tr>
                                )) : <tr>
                                  <td colSpan={4}>No Memebers found</td>
                                </tr>}

                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>

                )
              }) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeamLeaderBoard;