import { axiosApiInstance, BASE_URL } from "../../helpers/helper";
import * as ActionTypes from "../constants/calendarConstants"


const fetchOccasionGames = (id, searchTerm) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_OCCASION_GAMES_REQUEST });
  try {
    const { data } = await axiosApiInstance.post(BASE_URL + "/api/Calendar/get_ocasion_games", { id, searchTerm });
    dispatch({ type: ActionTypes.GET_OCCASION_GAMES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_OCCASION_GAMES_FAIL,
      payload: error.message
    });
  }
};

const getAllQuartersInfo = (CalendarTabSelected) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_QUARTER_INFO_REQUEST });
  try {
    //  let url = `${BASE_URL}/api/Calendar/all_quarter_info/${CalendarTabSelected}`;
    const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/Calendar/all_quarter_info", { CalendarTabSelected });
    //  const { data, status } = await axiosApiInstance.get(url);
    dispatch({ type: ActionTypes.GET_QUARTER_INFO_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_QUARTER_INFO_FAIL,
      payload: error.message
    });
  }
};

const getAllCalendarData = (CalendarTabSelected) => async (dispatch) => {
  dispatch({ type: ActionTypes.GET_ALL_CALENDAR_DATA_REQUEST });
  try {

    const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/Calendar/get_all_data", { CalendarTabSelected });
    dispatch({ type: ActionTypes.GET_ALL_CALENDAR_DATA_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.GET_ALL_CALENDAR_DATA_FAIL,
      payload: error.message
    });
  }
};

const addQuartersImages = (body) => async (dispatch) => {
  dispatch({ type: ActionTypes.ADD_QUARTER_INFO_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/Calendar/add_quarter_info", body);
    dispatch({ type: ActionTypes.ADD_QUARTER_INFO_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({ type: ActionTypes.ADD_QUARTER_INFO_FAIL, payload: error.message });
  }
};

const addOccasionGamesQuartersData = (body) => async (dispatch) => {
  dispatch({ type: ActionTypes.ADD_QUARTER_GAMES_OCCASIONS_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.post(BASE_URL + "/api/Calendar/add_quarter_games_occasions", body);
    dispatch({ type: ActionTypes.ADD_QUARTER_GAMES_OCCASIONS_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({ type: ActionTypes.ADD_QUARTER_GAMES_OCCASIONS_FAIL, payload: error.message });
  }
};
const updateQuarterData = (path, id ,type) => async (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_QUARTER_INFO_REQUEST,
    payload: { id, path },
  });
  try {
    const obj = {
      path,
      id,
      type
    }
    const { data, status } = await axiosApiInstance.put(
      BASE_URL + "/api/Calendar/update_quarter_info",
      obj,
    );
    dispatch({ type: ActionTypes.UPDATE_QUARTER_INFO_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({ type: ActionTypes.UPDATE_QUARTER_INFO_FAIL, payload: error.response });
  }
};

const updateQuarterColors = (colorIdArr) => async (dispatch) => {
  dispatch({
    type: ActionTypes.UPDATE_QUARTER_COLOR_REQUEST
  });
  try {
    const obj = {
      colorIdArr
    }
    const { data, status } = await axiosApiInstance.put(
      BASE_URL + "/api/Calendar/update_quarter_colors",
      obj,
    );
    dispatch({ type: ActionTypes.UPDATE_QUARTER_COLOR_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({ type: ActionTypes.UPDATE_QUARTER_COLOR_FAIL, payload: error.response });
  }
};
const updateOccasionGamesInQuarter = (body) => async (dispatch) => {
  dispatch({ type: ActionTypes.UPDATE_OCCASION_GAMES_REQUEST });
  try {
    const { data, status } = await axiosApiInstance.post(
      BASE_URL + "/api/Calendar/update_occasion_games",
      body,
    );
    dispatch({ type: ActionTypes.UPDATE_OCCASION_GAMES_SUCCESS, payload: data });
    return status;
  } catch (error) {
    dispatch({
      type: ActionTypes.UPDATE_OCCASION_GAMES_FAIL,
      payload: error.message
    });
  }
};
export {
  addOccasionGamesQuartersData,
  updateQuarterData,
  addQuartersImages,
  getAllQuartersInfo,
  getAllCalendarData,
  updateOccasionGamesInQuarter,
  fetchOccasionGames,
  updateQuarterColors
}