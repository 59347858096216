import React, { useRef, useState } from "react";
import "./bookEvent.css";

import { useDispatch } from "react-redux";
import PageLayout from "../../components/pagelayout/pagelayout";
import { axiosApiInstance, BASE_URL, failureAlert, IsAdmin, REG_EX_URL_FORMAT, S3_BASE_URL, successAlert } from "../../helpers/helper";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { logOut } from "../../redux/actions/userAction";
import Select from "react-select"
import upload from '../../assets/images/upload.svg';
import { useEffect } from "react";
import { gameAllCategory, getAllObjectives, getGameDetail, getAllRegion } from "../../redux/actions/gameDetailAction";
import { getAllOrganisations } from "../../redux/actions/organisationActions";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { addNewGame, updateGame } from "../../redux/actions/homepageActions";
import { ToastContainer } from "react-toastify";
import { uploadFile } from "../../redux/actions/commonActions";
import remove from '../../assets/images/remove.svg';
import _isEqual from "lodash/isEqual";
import { toggleLiveGames } from '../../redux/actions/gameDetailAction';
import Header from "../../components/header/header";
import bg from '../../assets/icons/dummy.jpg';
import iconinstantbook from '../../assets/icons/iconinstantbook.png';
import team from '../../assets/bookAnEvent-img/icon virtual.png';
import Footer from "../../components/footer/footer";
import Accordion from "../../containers/Accordion";
import quotes from "../../assets/icons/quotes.png";
import curiositycorner from "../../assets/icons/curiositycorner.png";
import iconTime from "../../assets/bookAnEvent-img/icon time.png";
import icondifficulty from "../../assets/icons/icondifficulty.png";
import iconcompetition from "../../assets/icons/iconcompetition.png";
import iconparticpants from "../../assets/bookAnEvent-img/icon participants.png";
import icongaurantee from "../../assets/icons/icongaurantee.png";
import iconheadcount from "../../assets/icons/iconheadcount.png";
import iconinnovative from "../../assets/icons/iconinnovative.png";
import iconaccessible from "../../assets/icons/iconaccessible.png";
import iconpricing from "../../assets/icons/iconpricing.png";
import Playbutton from "../../assets/icons/Playbutton.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { content, n } from "synonyms/dictionary";
import AskQuestionModal from "../../components/modal/askEventOuestionModal";
import axios from "axios";
import ImgSliderModal from "../../components/modal/imgSliderModal";

import { MdOutlinePlayCircle } from "react-icons/md";
import { MdOutlineAccessTime } from "react-icons/md";


import YouTube from 'react-youtube';
import { Helmet } from "react-helmet-async";



//import { axiosApiInstance, BASE_URL, decryptData, encryptData, GetUserType, IsAdmin, REG_EX_URL_FORMAT, successAlert, failureAlert } from "../../helpers/helper";
const BookAnEvent = (props) => {

    const [eventGameInfo, setEventGameInfo] = useState();
    const [liveEventsFaqOne, setLiveEventsFaqOne] = useState([]);
    const [liveEventsFaq, setLiveEventsFaq] = useState([]);
    const [eventTotalCost, setEventTotalCost] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState("INR");
    const [mainGameRows, setMainGamesRows] = useState([])
    const [testimonialData, setTestimonialData] = useState([]);

    const [loaded, setLoaded] = useState(true);

    const [allCatGames, setAllCatGames] = useState([]);
    const [formDataTouch, setFormDataTouch] = useState({ email: false, membersCount: false });
    const [isSticky, setIsSticky] = useState(false);
    const [stickToBottom, setStickToBottom] = useState(false);

    const [stickToTop, setStickToTop] = useState(false);
    const [expandedCards, setExpandedCards] = useState({});

    const [participantsOptions, setParticipantsOptions] = useState([]);


    const [formData, setFormData] = useState({
        membersCount: 0,
        email: null
    })

    const [openImgSliderModal, setOpenImgSliderModal] = useState(false);
    const [imgClickIndex, setImgClickIndex] = useState(0);

    const [isPlaying, setIsPlaying] = useState(false);
    const [elementIndex, setElementIndex] = useState(0);

    const [isSmallScreen, setIsSmallScreen] = useState(false);



    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;
    const topDiv = useRef(null)
    const mobiletopDiv = useRef(null)
    const formRef = useRef(null);
    const div2Ref = useRef(null);

    const temDivRef = useRef(null);


    const isAdmin = IsAdmin();
    const dispatch = useDispatch();

    const history = useHistory();

    const { id } = useParams();
    const { title } = useParams();

    const handleScroll = () => {


        const formHeight = formRef.current.offsetHeight;
        const div2Top = div2Ref.current.getBoundingClientRect().top;
        const div2Bottom = div2Ref.current.getBoundingClientRect().bottom;
        const viewportHeight = window.innerHeight;

        // Sticky form while scrolling within div2
        if (div2Top <= 0 && div2Bottom > formHeight + 200) {
            setIsSticky(true);
            setStickToBottom(false);
            setStickToTop(false);
        }
        // Absolute position 200px below the top of div2
        else if (div2Top > 0 && div2Top <= 200) {
            setIsSticky(false);
            setStickToBottom(false);
            setStickToTop(true);
        }
        // Absolute position 200px above the bottom of div2
        else if (div2Bottom <= formHeight + 200) {
            setIsSticky(false);
            setStickToBottom(true);
            setStickToTop(false);
        }
        // Reset position when user is above div2
        else {
            setIsSticky(false);
            setStickToBottom(false);
            setStickToTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToDiv = (divRef) => {
        divRef.current.scrollIntoView();
    };

    useEffect(() => {

        if (!isSmallScreen) {
            scrollToDiv(topDiv);
        }

        if (isSmallScreen) {
            scrollToDiv(mobiletopDiv);
        }
    }, [isSmallScreen]);

    useEffect(() => {
        // On page load, scroll to the first div
        if (!isSmallScreen) {
            scrollToDiv(topDiv);
        }
        if (isSmallScreen) {
            scrollToDiv(mobiletopDiv);
        }

    }, [id, isSmallScreen]);


    useEffect(() => {
        if (userInfo && userInfo.data && userInfo.data?.email) {

            setFormData((preState) => ({ ...preState, email: userInfo.data?.email }))
        }
    }, [userInfo])

    const videoRef = useRef(null);
    const handleVideoEnded = () => {
        if (videoRef.current) {
            videoRef.current.currentTime = 0;
            videoRef.current.play();
        }
    };

    const handleVideoPaused = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener("ended", handleVideoEnded);
            videoRef.current.addEventListener("pause", handleVideoPaused);

        }

        if (videoRef.current) {
            videoRef.current.play();
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener("ended", handleVideoEnded);
            }
        };
    }, [videoRef]);

    const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth < 1024);
    };

    useEffect(() => {
        // Check the screen size on initial load
        checkScreenSize();

        // Listen for window resize events
        window.addEventListener('resize', checkScreenSize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const responsive23 = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1436 },
            items: 3.3
        },
        desktop: {
            breakpoint: { max: 1435, min: 1024 },
            items: 2.3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1.8
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };


    useEffect(() => {
        const callBack = async () => {
            try {
                const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=e1a29c31b72d4a4a9d0ce93be30400ee')
                if (response?.data?.country_name) {
                    if (response?.data?.country_name === "India")
                        setSelectedCurrency("INR");
                    else
                        setSelectedCurrency("USD");
                }
            } catch (err) {
                console.log(err);
            }
        }
        callBack();
    }, []);

    useEffect(() => {
        const callBack = async () => {
            setLiveEventsFaq([])
            setLiveEventsFaqOne([])
            setEventGameInfo()
            const response = await axiosApiInstance.get(BASE_URL + '/api/bookEvent/event-game/' + id);
            if (response && response.data.message === 'get fetch sucessfully') {
                setEventGameInfo(response.data.data)
            }

        }
        if (id && id !== '') {
            callBack()
            setMainGamesRows([])
            // getAllMainImages(id)




        }
    }, [id])

    useEffect(() => {
        const callBack = async () => {
            const response = await axiosApiInstance.get(BASE_URL + '/api/bookEvent/getAllCategories-games');
            if (response && response.data.message === 'sucessfully fetch category data') {
                setAllCatGames(response.data.data)
            }
        }
        callBack()
    }, [])

    const signOut = async () => {
        await dispatch(logOut());
        if (isAdmin) props.history.push("/admin");
        else props.history.push("/");
    };

    // const liveEventsFaq = [
    //     { title: "Can I use the games to do LIVE events on Zoom, MS Teams etc?", content: "A 100% yes! Playing ExtraMile Play's games for LIVE events on Zoom, MS Teams, or any virtual platform is extremely easy. We believe in creating engaging experiences that bring teams together no matter where they are, ensuring every event is a hit!" },
    //     { title: "Are there facilitators available to help run the LIVE events?   ", content: "Yes! Our expert facilitators ensure your LIVE events run smoothly, guiding your team through engaging activities that enhance teamwork. When confirming the event, we share profiles of our experts for you to choose from, providing the perfect fit to help run your event." },
    // ];
    useEffect(() => {
        if (eventGameInfo && eventGameInfo?.faqs && eventGameInfo?.faqs.length > 0) {
            setLiveEventsFaqOne([])
            const updatedFaqs = eventGameInfo.faqs.map((data) => ({
                title: data.title,
                content: data.description,
            }));

            setLiveEventsFaqOne((prevState) => [...prevState, ...updatedFaqs]);
        }

        if (eventGameInfo) {

            if (eventGameInfo?.typeOfEvent && eventGameInfo.typeOfEvent === "offline") {

                setLiveEventsFaq([
                    // {
                    //     title: 'How it works',
                    //     content: eventGameInfo?.howItWorks
                    // },
                    {
                        title: 'Agenda Of Show',
                        content: eventGameInfo?.agenda
                    },
                    // {
                    //     title: "Things You'll Need",
                    //     content: eventGameInfo?.thingsYouWillNeed
                    // }
                    // {
                    //     title: 'How To Join',
                    //     content: eventGameInfo?.platforms && eventGameInfo.platforms.length > 0
                    //         ? eventGameInfo.platforms.map(platform => platform.name).join(', ')
                    //         : ''
                    // }


                ]);
            }
            else {

                setLiveEventsFaq([
                    // {
                    //     title: 'How it works',
                    //     content: eventGameInfo?.howItWorks
                    // },
                    {
                        title: 'Agenda Of Show',
                        content: eventGameInfo?.agenda
                    },
                    // {
                    //     title: "Things You'll Need",
                    //     content: eventGameInfo?.thingsYouWillNeed
                    // }
                    {
                        title: 'How To Join',
                        content: eventGameInfo?.platforms && eventGameInfo.platforms.length > 0
                            ? eventGameInfo.platforms.map(platform => platform.name).join(', ')
                            : ''
                    }

                ]);
            }



            const data = [
                {
                    desktopBannerPath: eventGameInfo.imageOne
                },
                {
                    desktopBannerPath: eventGameInfo.imageTwo
                },
                {
                    desktopBannerPath: eventGameInfo.imageThree
                },
                {
                    desktopBannerPath: eventGameInfo.imageFour
                },
                {
                    desktopBannerPath: eventGameInfo.imageFive
                }

            ]

            setMainGamesRows(data);
        }

        if (eventGameInfo && eventGameInfo?.minPlayers) {
            setFormData((preState) => ({
                ...preState,
                membersCount: eventGameInfo.minPlayers
            }))
        }

        if (eventGameInfo && eventGameInfo.minPlayers && eventGameInfo.maxPlayers) {
            // Dynamically create options based on minPlayers and maxPlayers
            const options = Array.from({ length: eventGameInfo.maxPlayers - eventGameInfo.minPlayers + 1 }, (_, i) => {
                return {
                    value: eventGameInfo.minPlayers + i,
                    label: `${eventGameInfo.minPlayers + i} participants`
                };
            });
            // Add extra option for maxPlayers+
            // options.push({
            //     value: `${eventGameInfo.maxPlayers}+`,
            //     label: `${eventGameInfo.maxPlayers}+ participants`
            // });
            setParticipantsOptions(options);
        } else {
            setParticipantsOptions([{ value: '', label: 'No participants available' }]);
        }
    }, [eventGameInfo])


    useEffect(() => {
        if (formData && formData.membersCount && eventGameInfo && eventGameInfo?.typeOfEvent === "offline") {
            let value = Number(formData.membersCount) * Number(eventGameInfo.costOfEvent[selectedCurrency]);
            setEventTotalCost(value)
            

        }
        if (eventGameInfo && eventGameInfo?.typeOfEvent === "online") {
            // let value = formData.membersCount * Number(eventGameInfo.costOfEvent[selectedCurrency]);
            // setEventTotalCost(value)
            setEventTotalCost(eventGameInfo.costOfEvent[selectedCurrency])

        }

    }, [formData, eventGameInfo])

    useEffect(() => {
        if (formData && formData.membersCount && eventGameInfo && eventGameInfo?.typeOfEvent === "offline") {
            // let value = Number(formData.membersCount )* Number(eventGameInfo.costOfEvent[selectedCurrency]);
            // setEventTotalCost(value)
            console.log("formData.membersCount " + formData.membersCount);

        }


    }, [formData])

    // const getAllMainImages = async (gameid) => {
    //     try {
    //         const response = await axiosApiInstance.get(`${BASE_URL}/api/bookEvent/getAllMainImages/${gameid}`);
    //         const GamesImages = response.data.data;
    //         //   const updatedRows = GamesImages.map(item => ({
    //         //     images: [
    //         //       `${item.id}`,
    //         //       `${item.desktopBannerPath}`
    //         //     ],
    //         //     errors: ['', '']
    //         //   }));
    //         console.log("GamesImages:  " + JSON.stringify(GamesImages));

    //         setMainGamesRows(GamesImages);
    //     } catch (error) {
    //         console.error('Error fetching categories:', error);
    //     }
    // };

    function validateEmail(email) {
        const regex = /[A-Z]/;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase()) && !regex.test(email);
    }

    const LoginValidateInput = (input) => {

        const errors = {
            email: "",
            membersCount: ""
        };
        if (formDataTouch.email && (input.email === "" || input.email === null)) {
            errors.email = "Please enter your email";
        }
        else if (formDataTouch.email && (!validateEmail(input.email))) {

            errors.email = "Please provide a valid email";
        }

        // if (formDataTouch.membersCount && input.membersCount <= 0) {
        //     errors.membersCount = "Invalid members count"
        // }
        // if (formDataTouch.membersCount && input.membersCount < eventGameInfo?.minPlayers) {
        //     errors.membersCount = "minimum participants must be " + eventGameInfo?.minPlayers
        // }
        // if (formDataTouch.membersCount && input.membersCount > eventGameInfo?.minPlayers && input.membersCount > eventGameInfo?.maxPlayers) {
        //     errors.membersCount = "maximum participants must be " + eventGameInfo?.maxPlayers
        // }
        return errors;
    };

    let errorMessage = LoginValidateInput(formData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log("name: " + name);
        // console.log("value: " + value);
        if (formDataTouch[name]) {
            setFormDataTouch(prevState => ({ ...prevState, [name]: false }));
        }

        setFormData((preState) => ({
            ...preState,
            [name]: value
        }))


    }

    const formSubmit = async (e) => {
        e.preventDefault();
        // console.log("formData: " + JSON.stringify(formData));
        if (errorMessage && errorMessage.email) {
            return failureAlert("Invalid email")
        }
        if (errorMessage && errorMessage.membersCount) {
            return failureAlert(errorMessage.membersCount)
        }
        if (formData && formData?.email && formData?.membersCount && eventTotalCost && eventGameInfo) {
            const body = {
                data: {
                    email: formData.email.toLowerCase(),
                    guestCount: String(formData.membersCount),
                    eventGameId: eventGameInfo.id,
                    eventCost: Number(eventTotalCost)
                }
            }
            setLoaded(false)
            const response = await axiosApiInstance.post(BASE_URL + '/api/bookEvent/add-event/form', body);
            if (response && response.data.message === ' book event form added ') {
                history.push('/event-form/' + response.data.data.id + "/" + eventGameInfo.id)
            }
            setLoaded(true)

        }
        else {
            setFormDataTouch({
                email: true,
                membersCount: true
            })
        }


    }

    const handleAfterChange = (nextSlide) => {

        setElementIndex(nextSlide)
    };

    const [openAskQuestModal, setOpenAskQuestModal] = useState(false);
    const handleAskQuestModal = (e) => {
        e.preventDefault();

        setOpenAskQuestModal(true);
    }

    const handleCardDetail = (e, gameId, title) => {
        const name =  title.replace(/\s+/g, '-'); 
        history.push("/event-detail/" + gameId+"/"+name)

    }

    const TitleFormatter = ({ title }) => {
        const words = title.split(' ');

        return (
            <h1 className="mt-[10px] font-sans font-semibold md:font-firaSans lg:font-firaSans text-[20px] md:text-[32px] lg:text-[32px]">
                {words.map((word, index) => {
                    // Check if there are more than two words
                    if (words.length > 2) {
                        return (
                            <span key={index}
                                style={{ color: index < 2 ? 'black' : 'var(--background-theme)' }}
                                className="font-sans font-semibold text-[20px] md:text-[35px] lg:text-[40px]"
                            >
                                {word}{' '}
                            </span>
                        );
                    }
                    // For exactly two words
                    else if (words.length === 2) {
                        return (
                            <span
                                key={index}
                                style={{ color: index === 0 ? 'black' : 'var(--background-theme)' }}
                                className="font-sans font-semibold text-[20px] md:text-[35px] lg:text-[40px]"

                            >
                                {word}{' '}
                            </span>
                        );
                    }
                    return null; // In case of unexpected input
                })}
            </h1>
        );
    };


    const toggleReadMore = (cardId) => {
        // Toggle the expanded state for the clicked card
        setExpandedCards(prevState => ({
            ...prevState,
            [cardId]: !prevState[cardId], // Toggle expansion for this card
        }));
    };


    const handleImgClick = (e, index) => {
        e.preventDefault();
        setOpenImgSliderModal(true)
        setImgClickIndex(index)
    }

    const extractYouTubeId = (url) => {
        const match = url.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
        return match ? match[1] : null;
    };



    // Function to scroll to a specific div


    const opts = {
        height: '100%',
        width: '100%',
        // playerVars: {
        //   autoplay: 1,
        // },
    };

    const handleVideoStateChange = (event) => {
        const playerState = event.data;

        if (playerState === 1) {
            setIsPlaying(true);
        }
        else if (playerState === 2 || playerState === 0) {
            setIsPlaying(false);
        }
    };

    return (
        <>
        <Helmet>
            <title>{title ? title.replace(/-/g, ' ') : 'Book An Event'}</title>
            <meta name="description" content={title ? title.replace(/-/g, ' ') : 'Book An Event'} />
            <meta name="keywords" content={title ? title.replace(/-/g, ' ') : 'Book An Event'} />
        </Helmet>
            <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />
            <LoadingComponent loaded={loaded} />
            <ToastContainer position="bottom-center" />
            {
                openAskQuestModal ?
                    <AskQuestionModal
                        modalid="ask-Question"
                        toggle={openAskQuestModal}
                        setOpenContactModal={setOpenAskQuestModal}
                        eventID={eventGameInfo?.id}
                    //    setOpenThankyouModal={setOpenThankyouModal}
                    />
                    : null
            }
            {
                openImgSliderModal && (
                    <ImgSliderModal
                        modalid={'img-slider-modal'}
                        toggle={openImgSliderModal}
                        setOpenImgSliderModal={setOpenImgSliderModal}
                        onClose={() => setOpenImgSliderModal(false)}
                        images={mainGameRows}
                        startIndex={imgClickIndex}

                    />
                )
            }

            <div class="relative z-10 lg:flex flex-wrap hidden bg-white mt-[72px] h-[calc(100vh-72px)]"
                ref={topDiv}
            >

                <div class="z-10 flex justify-center items-center p-4 w-1/2 h-full">
                    {eventGameInfo && mainGameRows && mainGameRows.length > 0 ? (
                        extractYouTubeId(mainGameRows[0].desktopBannerPath) ? (
                            <div className="relative flex justify-center items-center w-full h-full"
                                onClick={(e) => handleImgClick(e, 0)}
                            >
                                <img
                                    src={`https://img.youtube.com/vi/${extractYouTubeId(mainGameRows[0].desktopBannerPath)}/maxresdefault.jpg`}
                                    alt="YouTube Thumbnail"
                                    className="rounded-md w-full h-full hover:cursor-pointer object-fill"

                                />
                                <MdOutlinePlayCircle className="absolute w-[150px] h-[150px] text-white hover:text-rose-600 cursor-pointer" />
                            </div>
                        ) : mainGameRows[0]?.desktopBannerPath.match(/\.(mp4|webm|ogg)$/i) ? (
                            // Check if the path is a video URL
                            <video fetchpriority="high" autoPlay muted disablePictureInPicture id="video_banner" ref={videoRef} style={{ height: '100% !important' }} onClick={(e) => handleImgClick(e, 0)}>
                                <source
                                    src={S3_BASE_URL + mainGameRows[0].desktopBannerPath}
                                    type="video/mp4"
                                />

                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            // Fallback to image if it's not a video or YouTube URL
                            <img
                                src={S3_BASE_URL + mainGameRows[0].desktopBannerPath}
                                alt="Left Section Image"
                                className="rounded-md w-full h-full hover:cursor-pointer object-fill"
                                onClick={(e) => handleImgClick(e, 0)}
                            />
                        )
                    ) : (
                        // Fallback image when no mainGameRows data is available
                        // <img src={bg} alt="Left Section Image" className="rounded-md w-full h-full hover:cursor-pointer object-fill" />
                        <div className="flex bg-slate-100 rounded-[6px] w-full h-full skeleton-animation"></div>
                    )}

                </div>


                <div class="z-10 gap-4 grid grid-cols-2 grid-rows-2 p-4 pl-0 w-1/2 h-full">
                    {/* {


                        id && eventGameInfo && eventGameInfo?.images.length > 0 ?
                            eventGameInfo?.images.map((img, index) => {
                                return (
                                    <img src={S3_BASE_URL + img.desktopBannerPath} alt="Image 1" class="rounded-md w-full h-full object-cover" onClick={(e) => handleImgClick(e, index)} />

                                )
                            })
                            : null
                    } */}
                    {/* <img src={mainGameRows && mainGameRows[1].desktopBannerPath ? S3_BASE_URL + `${mainGameRows[1].desktopBannerPath}` : bg} alt="Image 1" class="rounded-md w-full h-full object-cover" />
                    <img src={mainGameRows && mainGameRows[2].desktopBannerPath ? S3_BASE_URL + `${mainGameRows[2].desktopBannerPath}` : bg} alt="Image 1" class="rounded-md w-full h-full object-cover" />
                    <img src={mainGameRows && mainGameRows[3].desktopBannerPath ? S3_BASE_URL + `${mainGameRows[3].desktopBannerPath}` : bg} alt="Image 1" class="rounded-md w-full h-full object-cover" />
                    <img src={mainGameRows && mainGameRows[4].desktopBannerPath ? S3_BASE_URL + `${mainGameRows[4].desktopBannerPath}` : bg} alt="Image 1" class="rounded-md w-full h-full object-cover" /> */}

                    {eventGameInfo && mainGameRows && mainGameRows.length > 0 ? (
                        <>
                            {
                                // mainGameRows[1]?.desktopBannerPath?.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/) ? (
                                //     <iframe
                                //         width="100%"
                                //         height="100%"
                                //         src={`https://www.youtube.com/embed/${mainGameRows[1].desktopBannerPath.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)[1]}`}
                                //         frameBorder="0"
                                //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                //         allowFullScreen
                                //         className="rounded-md w-full h-full object-fill"
                                //         onClick={(e) => handleImgClick(e, 1)}
                                //     ></iframe>

                                extractYouTubeId(mainGameRows[1].desktopBannerPath) ? (
                                    <div className="relative flex justify-center items-center w-full h-full"
                                        onClick={(e) => handleImgClick(e, 1)}
                                    >
                                        <img
                                            src={`https://img.youtube.com/vi/${extractYouTubeId(mainGameRows[1].desktopBannerPath)}/maxresdefault.jpg`}
                                            alt="YouTube Thumbnail"
                                            className="rounded-md w-full h-full hover:cursor-pointer object-fill"

                                        />
                                        <MdOutlinePlayCircle className="absolute w-[80px] h-[80px] text-white hover:text-rose-600 cursor-pointer" />
                                    </div>

                                ) : (
                                    <img
                                        src={mainGameRows[1]?.desktopBannerPath ? S3_BASE_URL + mainGameRows[1].desktopBannerPath : bg}
                                        alt="Image 1"
                                        className="rounded-md w-full h-full hover:cursor-pointer object-fill"
                                        onClick={(e) => handleImgClick(e, 1)}

                                    />
                                )}

                            {
                                // mainGameRows[2]?.desktopBannerPath?.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/) ? (
                                //     <iframe
                                //         width="100%"
                                //         height="100%"
                                //         src={`https://www.youtube.com/embed/${mainGameRows[2].desktopBannerPath.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)[1]}`}
                                //         frameBorder="0"
                                //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                //         allowFullScreen
                                //         className="rounded-md w-full h-full object-fill"
                                //         onClick={(e) => handleImgClick(e, 2)}

                                //     ></iframe>

                                extractYouTubeId(mainGameRows[2].desktopBannerPath) ? (
                                    <div className="relative flex justify-center items-center w-full h-full"
                                        onClick={(e) => handleImgClick(e, 2)}
                                    >
                                        <img
                                            src={`https://img.youtube.com/vi/${extractYouTubeId(mainGameRows[2].desktopBannerPath)}/maxresdefault.jpg`}
                                            alt="YouTube Thumbnail"
                                            className="rounded-md w-full h-full hover:cursor-pointer object-fill"

                                        />
                                        <MdOutlinePlayCircle className="absolute w-[80px] h-[80px] text-white hover:text-rose-600 cursor-pointer" />
                                    </div>
                                ) : (
                                    <img
                                        src={mainGameRows[2]?.desktopBannerPath ? S3_BASE_URL + mainGameRows[2].desktopBannerPath : bg}
                                        alt="Image 2"
                                        className="rounded-md w-full h-full hover:cursor-pointer object-fill"
                                        onClick={(e) => handleImgClick(e, 2)}

                                    />
                                )}

                            {
                                // mainGameRows[3]?.desktopBannerPath?.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/) ? (
                                //     <iframe
                                //         width="100%"
                                //         height="100%"
                                //         src={`https://www.youtube.com/embed/${mainGameRows[3].desktopBannerPath.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)[1]}`}
                                //         frameBorder="0"
                                //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                //         allowFullScreen
                                //         className="rounded-md w-full h-full object-cover"
                                //         onClick={(e) => handleImgClick(e, 3)}

                                //     ></iframe>

                                extractYouTubeId(mainGameRows[3].desktopBannerPath) ? (
                                    <div className="relative flex justify-center items-center w-full h-full"
                                        onClick={(e) => handleImgClick(e, 3)}
                                    >
                                        <img
                                            src={`https://img.youtube.com/vi/${extractYouTubeId(mainGameRows[3].desktopBannerPath)}/maxresdefault.jpg`}
                                            alt="YouTube Thumbnail"
                                            className="rounded-md w-full h-full hover:cursor-pointer object-fill"


                                        />
                                        <MdOutlinePlayCircle className="absolute w-[80px] h-[80px] text-white hover:text-rose-600 cursor-pointer" />
                                    </div>
                                ) : (
                                    <img
                                        src={mainGameRows[3]?.desktopBannerPath ? S3_BASE_URL + mainGameRows[3].desktopBannerPath : bg}
                                        alt="Image 3"
                                        className="rounded-md w-full h-full hover:cursor-pointer object-cover"
                                        onClick={(e) => handleImgClick(e, 3)}

                                    />
                                )}

                            {
                                // mainGameRows[4]?.desktopBannerPath?.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/) ? (
                                //     <iframe
                                //         width="100%"
                                //         height="100%"
                                //         src={`https://www.youtube.com/embed/${mainGameRows[4].desktopBannerPath.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/)[1]}`}
                                //         frameBorder="0"
                                //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                //         allowFullScreen
                                //         className="rounded-md w-full h-full object-cover"
                                //         onClick={(e)=> handleImgClick(e, 4)}

                                //     ></iframe>
                                extractYouTubeId(mainGameRows[4].desktopBannerPath) ? (
                                    <div className="relative flex justify-center items-center w-full h-full"
                                        onClick={(e) => handleImgClick(e, 4)}
                                    >
                                        <img
                                            src={`https://img.youtube.com/vi/${extractYouTubeId(mainGameRows[4].desktopBannerPath)}/maxresdefault.jpg`}
                                            alt="YouTube Thumbnail"
                                            className="rounded-md w-full h-full hover:cursor-pointer object-fill"


                                        />
                                        <MdOutlinePlayCircle className="absolute w-[80px] h-[80px] text-white hover:text-rose-600 cursor-pointer" />
                                    </div>
                                ) : (
                                    <img
                                        src={mainGameRows[4]?.desktopBannerPath ? S3_BASE_URL + mainGameRows[4].desktopBannerPath : bg}
                                        alt="Image 4"
                                        className="rounded-md w-full h-full hover:cursor-pointer object-cover"
                                        onClick={(e) => handleImgClick(e, 4)}

                                    />
                                )}
                        </>
                    ) : (
                        <>
                            <div className="flex bg-slate-100 rounded-[6px] w-full h-full skeleton-animation"></div>
                            <div className="flex bg-slate-100 rounded-[6px] w-full h-full skeleton-animation"></div>
                            <div className="flex bg-slate-100 rounded-[6px] w-full h-full skeleton-animation"></div>
                            <div className="flex bg-slate-100 rounded-[6px] w-full h-full skeleton-animation"></div>
                        </>
                    )
                    }


                </div>
            </div>

            <div className="relative z-10 flex flex-wrap lg:hidden bg-white mt-[80px] px-4 h-[100%]"
                ref={mobiletopDiv}
            >

                {
                    eventGameInfo && mainGameRows && mainGameRows.length > 0 ? (
                        <Carousel
                            autoPlay={!isPlaying} // Enable auto-scrolling
                            autoPlaySpeed={3000} // Duration for auto-scroll
                            // arrows={true} // Display left and right scroll buttons
                            additionalTransfrom={0}
                            centerMode={false}
                            className="relative"
                            containerClass="container-width-full"
                            draggable
                            infinite
                            itemClass="carousel-item"
                            keyBoardControl
                            minimumTouchDrag={80}
                            afterChange={handleAfterChange} // Add the afterChange handler
                            pauseOnHover
                            responsive={{
                                mobile: {
                                    breakpoint: { max: 1024, min: 0 },
                                    items: 1,
                                    partialVisibilityGutter: 30,
                                },
                            }}
                            slidesToSlide={1}
                            swipeable
                        >
                            {mainGameRows.map((img, index) => {
                                return (
                                    <div key={index} className="flex flex-col justify-center items-center w-full h-[330px] sm:h-[550px] md:h-[650px]">
                                        {
                                            extractYouTubeId(img.desktopBannerPath) ? (
                                                <div className="relative flex w-full h-full"
                                                // onClick={(e) => handleImgClick(e, index)} // Use index for clicking
                                                >
                                                    <YouTube
                                                        videoId={extractYouTubeId(img.desktopBannerPath)} // Example video ID, replace with your own
                                                        opts={opts}
                                                        onStateChange={handleVideoStateChange} // Attach state change handler
                                                        className="rounded-md w-full h-full object-cover" // Add your custom class here

                                                    />

                                                </div>
                                            ) : img.desktopBannerPath ? (
                                                <img
                                                    src={img.desktopBannerPath ? `${S3_BASE_URL}${img.desktopBannerPath}` : bg}
                                                    alt={`Image ${index}`}
                                                    className="rounded-md w-full h-full hover:cursor-pointer object-fill"
                                                // onClick={(e) => handleImgClick(e, index)} // Use index for clicking
                                                />
                                            ) : (
                                                <div className="flex bg-slate-100 w-full h-full skeleton-animation"></div>
                                            )
                                        }
                                    </div>
                                )
                            })}
                        </Carousel>
                    ) : (
                        <div className="flex flex-col justify-center items-center bg-slate-100 w-full h-[330px] sm:h-[550px] md:h-[650px] skeleton-animation">
                        </div>
                    )
                }
            </div >


            <div class="relative flex bg-white-200 mt-10 sm:mt-6 md:mt-6 lg:mt-12 px-4 md:px-7 lg:px-8 lg:pr-[70px] lg:pl-[70px]"
                ref={div2Ref}
            >


                <div class="right-[-60px] z-10 lg:flex justify-start hidden w-1/2"
                    ref={formRef}
                    style={{
                        position: isSticky ? 'fixed' : stickToBottom || stickToTop ? 'absolute' : 'absolute',
                        top: isSticky ? '130px' : stickToBottom ? 'auto' : stickToTop ? 0 : 0,
                        bottom: stickToBottom ? '50px' : !isSticky ? 'auto' : '50px',
                        height: 'fit-content',
                        transition: 'top 0.3s ease, bottom 0.3s ease',

                    }}
                >
                    <form class="bg-white shadowBookEvent p-8" style={{ width: '75%' }}>
                        <h2 class="mb-5 font-21">Starting from <span class="font-bold textRed"> {selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'} { eventTotalCost ? eventTotalCost : 0.0}</span></h2>
                        <label class="block mb-5">
                            <span className="font-21">Approximately how many participants?</span>
                            {/* <span className="font-21">Select No. Of Participants <span className="font-21">{`(min-${eventGameInfo && eventGameInfo?.minPlayers ? eventGameInfo?.minPlayers : 0} max-${eventGameInfo && eventGameInfo?.minPlayers ? eventGameInfo?.maxPlayers : 0} )`}</span></span> */}
                            {/* <input type="number" min={1} className="bg-white shadow-sm mt-1 p-2 border focus:border-blue-500 rounded focus:ring w-full h-[40px] focus:outline-none inputFont" placeholder="Enter no of participants"
                                onChange={handleChange}
                                onBlur={() => setFormDataTouch(prevState => ({ ...prevState, membersCount: true }))}
                                value={formData.membersCount}
                                name="membersCount"
                            /> */}
                            {/* <select name="" id="" class="block bg-white shadow-sm mt-1 p-2 focus:border-blue-500 rounded focus:ring w-full inputFont">
                                <option value="">14 participants</option>
                                <option value="">20 participants</option>
                                <option value="">30 participants</option>
                            </select> */}
                            <select
                                name="membersCount"
                                id="participants"
                                className="block bg-white shadow-sm mt-1 p-2 focus:border-blue-500 rounded focus:ring w-full inputFont"
                                style={{ maxHeight: '150px', overflowY: 'auto' }}
                                onBlur={() => setFormDataTouch(prevState => ({ ...prevState, membersCount: true }))}
                                value={formData.membersCount}
                                onChange={handleChange}
                            >
                                {participantsOptions.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>



                            {
                                errorMessage && errorMessage.membersCount ? (
                                    <span className="font-sans text-[8px] text-red-500">{errorMessage.membersCount}</span>
                                )
                                    : null
                            }
                        </label>

                        <label class="block mb-5">
                            <span className="font-21">Enter Email Address</span>
                            <input type="text" className="block mt-1 p-2 border focus:border-blue-500 rounded focus:ring w-full h-[40px] focus:outline-none inputFont" placeholder="Enter your work email address"
                                onChange={handleChange}
                                onBlur={() => setFormDataTouch(prevState => ({ ...prevState, email: true }))}
                                value={formData.email}
                                disabled={userInfo && userInfo.data && userInfo.data?.email ? true : false}
                                name="email"
                            />
                            {
                                errorMessage && errorMessage.email ? (
                                    <span className="font-sans text-[8px] text-red-500">{errorMessage.email}</span>
                                )
                                    : null
                            }
                        </label>

                        <button type="submit" class="bg-themeColor mb-5 p-2 rounded-[6px] w-full text-white"
                            onClick={formSubmit}
                        >Make A Reservation</button>
                        <label class="block mb-5">
                            <span className="font-21">Have A Question?</span>
                            <button type="submit" class="border-[1px] hover:bg-themeColor p-2 border-themeColor rounded-[6px] w-full font-21 !text-themeColor hover:!text-white" onClick={handleAskQuestModal}>Ask a Question</button>
                        </label>

                        <div class="mt-5 mb-5 p-4 rounded bgPink">
                            <h3 class="mb-1 font-21">Event Details</h3>
                            <p className="text-grey" style={{ fontSize: '14px' }}>Please ensure all details are correct before submitting the form. Feel free to ask any questions!</p>
                        </div>
                    </form>
                </div>


                <div class="relative z-0 flex flex-col justify-start space-y-6 p-0 w-full lg:w-1/2">

                    <div className="top-[370px] left-[-172px] z-0 absolute md:flex lg:flex hidden w-[370px] h-[360px]"
                        style={{
                            background: 'radial-gradient(circle, rgb(282, 194, 217) -43%, rgb(279, 232, 235) 19%, rgb(255, 255, 255) 69%)'
                        }}
                    ></div>

                    <div className="top-[700px] left-[-165px] z-0 absolute md:flex lg:flex hidden w-[500px] h-[500px]"
                        style={{
                            background: 'radial-gradient(circle, rgb(227, 221, 250, 0.8) -11%, rgb(243, 249, 255, 0.5) 55%, rgb(255, 255, 255, 0) 69%)'
                        }}
                    ></div>

                    <div className="top-[-134px] right-[-125%] absolute md:flex lg:flex hidden w-[500px] h-[550px]"
                        style={{
                            background: 'radial-gradient(circle, rgb(245, 232, 227, 0.8) -29%, rgb(253, 248, 235, 0.5) 28%, rgb(255, 255, 255, 0) 72%)'
                        }}
                    ></div>
                    {
                        liveEventsFaq && liveEventsFaq.length > 0 ?
                            (
                                <div className="right-[-137%] bottom-[0%] absolute md:flex lg:flex hidden w-[500px] h-[550px]"
                                    style={{
                                        background: 'radial-gradient(circle, rgb(245, 232, 227, 0.8) -29%, rgb(253, 248, 235, 0.5) 28%, rgb(255, 255, 255, 0) 72%)'
                                    }}
                                ></div>
                            )
                            : null
                    }

                    {/* <h1 class="mb-1 font-40 font-bold"> About <span class="font-bold textRed">The Game</span></h1> */}
                    <TitleFormatter title={eventGameInfo ? eventGameInfo.title : ''} />
                    {/* <h1 class="mb-3 font-bold">About The Game</h1> */}

                    <div class="z-10 lg:flex flex-wrap justify-between gap-5 hidden mt-[20px]">
                        <div class="relative flex justify-center items-center bg-white mb-[15px] p-4 rounded-lg w-[30%] pinkCard">
                            <img src={iconparticpants} alt="Card 1 Image" class="top-[-18px] right-[10px] absolute w-[38px] h-[38px] aboutCardImage object-cover" />
                            <span class="font-21 text-center">{`Upto ${eventGameInfo?.headCount} Participants`}</span>
                        </div>
                        <div class="relative flex justify-center items-center bg-white mb-[15px] p-4 rounded-lg w-[30%] pinkCard">
                            <img src={iconTime} alt="Card 1 Image" class="top-[-18px] right-[10px] absolute w-[38px] h-[38px] aboutCardImage object-cover" />
                            <span class="font-21 text-center">{`Time- ${eventGameInfo?.duration} Mins`}</span>
                        </div>
                        <div class="relative flex justify-center items-center bg-white mb-[15px] p-4 rounded-lg w-[30%] pinkCard">
                            <img src={team} alt="Card 1 Image" class="top-[-20px] right-[10px] absolute w-[40px] h-[40px] aboutCardImage object-cover" />
                            <span class="font-21 text-center">{`${eventGameInfo && eventGameInfo?.typeOfEvent === "online" ? 'Virtual' : eventGameInfo && eventGameInfo?.typeOfEvent === "offline" ? 'Onsite' : eventGameInfo?.typeOfEvent}`}</span>
                        </div>

                        {/* <div class="relative flex justify-center items-center bg-white mb-[15px] p-4 rounded-lg w-1/2 sm:w-1/4 lg:w-[240px] pinkCard" >
                            <img src={iconcompetition} alt="Card 1 Image" class="top-[-18px] right-[10px] absolute aboutCardImage object-cover" />
                            <span class="font-21 text-center">Competition Level:Neutral</span>
                        </div>
                        <div class="relative flex justify-center items-center bg-white p-4 rounded-lg w-1/2 sm:w-1/4 lg:w-1/2 pinkCard">
                            <img src={team} alt="Card 1 Image" class="top-[-18px] right-[10px] absolute aboutCardImage object-cover" />
                            <span class="font-21 text-center">{eventGameInfo?.typeOfEvent}</span>
                        </div> */}
                    </div>


                    {/* <div class="z-10 flex justify-center items-center lg:hidden w-full h-[201px] sm:h-[300px] md:h-[337px] lg:h-[337px] overflow-hidden">
                        <img src={eventGameInfo && eventGameInfo?.gameBanner ? `${S3_BASE_URL}${eventGameInfo?.gameBanner}` : bg} alt="Fullwidth Image" class="rounded-lg w-full h-full object-fill" />
                    </div> */}
                    <div class="z-10 flex flex-row flex-wrap justify-between lg:gap-5 lg:hidden mt-10 lg:mt-0">
                        <div class="relative flex justify-center items-center bg-white mb-[24px] md:mb-[15px] lg:mb-[15px] p-4 rounded-lg w-[100%] sm:w-[32%] md:w-[32%] lg:w-1/2 h-[50px] sm:h-[61px] md:h-[61px] lg:h-[81px] pinkCard">
                            <img src={iconparticpants} alt="Card 1 Image" class="top-[-15px] md:top-[-10px] lg:top-[-18px] right-[10px] absolute w-[28px] md:w-[35px] lg:w-[47px] h-[28px] md:h-[35px] lg:h-[38px] aboutCardImage object-cover" />
                            <span class="font-21 text-center">{`Upto ${eventGameInfo?.headCount} Participants`}</span>
                        </div>
                        <div class="relative flex justify-center items-center bg-white mb-[24px] md:mb-[15px] lg:mb-[15px] p-4 rounded-lg w-[100%] sm:w-[32%] md:w-[32%] lg:w-1/2 h-[50px] sm:h-[61px] md:h-[61px] lg:h-[81px] pinkCard">
                            <img src={iconTime} alt="Card 1 Image" class="top-[-12px] md:top-[-10px] lg:top-[-18px] right-[10px] absolute w-[27px] md:w-[35px] lg:w-[47px] h-[27px] md:h-[35px] lg:h-[38px] aboutCardImage object-cover" />
                            <span class="font-21 text-center">{`Time- ${eventGameInfo?.duration} Mins`}</span>
                        </div>
                        <div class="relative flex justify-center items-center bg-white mb-[15px] p-4 rounded-lg w-[100%] sm:w-[32%] md:w-[32%] lg:w-1/2 h-[50px] sm:h-[61px] md:h-[61px] lg:h-[81px] pinkCard">
                            <img src={team} alt="Card 1 Image" class="top-[-15px] md:top-[-10px] lg:top-[-18px] right-[5px] md:right-[10px] absolute w-[34px] md:w-[35px] lg:w-[47px] h-[34px] md:h-[35px] lg:h-[38px] aboutCardImage object-cover" />
                            <span class="font-21 text-center">{`${eventGameInfo && eventGameInfo?.typeOfEvent === "online" ? 'Virtual' : eventGameInfo && eventGameInfo?.typeOfEvent === "offline" ? 'Onsite' : eventGameInfo?.typeOfEvent}`}</span>
                        </div>


                        {/* <div class="relative flex justify-center items-center bg-white mb-[15px] p-4 rounded-lg w-[100%] sm:w-[43%] md:w-[43%] lg:w-1/2 pinkCard">
                            <img src={team} alt="Card 1 Image" class="top-[-18px] right-[10px] absolute aboutCardImage object-cover" />
                            <span class="font-21 text-center">{eventGameInfo?.typeOfEvent}</span>
                        </div>
                        <div class="relative flex justify-center items-center bg-white p-4 rounded-lg w-[100%] lg:w-[240px] pinkCard" >
                            <img src={iconcompetition} alt="Card 1 Image" class="top-[-18px] right-[10px] absolute aboutCardImage object-cover" />
                            <span class="font-21 text-center">Competition Level:Neutral</span>
                        </div> */}
                    </div>

                    <div class="z-10 space-y-4">
                        <p class="text-lg paraColorAndFont">{eventGameInfo?.description}</p>
                        <p class="text-lg paraColorAndFont">{eventGameInfo?.feature}</p>
                    </div>

                    <div class="z-10 flex flex-wrap lg:flex-nowrap justify-between lg:space-x-4">
                        {/* <button class="flex items-center lg:space-x-2 sm:mb-0 md:mb-0 lg:mb-0 py-2 rounded text-black">
                            <img src={iconinstantbook} alt="Logo 1" class="w-5 h-5" />
                            <span class="font-21 underline">{eventGameInfo?.bookingType}</span>
                        </button>
                        <button class="flex items-center lg:space-x-2 sm:mb-0 md:mb-0 lg:mb-0 py-2 rounded text-black">
                            <img src={icongaurantee} alt="Logo 1" class="w-5 h-5" />
                            <span class="font-21 underline">Extramile Play Guarantee</span>
                        </button> */}
                        <button class="flex items-center lg:space-x-2 sm:mb-0 md:mb-0 lg:mb-0 py-2 rounded text-black">
                            <img src={iconheadcount} alt="Logo 1" class="w-5 h-5" />
                            <span class="font-21 underline">Adjustable Headcount</span>
                        </button>
                    </div>

                    <div className="z-10 flex flex-col lg:hidden mt-[75px] w-full">
                        <form class="bg-white shadowBookEvent p-6 md:p-8 lg:p-8 w-full">
                            <h2 class="mb-5 font-21">Starting from <span class="font-bold textRed">{selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'} {eventTotalCost ? eventTotalCost : 0.0}</span></h2>
                            <label class="block mb-5">
                                <span className="font-21">Approximately how many participants?</span>

                                <select
                                    name="membersCount"
                                    id="participants"
                                    className="block bg-white shadow-sm mt-1 p-2 focus:border-blue-500 rounded focus:ring w-full !h-[40px] inputFont"
                                    style={{ maxHeight: '150px', overflowY: 'auto' }}
                                    onBlur={() => setFormDataTouch(prevState => ({ ...prevState, membersCount: true }))}
                                    value={formData.membersCount}
                                    onChange={handleChange}
                                >
                                    {participantsOptions.map((option, i) => (
                                        <option key={i} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>

                                {
                                    errorMessage && errorMessage.membersCount ? (
                                        <span className="font-sans text-[10px] text-red-500">{errorMessage.membersCount}</span>
                                    )
                                        : null
                                }
                            </label>

                            <label class="block mb-5">
                                <span className="font-21">Enter Email Address</span>
                                <input type="text" className="block mt-1 p-2 border focus:border-blue-500 rounded focus:ring w-full !h-[40px] focus:outline-none inputFont" placeholder="Enter your work email address"
                                    onChange={handleChange}
                                    onBlur={() => setFormDataTouch(prevState => ({ ...prevState, email: true }))}
                                    value={formData.email}
                                    disabled={userInfo && userInfo.data && userInfo.data?.email ? true : false}
                                    name="email"
                                />
                                {
                                    errorMessage && errorMessage.email ? (
                                        <span className="font-sans text-[8px] text-red-500">{errorMessage.email}</span>
                                    )
                                        : null
                                }
                            </label>

                            <button type="submit" class="bg-themeColor mb-5 p-2 rounded-[6px] w-full text-white"
                                onClick={formSubmit}
                            >Make A Reservation</button>
                            <label class="block mb-5">
                                <span className="font-21">Have A Question?</span>
                                <button type="submit" class="border-[1px] hover:bg-themeColor p-2 border-themeColor rounded-[6px] w-full font-21 !text-themeColor text-white hover:!text-white" onClick={handleAskQuestModal}>Ask a Question</button>
                            </label>

                            <div class="mt-5 mb-5 p-4 rounded bgPink">
                                <h3 class="mb-1 font-21">Event Details</h3>
                                <p className="text-grey" style={{ fontSize: '14px' }}>Please ensure all details are correct before submitting the form. Feel free to ask any questions!</p>
                            </div>
                        </form>
                    </div>

                    {
                        liveEventsFaq && liveEventsFaq.length > 0 ? (
                            <div class="z-10 flex justify-between space-x-4 mt-12 mb-12" style={{
                                marginTop: '50px',
                                marginBottom: '50px'
                            }}>
                                <div class="relative flex justify-center items-center w-full">
                                    <Accordion items={liveEventsFaq} page="bookEvent" />

                                </div>
                            </div>
                        )
                            : null
                    }

                    {/* <div class="flex justify-between space-x-4" style={{

                        marginBottom: '25%'
                    }}>
                        <div className="ratingsection">
                            <span className="mb-3 heading">Reviews</span>
                            <span className="mb-3 checked fa fa-star" style={{ fontSize: '25px' }}>4.9  </span><span className="text-grey">(156)</span>

                            <div className="row">
                                <div className="side"> 1 <span className="checked fa fa-star"></span></div>
                                <div className="middle">
                                    <div className="bar-container">
                                        <div className="bar-1"></div>
                                    </div>
                                </div>
                                <div className="right">20 %</div>
                            </div>
                            <div className="row">
                                <div className="side"> 1 <span className="checked fa fa-star"></span></div>
                                <div className="middle">
                                    <div className="bar-container">
                                        <div className="bar-1"></div>
                                    </div>
                                </div>
                                <div className="right">20 %</div>
                            </div>
                            <div className="row">
                                <div className="side"> 1 <span className="checked fa fa-star"></span></div>
                                <div className="middle">
                                    <div className="bar-container">
                                        <div className="bar-1"></div>
                                    </div>
                                </div>
                                <div className="right">20 %</div>
                            </div>
                            <div className="row">
                                <div className="side"> 1 <span className="checked fa fa-star"></span></div>
                                <div className="middle">
                                    <div className="bar-container">
                                        <div className="bar-1"></div>
                                    </div>
                                </div>
                                <div className="right">20 %</div>
                            </div>

                            <div className="row">
                                <div className="side"> 1 <span className="checked fa fa-star"></span></div>
                                <div className="middle">
                                    <div className="bar-container">
                                        <div className="bar-1"></div>
                                    </div>
                                </div>
                                <div className="right">20 %</div>
                            </div>
                        </div>


                    </div> */}

                    {
                        eventGameInfo && eventGameInfo?.testimonials > 0 ? (
                            <div class="z-10 flex justify-between space-x-4 mt-0 mb-[50px]">
                                <div className="rounded-[6px] w-full h-auto">

                                    <h1 class="mb-[40px] font-bold text-[21px] md:text-[32px] lg:text-[32px]"> Hear From  <span class="font-bold textRed">Our Clients</span></h1>

                                    {
                                        eventGameInfo && eventGameInfo?.testimonials.length > 0 ?
                                            eventGameInfo?.testimonials.map((data, indx) => {
                                                return (
                                                    <div className="mb-8 user-feedback" key={indx}>
                                                        <div className="relative mb-[10px]">
                                                            {
                                                                data && data?.testimonialImagePath ? (
                                                                    <img src={data?.testimonialImagePath ? `${S3_BASE_URL}${data?.testimonialImagePath}` : bg} alt="User 1" className="user-image" />

                                                                )
                                                                    : (
                                                                        <div className="!bg-slate-100 skeleton-animation user-image"></div>
                                                                    )
                                                            }
                                                            <div className="moon-border"></div>
                                                        </div>
                                                        <h3 className="font-semibold text-[#23282E] text-[18px] user-name">{data?.name}</h3>
                                                        <h4 className="opacity-[0.5] text-[#23282E] text-[16px] user-designation">{data?.designation}</h4>
                                                        <div className="inline-block relative">
                                                            <img
                                                                src={quotes}
                                                                alt="quotes"
                                                                className="top-0 left-0 absolute w-8 h-8"
                                                            />
                                                            <p className="relative z-10 pl-[13px] text-[#23282E] text-[16px] user-quote">
                                                                {data?.comment}
                                                            </p>
                                                        </div>
                                                        <hr className="divider" />
                                                    </div>
                                                )
                                            })
                                            : null
                                    }


                                </div>

                            </div>
                        )
                            : null
                    }

                    {
                        liveEventsFaqOne && liveEventsFaqOne.length > 0 ? (
                            <div class="z-10 space-x-4 mb-12" style={{ marginBottom: '50px', }}>
                                <h1 class="mb-3 font-bold text-[21px] md:text-[32px] lg:text-[32px]"> Frequently Asked <span class="font-bold textRed">Questions</span></h1>
                                <Accordion items={liveEventsFaqOne} page="bookEvent" />
                            </div>
                        )
                            : null
                    }


                    {/* <div class="z-10 md:flex lg:flex justify-between space-x-4 hidden mt-12 mb-12" style={{ marginBottom: '25%' }}>
                        <div className="p-0 w-full why-extramile-section">
                            <h1 class="mb-3 font-bold text-[21px] md:text-[35px] lg:text-[40px]"> Why  <span class="font-bold textRed">Extramile?</span></h1>

                            <div className="user-feedback">
                                <img src={iconinnovative} alt="User 1" className="user-image" />
                                <div className="user-info">
                                    <h3 className="user-name">John Doe</h3>
                                    <p className="user-quote">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                                </div>
                            </div>

                            <div className="user-feedback">
                                <img src={iconaccessible} alt="User 2" className="user-image" />
                                <div className="user-info">
                                    <h3 className="user-name">Jane Smith</h3>
                                    <p className="user-quote">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                                </div>
                            </div>

                            <div className="user-feedback">
                                <img src={iconpricing} alt="User 3" className="user-image" />
                                <div className="user-info">
                                    <h3 className="user-name">Alice Johnson</h3>
                                    <p className="user-quote">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."</p>
                                </div>
                            </div>
                        </div>

                    </div> */}
                </div>


            </div>

            <div className="relative z-10 bg-transparent lg:pl-[35px] w-full h-auto">
                {
                    allCatGames && allCatGames.length > 0 ?
                        (
                            <h1 class="mb-[25px] ml-0 md:ml-4 lg:ml-8 w-full font-bold text-[21px] md:text-[32px] lg:text-[32px]">People also booked</h1>
                        )
                        : null
                }

                {
                    allCatGames && allCatGames.length > 0 ?
                        allCatGames.map((data, index) => {
                            return (
                                data?.events && data.events.length > 0 && (
                                    <div className="relative z-10 bg-transparent pr-2 md:pr-6 lg:pr-8 pl-3">
                                        <div class="flex flex-col justify-start bg-transparent pb-6 w-full">


                                            {/* <h1 class="mb-0 ml-8 font-40 font-bold"> Explore other  <span class="font-bold textRed">Events</span></h1> */}
                                            <h1 class="mb-0 ml-0 md:ml-4 lg:ml-8 font-bold text-[21px] md:text-[32px] lg:text-[32px]">{data?.title}</h1>

                                            <div className="flex justify-center mt-4">
                                                <div className="flex flex-wrap justify-center gap-4 w-full max-w-[100%] bookeventcardcontainer">
                                                    <Carousel responsive={responsive23} className="!w-full">
                                                        {/* Repeat this card structure as needed */}
                                                        {
                                                            data && data?.events && data?.events.length > 0 ?
                                                                data.events.map((game, indx) => {
                                                                    return (
                                                                        game && game?.id && game.id !== id &&
                                                                        <div className="relative z-10 flex flex-col items-center bg-white shadowBookEvent rounded-lg w-[calc(100%-15px)] sm:w-[320px] md:w-[25rem] lg:w-[400px] cursor-pointer overflow-hidden bookEventInfoCard"
                                                                            onClick={(e) => handleCardDetail(e, game.id, game.title)}
                                                                        >
                                                                            {
                                                                                game && game?.gameBanner ? (
                                                                                    <div className="z-10 flex justify-center items-center bg-white !w-full h-[160px] md:h-[200px] lg:h-[200px] overflow-hidden">
                                                                                        <img src={game && game?.gameBanner ? S3_BASE_URL + game?.gameBanner : curiositycorner} alt="Card 1 Image" className="z-0 mb-0 rounded-md !w-full h-[160px] md:h-[200px] lg:h-[200px] bookEventInfoCardImg object-fill" />

                                                                                    </div>
                                                                                )
                                                                                    :
                                                                                    (
                                                                                        <div className="z-10 flex justify-center items-center bg-slate-100 rounded-md !w-full h-[160px] md:h-[200px] lg:h-[200px] overflow-hidden skeleton-animation">

                                                                                        </div>
                                                                                    )
                                                                            }

                                                                            <div className="p-4 w-full text-left">
                                                                                <h2 className="mb-3 max-h-[27px] text-ellipsis whitespace-nowrap overflow-hidden textBlack titleHoverInfo">{game?.title}</h2>
                                                                                <div className="flex justify-between mb-3 w-full">
                                                                                    <div className="flex flex-row items-center">
                                                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" class="mr-1 w-[22px] h-[20px] text-black" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"></path></svg>
                                                                                        <span className="font-15">{`Upto ${game?.headCount} Participants`}</span>

                                                                                    </div>

                                                                                    <div className="flex flex-row items-center">
                                                                                        <MdOutlineAccessTime className="mr-1 w-[22px] h-[22px]" />
                                                                                        <span className="font-15">{`Time-${game?.duration} mins`}</span>

                                                                                    </div>
                                                                                </div>
                                                                                {/* <p className="flex flex-wrap mb-3 max-h-[90px] font-14 text-ellipsis whitespace-nowrap overflow-hidden">
                                                                                {game?.description}
                                                                            </p> */}
                                                                                {/* <p className="mb-3 font-14 truncate-multiline">
                                                                                {game?.description}
                                                                            </p> */}
                                                                                <p className={`font-14 mb-3 description`}>
                                                                                    {expandedCards[game.id] ? game?.description : `${game?.description.slice(0, 150)}... `}
                                                                                    {game?.description.length > 150 && (
                                                                                        <span
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation(); // Prevent click event from bubbling up
                                                                                                toggleReadMore(game.id); // Call toggle function for the specific card
                                                                                            }}
                                                                                            className="text-blue-500 cursor-pointer"
                                                                                        >
                                                                                            {expandedCards[game.id] ? 'Read Less' : 'Read More'}
                                                                                        </span>
                                                                                    )}
                                                                                </p>

                                                                                <div className="flex flex-row justify-between items-center mt-1 mb-1">
                                                                                    <span className="font-semibold text-gray-900 text-md">
                                                                                        {selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'}{game.costOfEvent[selectedCurrency]}{game.typeOfEvent === 'offline' ? "/ Per Person" : ""}
                                                                                    </span>
                                                                                    <button className="bg-themeColor px-4 py-2 rounded-[6px] text-white" onClick={(e) => handleCardDetail(e, game.id, game.title)}>View Details</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : null
                                                        }

                                                    </Carousel>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                )
                            )
                        })
                        : null
                }
            </div>



            {/* <div className="relative z-10 bg-white p-4">
                <div class="flex flex-col justify-start mb-6 pr-12 pl-12 w-full">


                    <h1 class="mb-0 ml-8 font-40 font-bold"> Explore other  <span class="font-bold textRed">Events</span></h1>
                    <div className="flex justify-center mt-0">
                        <div className="flex flex-wrap justify-center gap-4 w-full max-w-[100%] bookeventcardcontainer">
                            <Carousel responsive={responsive23}>
                                <div className="relative flex flex-col items-center bg-white shadowBookEvent rounded-lg w-80 bookEventCard">
                                    <img src={curiositycorner} alt="Card 1 Image" className="mb-0 rounded-md w-full h-32 bookEventCardImg object-cover" />
                                    <div className="p-4 w-full text-left">
                                        <h2 className="mb-2 textBlack">Curiosity Corner</h2>
                                        <div className="flex justify-between mb-2 w-full">
                                            <span className="font-15">2000 Participants</span>
                                            <span className="font-15">Time-30 mins</span>
                                        </div>
                                        <p className="mb-3 font-14">
                                            tis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, s
                                        </p>
                                        <button className="bg-blue-500 bg-red-button px-4 py-2 rounded text-white">Book Now</button>
                                    </div>
                                </div>

                             
                            </Carousel>
                        </div>
                    </div>


                </div>
            </div>
            <div className="relative z-10 bg-white p-4">
                <div class="flex flex-col justify-start mb-6 pr-12 pl-12 w-full">


                    <h1 class="mb-0 ml-8 font-40 font-bold"> Explore other  <span class="font-bold textRed">Events</span></h1>
                    <div className="flex justify-center mt-0">
                        <div className="flex flex-wrap justify-center gap-4 w-full max-w-[100%] bookeventcardcontainer">
                            <Carousel responsive={responsive23}>

                                <div className="relative flex flex-col items-center bg-white shadowBookEvent rounded-lg w-80 bookEventCard">
                                    <img src={curiositycorner} alt="Card 1 Image" className="mb-0 rounded-md w-full h-32 bookEventCardImg object-cover" />
                                    <div className="p-4 w-full text-left">
                                        <h2 className="mb-2 textBlack">Curiosity Corner</h2>
                                        <div className="flex justify-between mb-2 w-full">
                                            <span className="font-15">2000 Participants</span>
                                            <span className="font-15">Time-30 mins</span>
                                        </div>
                                        <p className="mb-3 font-14">
                                            tis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, s
                                        </p>
                                        <button className="bg-blue-500 bg-red-button px-4 py-2 rounded text-white">Book Now</button>
                                    </div>
                                </div>


                            </Carousel>
                        </div>
                    </div>


                </div>
            </div> */}
            <div className="relative z-10">
                <Footer {...props} isAdmin={isAdmin} />
            </div>

            {/* <img src={Playbutton} alt="Bottom Right" className="bottom-right-image" /> */}
            {/* <div className="right-[30px] bottom-[30px] z-50 fixed lg:flex justify-center items-start hidden w-[140px] h-[80px]">
                <div className="flex justify-center items-center w-[95px] h-[80px] ball">
                    <img src={Playbutton} alt="happly-play-icon" />
                </div>
            </div> */}
        </>
    )
}

export default BookAnEvent
