import * as ActionTypes from "../constants/demoRequestConstants";

const initialState = {
  loading: false,
  demoRequests: [],
  error: ""
};

function getAllDemoRequestsReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FETCH_DEMO_REQUEST_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_DEMO_REQUEST_SUCCESS:
      return { ...state, loading: false, demoRequests: action.payload };
    case ActionTypes.FETCH_DEMO_REQUEST_FAILURE:
      return { ...state,loading: false, demoRequests: [], error: action.payload };
    default:
      return state;
  }
}

export { getAllDemoRequestsReducer };
