import * as ActionTypes from "../constants/teamApiConstants";

function getOrgTeamsReducer(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GET_ORG_TEAMS_REQUEST:
            return { loading: true };
        case ActionTypes.GET_ORG_TEAMS_SUCCESS:
            return { loading: false, orgTeams: action.payload };
        case ActionTypes.GET_ORG_TEAMS_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}


function getOrgTeamsGameSessionsReducer(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GET_ORG_TEAM_SESSION_REQUEST:
            return { loading: true };
        case ActionTypes.GET_ORG_TEAM_SESSION_SUCCESS:
            return { loading: false, teamSessions: action.payload };
        case ActionTypes.GET_ORG_TEAM_SESSION_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

function getTeamsGamesReport(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GET_TEAMS_GAMES_REPORT_REQUEST:
            return { loading: true };
        case ActionTypes.GET_TEAMS_GAMES_REPORT_SUCCESS:
            return { loading: false, teamReport: action.payload };
        case ActionTypes.GET_TEAMS_GAMES_REPORT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}

function getAllTeamsReportReducer(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GET_ALL_TEAMS_REPORT_REQUEST:
            return { loading: true };
        case ActionTypes.GET_ALL_TEAMS_REPORT_SUCCESS:
            return { loading: false, allTeamReport: action.payload };
        case ActionTypes.GET_ALL_TEAMS_REPORT_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }
}


export{
    getOrgTeamsReducer,
    getOrgTeamsGameSessionsReducer,
    getTeamsGamesReport,
    getAllTeamsReportReducer
}