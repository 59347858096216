import React, { useEffect, useLayoutEffect, useState } from "react";

import Account from "../../components/account/account";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import PageLayout from "../../components/pagelayout/pagelayout";
import { Redirect, useParams } from "react-router";
import OrgDetails from "../../components/account/orgDetails";
import { sidebarContentMyAccount, sidebarContentMyAccountUser, sidebarContentMyAccountWithXoxo, sidebarContentMyAccountUserForDummyUser } from '../../helpers/sidebarContentAccount';
import sidebarContentAdmin from '../../helpers/sidebarContentAdmin';
import PlanDetail from "../../components/plandetail/plandetail";
import Security from "../../components/security/security";
import TeamDetails from "../../components/teamDetail/TeamDetails";
import BadgesDetails from "../dashboard/badgesDetails";
import ROLES from "../../helpers/userTypes";
import MyPoints from './../../components/points/myPoints';
import { BASE_URL, axiosApiInstance } from "../../helpers/helper";
import BookingTransactionHistory from "../bookAnEventForm/BookingTransactionPage";
import { getOrgTeams } from "../../redux/actions/teamAction";
import TeamDetailsPointSystem from "../dashboard/teamDetails";

const MyAccount = (props) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.getUser);
  const userType = userInfo && userInfo.data && userInfo.data.role;
  const { tab } = useParams();
 
  const isAdmin =
    userInfo && userInfo.data && userInfo.data.role === "EXTRAMILE_SUPERADMIN";
    console.log(tab,"%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%",isAdmin)
  const userIsMail = userInfo && userInfo.data && userInfo.data.isMail;
  const [isXoxoEnabled, setXoxoEnabled] = useState(false);
  const [isInTeam, setIsInTeam] = useState(false)

  const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
  const { orgTeams } = getAllOrgsTeam;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (userInfo?.data?.organizationId) {
        const { data } = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/getXoxoEnableStatus", {
          organizationId: userInfo?.data?.organizationId
        })
        if (data.status === 200 && userInfo.data.role === ROLES.ORG_SUPER_ADMIN) {
          setXoxoEnabled(data?.data?.isXoxoEnable)
        }
      }
    })();
  }, [userInfo?.data?.organizationId]);

  useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.organizationId && userInfo.data.organisationTeamId) {
      dispatch(getOrgTeams(userInfo.data.organizationId))
    }
  }, [userInfo])

  useEffect(() => {
    if (orgTeams && orgTeams.message === "Sucessfully fetch org teams" && userInfo && userInfo.data && userInfo.data.organizationId && userInfo.data.organisationTeamId) {
      // let userTeamData = teamsInfo.filter((team) => team.id === userInfo.data.organisationTeamId);
      let userTeamData = findTeamByUserId(orgTeams.data, userInfo.data.id)
      console.log("userTeamData   ---->>>>>>  "+JSON.stringify(userTeamData));
      
      if (userTeamData) {
        setIsInTeam(true)
      }

    }
  }, [orgTeams])

  useEffect(()=>{
console.log("isInTeam  "+isInTeam);

  },[isInTeam])
  
  function findTeamByUserId(teamsInfo, userId) {
    for (const team of teamsInfo) {
      const member = team.members.find(member => member.id === userId);
      team.members.sort((a, b) => {
        if (a.id === team.teamCaptainId) return -1; // team captain goes first
        if (b.id === team.teamCaptainId) return 1;
        return 0;
      });
      if (member) {
        return team;
      }
    }
    return null; // If no team is found for the given userId
  }

  const filterTeamsDetails = (data)=>{
let filerData = data.filter((item)=> item.title !== "Team Details")

return filerData
  } 

  const signOut = async () => {
    await dispatch(logOut());
    props.history.push("/");
  };
  var active = ""
  if (tab === "details")
    active = "My Details";
  else if (tab === "org-details")
    active = "Organisation Details"
  else if (tab === "plan-details")
    active = "My Plan"
  else if (tab === "xoxo-day")
    active = "XOXO Day"
  else if (tab === "team-details")
    active = "Team Details"
  else if (tab === "security")
    active = "Security"
  else if (tab === "event-booking")
    active = "Event Booking"
  else if (tab === "all-badges")
    active = "Badges"
 

  if (isAdmin) {
    return (
      <div >
        <PageLayout
          sidebartitle=""
          active={"Profile"}
          sideBarContents={sidebarContentAdmin}
          profile
          {...props}
          signOut={signOut}
          isAdmin={isAdmin}
        >
          <Account />
        </PageLayout>
      </div>
    );
  } else {
    if (tab)
      return (
        // Updated UI for account
        <div >
          <PageLayout
            sidebartitle=""
            active={((userType === ROLES.ORG_ADMIN) || (userType === ROLES.ORG_SUPER_ADMIN) || (userType === ROLES.EMPLOYEE)) ? active : "My Details"}
            sideBarContents={((userType === ROLES.ORG_SUPER_ADMIN) && isXoxoEnabled && isInTeam === true) ? sidebarContentMyAccountWithXoxo : ((userType === ROLES.ORG_SUPER_ADMIN) && isXoxoEnabled && isInTeam === false) ? filterTeamsDetails(sidebarContentMyAccountWithXoxo) : userType === ROLES.EMPLOYEE && !userIsMail ? sidebarContentMyAccountUserForDummyUser : (userType === ROLES.ORG_ADMIN && isInTeam === true) || (userType === ROLES.ORG_SUPER_ADMIN && isInTeam === true) ? sidebarContentMyAccount : (userType === ROLES.ORG_ADMIN && isInTeam === false) || (userType === ROLES.ORG_SUPER_ADMIN && isInTeam === false) ? filterTeamsDetails(sidebarContentMyAccount) : !isInTeam ? filterTeamsDetails(sidebarContentMyAccountUser) : sidebarContentMyAccountUser}
            profile
            {...props}
            signOut={signOut}
            isAdmin={isAdmin}
          >
            {active === "My Details" ?
              <Account {...props} /> : active === "Organisation Details" ?
                <OrgDetails {...props} role={userInfo && userInfo.data && userInfo.data.role} email={userInfo && userInfo.data && userInfo.data.email} /> :
                active === "My Plan" ?
                  <div className="plandetail-wrapper">
                    <PlanDetail {...props} userType={userType} />
                  </div> : isInTeam &&  active !== "Badges"?
                    <div className="plandetail-wrapper">
                       {/* <TeamDetailsPointSystem {...props} userType={userType} /> */}
                      <TeamDetails {...props} userType={userType} />
                    </div> : active === "Security" ?
                      <div className="plandetail-wrapper">
                        <Security {...props} userType={userType} />
                      </div> : active === "Event Booking" ?
                        <div className="plandetail-wrapper">
                          <BookingTransactionHistory {...props} userType={userType} />
                        </div> : active === "Badges" ?
                          <div className="">
                            <BadgesDetails {...props} userType={userType} />
                          </div>:
                             isXoxoEnabled ?
                            <div className="plandetail-wrapper">
                              <MyPoints {...props} userType={userType} />
                            </div> : null
            }



          </PageLayout>
        </div>
      );
    return (<Redirect to="/account/details" />)
  }
};

export default MyAccount;
