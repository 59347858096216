import React, { useEffect, useRef, useState } from 'react';

import './security.css';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";

import { axiosApiInstance, BASE_URL, failureAlert, S3_BASE_URL, successAlert } from '../../helpers/helper';
import Password from "../../assets/backgrounds/Password.png"
import LoadingComponent from '../loader/LoadingComponent';
import UpdatePasswordOtp from './updatePassword-otp';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { CiCircleInfo } from 'react-icons/ci';
// import { FaCheckCircle } from "react-icons/fa";
// import { FaCircleCheck } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import { Helmet } from 'react-helmet-async';




const Security = (props) => {
    const UserInfo = useSelector(state => state.getUser)
    const { userInfo } = UserInfo;
    const [loaded, setLoaded] = useState(true);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [resetPasswordType, setResetPasswordType] = useState("reset-via-otp");
    const [showPassword, setShowPassword] = useState(false);
    const [isVerify, setIsVerify] = useState(false);

    const [inputValue, setInputValue] = useState({ password: "", verifyPassword: "" });
    const [touch, setTouch] = useState({ password: false, verifyPassword: false });

    const [passTouch, setPassTouch] = useState({ password: false });
    const [originalPassInput, setOriginalPassInput] = useState({ password: "" });

    const [otpTouch, setOtpTouch] = useState({ otp: false });
    const [otpInput, setOtpInput] = useState({ otp: "" });
    const [disableSendOtpBtn, setDisableSendOtpBtn] = useState(false);
    const [resendCounter, setResendCounter] = useState(0);
    const [displayFieldError, setDisplayFieldError] = useState({
        password: false,
        verifyPassword: false
    })


    useEffect(() => {
        if (userInfo && !userInfo.data.password) {
            setResetPasswordType('reset-via-otp');

        }
    }, [userInfo])

    useEffect(() => {
        if (resetPasswordType === "reset-via-password") {
            setOtpTouch({ otp: false });
            setOtpInput({ otp: "" });
            setPassTouch({ password: false });
            setOriginalPassInput({ password: "" });
            setInputValue({ password: "", verifyPassword: "" });
            setTouch({ password: false, verifyPassword: false });
            setShowPassword(false);
            setResendCounter(0);
            setDisableSendOtpBtn(false);
            setDisplayFieldError({
                password: false,
                verifyPassword: false
            })
            setIsVerify(false);
        }
        if (resetPasswordType === 'reset-via-otp') {
            setOtpTouch({ otp: false });
            setOtpInput({ otp: "" });
            setPassTouch({ password: false });
            setOriginalPassInput({ password: "" });
            setInputValue({ password: "", verifyPassword: "" });
            setTouch({ password: false, verifyPassword: false });
            setShowPassword(false);
            setDisableSendOtpBtn(false);
            setResendCounter(0);
            setDisplayFieldError({
                password: false,
                verifyPassword: false
            })
            setIsVerify(false);
        }
    }, [resetPasswordType])

    const counterinterval = useRef();

    useEffect(() => {
        if (resendCounter === 30) {
            let count = 30;
            counterinterval.current = setInterval(() => {
                if (count === 0)
                    clearInterval(counterinterval.current);
                else {
                    count -= 1;
                    setResendCounter(count);
                }
            }, 1000);
        }
    }, [resendCounter]);


    const handelResendOtpBtn = async (e) => {
        e.preventDefault();
        if (userInfo && userInfo.data && userInfo.data.id) {
            const body = {
                id: userInfo.data.id
            }
            setLoaded(false);
            const sendResetPasswordOtp = await axiosApiInstance.post(BASE_URL + "/api/user/reset/passowrd-otp", body);
            if (sendResetPasswordOtp.data.message === "Reset Password Otp Send Sucessfully") {
                successAlert("An OTP to reset your password has been sent to your email");
                setTimeout(() => {
                    setLoaded(true);
                    setResendCounter(30);
                }, 1000)
                setResetPasswordType('reset-via-otp');

            }
            else {
                failureAlert("Failed To Send OTP");
                setResetPasswordType('reset-via-password');
            }
        }
        else {
            failureAlert("Failed To Send OTP");
            setResetPasswordType('reset-via-password');
        }

    }

    const handelResetViaOtpBtn = async (e) => {
        e.preventDefault();
        if (userInfo && userInfo.data && userInfo.data.id) {
            const body = {
                id: userInfo.data.id
            }
            setLoaded(false);
            const sendResetPasswordOtp = await axiosApiInstance.post(BASE_URL + "/api/user/reset/passowrd-otp", body);
            if (sendResetPasswordOtp.data.message === "Reset Password Otp Send Sucessfully") {
                successAlert("An OTP to reset your password has been sent to your email.");
                setTimeout(() => {
                    setLoaded(true);
                    setDisableSendOtpBtn(true);
                    setResendCounter(30);
                }, 1000)
                setResetPasswordType('reset-via-otp');

            }
            else {
                failureAlert("Failed To Send OTP");
                setResetPasswordType('reset-via-password');
            }
        }
        else {
            failureAlert("Failed To Send OTP");
            setResetPasswordType('reset-via-password');
        }

    }
    const handelResetViaPasswordBtn = async (e, resetType) => {
        e.preventDefault();
        if (userInfo && !userInfo.data.password) {
            setResetPasswordModal(true);
        }
        else {
            setResetPasswordType(resetType);

        }
        // if (resetType === 'reset-via-otp') {
        //     handelResetViaOtpBtn(e);
        // }
    }

    // const handelResetViaPasswordBtn = () => {
    //     setResetPasswordType("enter-password-form");
    //     setResetPasswordModal(true);
    // }

    function validatePassword(password) {
        let validLength = false;
        if (password.length > 4 && password.length <= 15) {
            validLength = true;
        }
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

        return hasUpperCase && hasLowerCase && hasNumber && hasSymbol && validLength;
    }

    const newResetPasswordValidation = (input) => {

        const errors = {
            password: '',
            verifyPassword: ''
        };
        if (touch.password && !validatePassword(input.password)) {
            errors.password = "Your password length must be between 4 to 15 must contain at least one uppercase letter, one number, and one symbol"
        }
        if (touch.password && input.password === "") {
            errors.password = "Your password length must be between 4 to 15 must contain at least one uppercase letter, one number, and one symbol"
        }
        if (touch.verifyPassword && inputValue.password !== "" && (input.verifyPassword !== inputValue.password || input.verifyPassword === "")) {
            errors.verifyPassword = "Password must be same as your set password"
        }
        if (touch.verifyPassword && input.verifyPassword !== "" && inputValue.password === "") {
            errors.verifyPassword = "Password must be same as your set password"
        }
        if (touch.verifyPassword && input.verifyPassword === "") {
            errors.verifyPassword = "Password must be same as your set password"
        }
        return errors;
    };

    let ResetPasswordErrors = newResetPasswordValidation(inputValue);


    const handelResetPasswordChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (touch[name]) {
            setTouch(prevState => ({ ...prevState, [name]: false }));
        }
        setInputValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handelOriginalPasswordChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (passTouch[name]) {
            setPassTouch(prevState => ({ ...prevState, [name]: false }));
        }
        setOriginalPassInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handelOtpInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (otpTouch[name]) {
            setOtpTouch(prevState => ({ ...prevState, [name]: false }));
        }
        setOtpInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        if (resetPasswordType === 'reset-via-password' && originalPassInput.password !== "") {
            const body = {
                id: userInfo.data.id,
                password: originalPassInput.password
            }
            setLoaded(false);
            const response = await axiosApiInstance.post(BASE_URL + "/api/user/verify/password", body);
            if (response.data.message === "Valid User Password") {
                setLoaded(true);
                setIsVerify(true);
                // handelPasswordReset(e);
            }
            else if (response && response.data && response.data.message === "Invalid User Password") {
                // setOrgPassError(response.data.message);
                failureAlert(response.data.message);
                setLoaded(true);
            }
            else if (response && response.data && response.data.message === "User does not have passowrd set up") {
                failureAlert("User does not have password set up");
                setLoaded(true);
            }
            else {
                failureAlert("Failed To Validate User Password");
                setLoaded(true);
            }
        }
        else if (resetPasswordType === 'reset-via-otp' && otpInput.otp !== "") {
            const body = {
                otp: otpInput.otp,
                email: userInfo.data.email
            }
            setLoaded(false);
            const verifyResetPasswordOtp = await axiosApiInstance.post(BASE_URL + "/api/user/verify/reset/password-otp", body);
            if (verifyResetPasswordOtp && verifyResetPasswordOtp.data && verifyResetPasswordOtp.data.message === "OTP VERIFIED") {
                setLoaded(true);
                setIsVerify(true);
                // handelPasswordReset(e)

            }
            else if (verifyResetPasswordOtp && verifyResetPasswordOtp.data && verifyResetPasswordOtp.data.message === "OTP EXPIRED") {
                setLoaded(true);
                // setOtpError(verifyResetPasswordOtp.data.message);
                failureAlert(verifyResetPasswordOtp.data.message);
            }
            else if (verifyResetPasswordOtp && verifyResetPasswordOtp.data && verifyResetPasswordOtp.data.message === "INVALID OTP") {
                setLoaded(true);
                // setOtpError(verifyResetPasswordOtp.data.message);
                failureAlert(verifyResetPasswordOtp.data.message);
            }
            else {
                setLoaded(true);
                failureAlert("OTP Verification Failed");
            }
        }
        else {
            failureAlert('Invaild Input');
        }
    }

    const handelSaveChanges = async (e) => {
        e.preventDefault();
        if (resetPasswordType === 'reset-via-otp' && otpInput.otp !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "") {
            handelPasswordReset(e);
        }
        else if (resetPasswordType === 'reset-via-password' && originalPassInput.password !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "") {
            handelPasswordReset(e);
        }
        else {
            failureAlert('Invaild Input');
        }
    }


    const handelPasswordReset = async (e) => {
        e.preventDefault();
        if (inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "") {
            if (inputValue.password === inputValue.verifyPassword) {
                const body = {
                    password: inputValue.password,
                    verifyPassword: inputValue.verifyPassword,
                    id: userInfo.data.id
                }
                setLoaded(false);
                const response = await axiosApiInstance.post(BASE_URL + "/api/user/update/user/password", body);
                if (response.data.message === "Sucessfully Updated User Password") {
                    setTimeout(() => {
                        setLoaded(true);
                        successAlert("Password updated successfully");
                        setTouch({ password: false, verifyPassword: false });
                        setInputValue({ password: "", verifyPassword: "" });
                        setPassTouch({ password: false });
                        setOriginalPassInput({ password: "" })
                        setOtpTouch({ otp: false });
                        setOtpInput({ otp: "" });
                        setResetPasswordType('reset-via-otp');
                        setDisableSendOtpBtn(false)
                        setIsVerify(false);
                    }, 1000)

                }
                else {
                    failureAlert("Failed To Update Password");
                }

            }
        }
    }

    const handelCancel = (e) => {
        e.preventDefault();
        setOtpTouch({ otp: false });
        setOtpInput({ otp: "" });
        setPassTouch({ password: false });
        setOriginalPassInput({ password: "" });
        setInputValue({ password: "", verifyPassword: "" });
        setTouch({ password: false, verifyPassword: false });
        setShowPassword(false);
        setResetPasswordType("reset-via-password");
        setIsVerify(false);
    }

    const handleIiconClick = (fieldName) => {
        setDisplayFieldError((prev) => ({ ...prev, [fieldName]: !displayFieldError[fieldName] }));
    }

    return (
        <div className={loaded ? "myaccount-wrapper" : "myaccount-wrapper loading"} id="myaccount-element">
            <Helmet>
                <title>Security</title>
                <meta name="description" content="Security" />
                <meta name="keywords" content="Security" />
            </Helmet>
            <ToastContainer position="bottom-center" />
            <LoadingComponent loaded={loaded} />
            {resetPasswordModal && <UpdatePasswordOtp userEmail={userInfo.data.email} userId={userInfo.data.id} toggle={resetPasswordModal} modalId="Account changes" resetPasswordType={resetPasswordType} setResetPasswordModal={setResetPasswordModal} setLoaded={setLoaded}></UpdatePasswordOtp>}
            <div className="pass-rest-left-cont">

                <div className="myaccount-heading">
                    <h5>Security</h5>
                </div>
                <div className="reset-password-tabs-options">
                    {/* <button type='button' className='btn btn-primary' style={{ backgroundColor: '#5F638A' }} onClick={handelResetViaPasswordBtn}> Reset Via password</button>
                <button type='button' className='btn btn-primary' style={{ color: '#5F638A', backgroundColor: '#FFE74C' }} onClick={(e) => handelResetViaOtpBtn(e)}> Reset Via Otp</button> */}

                    <button type='button' className='btn btn-primary' style={{ color: 'white', backgroundColor: resetPasswordType === 'reset-via-password' ? 'var(--background-theme)' : '#5F638A', boxShadow: resetPasswordType === 'reset-via-password' ? '0px 8px 24px 2px rgba(0, 0, 0, 0.16)' : '' }} onClick={(e) => handelResetViaPasswordBtn(e, "reset-via-password")}> Reset Via password</button>
                    <button type='button' className='btn btn-primary' style={{marginLeft:'20px', color: 'white', backgroundColor: resetPasswordType === 'reset-via-otp' ? 'var(--background-theme)' : '#5F638A', boxShadow: resetPasswordType === 'reset-via-otp' ? '0px 8px 24px 2px rgba(0, 0, 0, 0.16)' : '' }} onClick={(e) => handelResetViaPasswordBtn(e, "reset-via-otp")} disabled={disableSendOtpBtn}> Reset Via OTP</button>
                </div>
                <div className="pswd-input-container">
                    <div className="pass-otp-input-cont" style={{ justifyContent: 'flex-start', display:isVerify ? 'none' : 'flex' }}>
                        <div className="form-group" style={{ width: '300px' }}>

                            {
                                resetPasswordType === 'reset-via-otp' ? (<>

                                    {resendCounter !== 0 && <span className={`error-message ${resendCounter > 0 ? "" : "invisible"}`}>{`re-send OTP in 00:${resendCounter > 9 ? resendCounter : "0" + resendCounter}`}</span>}
                                    <label htmlFor="otp">Confirm Email </label>
                                    <div className="password-container">
                                        <input
                                            className={"form-control"}
                                            id="email"
                                            name="email"
                                            type={'text'}
                                            // placeholder="Insert OTP"
                                            // onChange={(e) => handelOtpInputChange(e)}
                                            // onBlur={() => setOtpTouch(prevState => ({ ...prevState, password: true }))}
                                            value={userInfo?.data?.email}
                                            required
                                            style={{ border: '1px solid #707070', width: '300px' }}
                                            disabled={true}

                                        />
                                    </div>

                                    {disableSendOtpBtn ? (<>
                                        <label htmlFor="otp">Insert OTP *</label>
                                        <div className="password-container">
                                            <input
                                                className={"form-control"}
                                                id="otp"
                                                name="otp"
                                                type={'number'}
                                                placeholder="Insert OTP"
                                                onChange={(e) => handelOtpInputChange(e)}
                                                onBlur={() => setOtpTouch(prevState => ({ ...prevState, password: true }))}
                                                value={otpInput.otp}
                                                required
                                                style={{ border: '1px solid #707070', width: '300px' }}
                                                disabled={isVerify}

                                            />
                                            {isVerify ? (
                                                <FaRegCheckCircle style={{ position: 'absolute', color: '#25da2a', right: '-7%' }} />

                                            ) : null}
                                        </div>
                                        {!isVerify && disableSendOtpBtn && resendCounter === 0 && <button type='button' className='btn btn-primary' style={{ backgroundColor: '#5F638A', fontSize: '10px', height: '26px', width: '84px', marginTop: '10px' }} onClick={(e) => handelResendOtpBtn(e, "reset-via-otp")}> Resend OTP</button>}

                                    </>) : null}

                                </>
                                ) : resetPasswordType === 'reset-via-password' ? (<>
                                    <label htmlFor="password">Insert your current passwords *</label>

                                    <div className="password-container">
                                        <input
                                            className={"form-control"}
                                            id="password"
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder="Insert your current password"
                                            onChange={(e) => handelOriginalPasswordChange(e)}
                                            onBlur={() => setPassTouch(prevState => ({ ...prevState, password: true }))}
                                            value={originalPassInput.password}
                                            required
                                            style={{ border: '1px solid #707070', width: '300px' }}
                                            disabled={isVerify}

                                        />
                                        {isVerify ? (
                                            <FaRegCheckCircle style={{ position: 'absolute', color: '#25da2a', right: '-7%' }} />
                                        ) : null}

                                        <div
                                            className="pass-eye-btn"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ? <FaEye style={{ position: 'absolute' }} /> : <FaEyeSlash style={{ position: 'absolute' }} />}
                                        </div>
                                    </div>
                                </>) : null
                            }


                        </div>

                    </div>

                    <div className="pass-otp-input-cont" style={{ justifyContent: 'flex-start' }}>

                        <div className="form-group" style={{ width: '300px' }}>

                            {
                                isVerify ? (<>
                                    <label htmlFor="password">New Password *</label>
                                    <div className="tooltip-input-container">
                                        {ResetPasswordErrors.password !== "required*" && displayFieldError.password && <div className="tolltip-error-text" style={{ display: ResetPasswordErrors.password === "" ? 'none' : 'flex', left: '-94%' }}>{ResetPasswordErrors.password}</div>}
                                        {ResetPasswordErrors.password !== "" && <CiCircleInfo style={{ position: 'absolute', left: '84%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('password')}></CiCircleInfo>}


                                        <div className="password-container">
                                            <input
                                                className={ResetPasswordErrors.password ? "error-input" : "form-control"}
                                                id="password"
                                                name="password"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Enter new password"
                                                onChange={(e) => handelResetPasswordChange(e)}
                                                onBlur={() => setTouch(prevState => ({ ...prevState, password: true }))}
                                                value={inputValue.password}
                                                required
                                                style={{ border: '1px solid #707070', width: '300px', borderColor: ResetPasswordErrors.password ? '#fc8181' : '#707070' }}

                                            />
                                            {
                                                touch.password === true && inputValue.password !== "" && ResetPasswordErrors.password === "" ? (
                                                    <FaRegCheckCircle style={{ position: 'absolute', color: '#25da2a', right: '-7%' }} />
                                                ) : null
                                            }
                                            <div
                                                className="pass-eye-btn"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {showPassword ? <FaEye style={{ position: 'absolute' }} /> : <FaEyeSlash style={{ position: 'absolute' }} />}
                                            </div>
                                        </div>

                                        {/* {!touch.password && ResetPasswordErrors.password === "" && <span style={{ width: '100%', fontSize: '8px', color: '#E25569', fontStyle: 'inherit', fontWeight: '100', padding: '0' }}>Your password length must be between 4 to 15 must contain at least one uppercase letter, one number, and one symbol<br /></span>} */}
                                        {ResetPasswordErrors.password && <div className="error-message" style={{ position: 'static' }}>{ResetPasswordErrors.password}</div>}
                                    </div>
                                    <label htmlFor="verifyPassword">Verify Password *</label>
                                    <div className="tooltip-input-container">
                                        {ResetPasswordErrors.verifyPassword !== "required*" && displayFieldError.verifyPassword && <div className="tolltip-error-text" style={{ display: ResetPasswordErrors.verifyPassword === "" ? 'none' : 'flex', left: '-94%' }}>{ResetPasswordErrors.verifyPassword}</div>}
                                        {ResetPasswordErrors.verifyPassword !== "" && <CiCircleInfo style={{ position: 'absolute', left: '84%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('verifyPassword')}></CiCircleInfo>}

                                        <div className="password-container">
                                            <input
                                                className={ResetPasswordErrors.verifyPassword ? "error-input" : "form-control"}
                                                id="verifyPassword"
                                                name="verifyPassword"
                                                type={showPassword ? 'text' : 'password'}
                                                placeholder="Verify password"
                                                onChange={(e) => handelResetPasswordChange(e)}
                                                onBlur={() => setTouch(prevState => ({ ...prevState, verifyPassword: true }))}
                                                value={inputValue.verifyPassword}
                                                required
                                                style={{ border: '1px solid #707070', width: '300px', borderColor: ResetPasswordErrors.verifyPassword ? '#fc8181' : '#707070' }}
                                            />
                                            {
                                                touch.verifyPassword === true && inputValue.verifyPassword !== "" && ResetPasswordErrors.verifyPassword == "" ? (
                                                    <FaRegCheckCircle style={{ position: 'absolute', color: '#25da2a', right: '-7%' }} />
                                                ) : null
                                            }
                                            <div
                                                className="pass-eye-btn"
                                                onClick={togglePasswordVisibility}
                                            >

                                                {showPassword ? <FaEye style={{ position: 'absolute' }} /> : <FaEyeSlash style={{ position: 'absolute' }} />}
                                            </div>
                                        </div>

                                        {/* {!touch.verifyPassword && ResetPasswordErrors.verifyPassword === "" && <div className="error-message" style={{ position: 'static' }}>verify password must be same as your set password</div>} */}
                                        {ResetPasswordErrors.verifyPassword && <div className="error-message" style={{ position: 'static' }}>{ResetPasswordErrors.verifyPassword}</div>}
                                    </div>
                                </>) : null
                            }



                        </div>

                    </div>
                </div>
                <div className="changes-btns-cont">
                    {isVerify ? (<>
                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={(e) => handelCancel(e)}
                            style={{
                                // width:'10%',
                                margin: '1%',
                                opacity: otpInput.otp !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? '1' : originalPassInput.password !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? '1' : '0.5',
                                cursor: otpInput.otp !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? 'pointer' :
                                    originalPassInput.password !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? 'pointer' : 'not-allowed'
                            }}
                            disabled={
                                otpInput.otp !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? false :
                                    originalPassInput.password !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? false : true
                            }
                        >cancel</button>

                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={(e) => handelSaveChanges(e)}
                            style={{
                                opacity: otpInput.otp !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? '1' : originalPassInput.password !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? '1' : '0.5',
                                cursor: otpInput.otp !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? 'pointer' :
                                    originalPassInput.password !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? 'pointer' : 'not-allowed'
                            }}
                            disabled={
                                otpInput.otp !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? false :
                                    originalPassInput.password !== "" && inputValue.password !== "" && inputValue.verifyPassword !== "" && ResetPasswordErrors.password === "" && ResetPasswordErrors.verifyPassword === "" ? false : true
                            }
                        >save changes</button>
                    </>) : (<>

                        <button
                            type='button'
                            className='btn btn-primary'
                            onClick={(e) => resetPasswordType === 'reset-via-otp' && !disableSendOtpBtn ? handelResetViaOtpBtn(e) : handleVerify(e)}
                            style={{
                                // width:'107px',

                                opacity: resetPasswordType === 'reset-via-otp' && !disableSendOtpBtn ? '1' : otpInput.otp !== "" ? '1' : originalPassInput.password !== "" ? '1' : '0.5',
                                cursor: resetPasswordType === 'reset-via-otp' && !disableSendOtpBtn ? 'pointer' : otpInput.otp !== "" ? 'pointer' : originalPassInput.password !== "" ? 'pointer' : 'not-allowed'
                            }}
                            disabled={
                                resetPasswordType === 'reset-via-otp' && !disableSendOtpBtn ? false : otpInput.otp !== "" ? false : originalPassInput.password !== "" ? false : true
                            }
                        >{resetPasswordType === 'reset-via-otp' && !disableSendOtpBtn ? "Request OTP" : resetPasswordType === 'reset-via-otp' && disableSendOtpBtn ? 'Verify OTP' : resetPasswordType === 'reset-via-password' ? 'verify password' : ''}</button>
                    </>)
                    }

                </div>
            </div>

            <div className="pass-reset-right-cont">
                <img src={Password} style={{
                    maxWidth:'fit-content'
                }}></img>
            </div>


        </div>
    );
};

export default Security;