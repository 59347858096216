export const GET_ALL_POINTS_REQUEST = "GET_ALL_POINTS_REQUEST";
export const GET_ALL_POINTS_SUCCESS = "GET_ALL_POINTS_SUCCESS";
export const GET_ALL_POINTS_FAIL = "GET_ALL_POINTS_FAIL";

export const GET_LOGIN_STREAK_REQUEST = "GET_LOGIN_STREAK_REQUEST";
export const GET_LOGIN_STREAK_SUCCESS = "GET_LOGIN_STREAK_SUCCESS";
export const GET_LOGIN_STREAK_FAIL = "GET_LOGIN_STREAK_FAIL";

export const GET_TASK_REQUEST = "GET_TASK_REQUEST";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAIL = "GET_TASK_FAIL";

export const ADD_BADGE_ID= "ADD_BADGE_ID";
