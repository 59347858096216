import React, { useEffect, useRef, useState } from 'react';
import "./TeamDetails.css"
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from "react-toastify";

import { axiosApiInstance, BASE_URL, failureAlert, S3_BASE_URL, successAlert } from '../../helpers/helper';

import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import PlayIcon from "../../assets/backgrounds/team logo.png"
import { GoStarFill } from 'react-icons/go';
import ROLES from '../../helpers/userTypes';
import arrowleft from '../../assets/images/paginationarrow.svg';
import { Helmet } from 'react-helmet-async';



const TeamDetails = (props) => {
  const UserInfo = useSelector(state => state.getUser)
  const { userInfo } = UserInfo;
  const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
  const { orgTeams } = getAllOrgsTeam;

  const [loaded, setLoaded] = useState(true);
  const [teamsInfo, setTeamsInfo] = useState([]);
  const [teamLogo, setTeamLogo] = useState()
  const [selectedTeamInfo, setSelectedTeamInfo] = useState()
  const [lightColor, setLightColor] = useState("#D83307")
  const [themeColor, setThemeColor] = useState('#D83307')


  const dispatch = useDispatch()


  useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.organizationId && userInfo.data.organisationTeamId) {
      dispatch(getOrgTeams(userInfo.data.organizationId))
    }
  }, [userInfo])

  useEffect(() => {
    if (getAllOrgsTeam && getAllOrgsTeam.loading) {
      setLoaded(false)
    }
    else {
      setLoaded(true)
    }
  }, [getAllOrgsTeam])

  useEffect(() => {
    if (orgTeams && orgTeams.message === "Sucessfully fetch org teams") {
      setTeamsInfo(orgTeams.data);
      // setTeamLogo(orgTeams.data[0].teamLogo);


    }
    else if (orgTeams && orgTeams.message === "org teams not available") {
      setTeamsInfo([]);
    }

  }, [getAllOrgsTeam])

  useEffect(() => {
    const callBack = async () => {
      if (teamsInfo && teamsInfo.length > 0 && userInfo && userInfo.data && userInfo.data.organizationId && userInfo.data.organisationTeamId) {
        // let userTeamData = teamsInfo.filter((team) => team.id === userInfo.data.organisationTeamId);
        let userTeamData = findTeamByUserId(teamsInfo, userInfo.data.id)
        setSelectedTeamInfo(userTeamData)
        setTeamLogo(userTeamData.teamLogo);
      }
    }
    callBack()
  }, [teamsInfo])

  function findTeamByUserId(teamsInfo, userId) {
    for (const team of teamsInfo) {
      const member = team.members.find(member => member.id === userId);
      team.members.sort((a, b) => {
        if (a.id === team.teamCaptainId) return -1; // team captain goes first
        if (b.id === team.teamCaptainId) return 1;
        return 0;
      });
      if (member) {
        return team;
      }
    }
    return null; // If no team is found for the given userId
  }

  function capitalizeWords(str) {
    if (!str) {
      return '';
    }
    return str.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  }


  useEffect(() => {
    const colorTheme = getComputedStyle(document.documentElement).getPropertyValue('--color-theme').trim();
    setThemeColor(colorTheme)
    if (colorTheme !== '#D83307') {
      let color = generateGradient(colorTheme);
      setLightColor(color)
    }

  }, [lightColor])



  function generateGradient(color) {
    function lightenColor(color, percent) {
      const num = parseInt(color.replace("#", ""), 16); // Remove '#' and parse hex
      const r = (num >> 16) + Math.round((255 - (num >> 16)) * percent / 100);
      const g = ((num >> 8) & 0x00ff) + Math.round((255 - ((num >> 8) & 0x00ff)) * percent / 100);
      const b = (num & 0x0000ff) + Math.round((255 - (num & 0x0000ff)) * percent / 100);
      return `rgb(${Math.min(255, r)}, ${Math.min(255, g)}, ${Math.min(255, b)})`;
    }

    if (!/^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/.test(color)) {
      throw new Error("Please provide a valid HEX color code.");
    }

    const lighterColor = lightenColor(color, 75);
    return lighterColor;
  }
  const [page, setPage] = useState(1);
  const [paginationDataGames, setpaginationDataGames] = useState();
  const [members, setMembers] = useState([])


  useEffect(() => {
    if (selectedTeamInfo && selectedTeamInfo?.members && selectedTeamInfo?.members?.length > 0) {
      const { data, paginationData } = pagination({
        data: selectedTeamInfo?.members,
        perPage: 10,
        currentPage: page,
        getAll: false
      });
      setpaginationDataGames(paginationData);
      setMembers(data)
    }
  }, [page, selectedTeamInfo])

  const pagination = ({ data, perPage, currentPage, getAll = false }) => {
    try {
      perPage = Number(perPage);
      currentPage = Number(currentPage);
      if (getAll) {
        return {
          data,
          paginationData: {
            currentPage: 1,
            perPage: data.length,
            totalEntries: data.length
          }
        };
      }
      let tableDataCount = data.length;
      let paginationItemsCount =
        tableDataCount % perPage === 0
          ? tableDataCount / perPage
          : tableDataCount / perPage + 1;
      paginationItemsCount = Math.floor(paginationItemsCount);
      let currPage = currentPage > paginationItemsCount ? 1 : currentPage;
      return {
        data: data.slice((currPage - 1) * perPage, currPage * perPage),
        paginationData: {
          currentPage: currPage,
          perPage: perPage,
          totalEntries: data.length
        }
      };
    } catch (error) {
      console.log(`"ERROR => ${error}"`);
      throw { error };
    }
  };

  const allGamesChangePage = (newPage) => {
    setLoaded(false);
    setPage(newPage)
    setTimeout(() => {
      setLoaded(true)
      window.scrollTo(0, 0);
    }, 100);
  }

  return (
    <div className={loaded ? "myaccount-wrapper" : "myaccount-wrapper loading"} id="myaccount-element">
      <Helmet>
        <title>Team Details</title>
        <meta name="description" content="Team Details" />
        <meta name="keywords" content="Team Details" />
      </Helmet>
      <ToastContainer position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <div className="flex flex-col w-full h-full">

        <div className="flex justify-center items-center mb-[50px] w-full">
          {
            loaded ? (
              <div className={`flex flex-row justify-start sm:justify-center md:justify-center lg:justify-center items-center p-[13px] rounded-[6px] w-[100%] lg:w-[824px] h-[99px] sm:h-[120px] md:h-[152px] lg:h-[152px]`}
                style={{
                  background: `linear-gradient(90deg, ${themeColor} 0%, ${lightColor} 100%)`

                }}
              >
                <div className="relative flex justify-center items-center ml-0 rounded-full w-[66px] sm:w-[100px] md:w-[127px] lg:w-[127px] h-[66px] sm:h-[100px] md:h-[127px] lg:h-[127px]" >
                  <img
                    src={teamLogo ? `${S3_BASE_URL}${teamLogo}` : PlayIcon}
                    alt="logo" className="rounded-full w-full h-full object-cover" />
                  {/* 
                          <div className="right-[-15px] bottom-0 absolute flex justify-center items-center bg-white rounded-[6px] w-[32px] h-[32px] cursor-pointer" onClick={handleButtonClick}>
                              <div className="relative flex justify-center items-center bg-[#E2556926] rounded-[6px] w-full h-full">
                                  <FaRegEdit className="rounded-[6px] w-[15px] h-[15px]" fill='#E25569' />
                                  <input
                                      type="file"
                                      className="hidden opacity-0 rounded-[6px] w-[32px] h-[32px] cursor-pointer"
                                      name="team-logo-upload"
                                      onChange={fileChanged}
                                      accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                      ref={fileInputRef}
  
                                  />
  
                              </div>
  
                          </div> */}

                </div>
                <div className="flex flex-col justify-center items-center p-10">
                  <h1 className='font-semibold text-[24px] text-center text-white sm:text-[30px] md:text-[42px] lg:text-[42px]'> {selectedTeamInfo && selectedTeamInfo?.name ? capitalizeWords(selectedTeamInfo.name) : 'Team Name'}</h1>
                  <span className='font-light text-[18px] text-white md:text-[20px] lg:text-[20px]'>  {`Members ${teamsInfo && teamsInfo.length > 0 && selectedTeamInfo ? selectedTeamInfo.members.length : 0}`}</span>
                </div>

              </div>
            ) : (
              <div className={`flex flex-row  bg-slate-100 skeleton-animation justify-start sm:justify-center md:justify-center lg:justify-center items-center p-[13px] rounded-[6px] w-[100%] lg:w-[824px] h-[99px] sm:h-[120px] md:h-[152px] lg:h-[152px]`}
              ></div>
            )
          }


        </div>

        <div className="team-table">
          <table>
            <thead style={{ height: '69px' }}>
              <tr>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}>Full Name</th>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}>Email ID</th>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}>Role</th>
                <th style={{ fontSize: '20px', fontFamily: 'sans-serif' }}></th>

              </tr>
            </thead>
            <tbody>
              {
                loaded ? members && members.length > 0 && members.map((user, index) => {
                  return (
                    <tr style={{ height: '69px' }}>
                      <td>{`${capitalizeWords(user.firstName)} ${capitalizeWords(user.lastName)}`}</td>
                      <td>{user.email}</td>
                      <td className="teams-role">{user.role === ROLES.ORG_SUPER_ADMIN ? "Owner" : user.role === ROLES.ORG_ADMIN ? "Admin" : user.role === ROLES.EMPLOYEE ? "Employee" : "User"}</td>

                      <td className="teams-role">
                        {
                          user.id === selectedTeamInfo.teamCaptainId ? (
                            <div className="flex items-start w-[23px]">
                              <GoStarFill className='w-[15px] sm:w-[18px] md:w-[20px] lg:w-[20px] h-[16px] sm:h-[19px] md:h-[21px] lg:h-[21px]' fill='#5ECA87' />
                            </div>
                          )
                            : null
                        }

                      </td>
                    </tr>
                  )
                })
                  : Array.from({ length: 6 }).map((_, index) => (
                    <tr key={index} className='bg-slate-100 w-full h-[69px] skeleton-animation'>

                    </tr>
                  ))
              }

            </tbody>
          </table>
        </div>

        <div className="m-teams-table table-responsive">
          {
            loaded ? members && members.length > 0 && members.map((user, index) => {
              return (
                <div className="team-m-card c-white-card"
                //  key={JSON.stringify(user)}
                >
                  <div className="team-m-top">
                    <div className="role">

                      <div className="teams-m-name">{`${capitalizeWords(user.firstName)} ${capitalizeWords(user.lastName)}`}</div>
                      <div className="team-m-user">{user.email}</div>
                      {/* <span>Role</span>
                      <div className="teams-m-title">{user.role === Roles.ORG_SUPER_ADMIN ? "Owner" : user.role === Roles.ORG_ADMIN ? "Admin" : "User"}</div> */}
                    </div>

                  </div>

                  <div className="team-m-bottom"
                  // style={
                  //   {
                  //     marginTop: userInfo && userInfo.data && user.email !== userInfo.data.email ? '0px' : '10px'

                  //   }
                  // }
                  >
                    <div>

                      <div className={`flex items-end justify-center h-full w-full text-[16px] font-sans font-semibold `}>Role-{user.role === ROLES.ORG_SUPER_ADMIN ? "Owner" : user.role === ROLES.ORG_ADMIN ? "Admin" : user.role === ROLES.EMPLOYEE ? "Employee" : "User"}</div>

                    </div>
                    <div className="flex">
                      {
                        user.id === selectedTeamInfo.teamCaptainId ? (
                          <div className="flex items-start w-[23px]">
                            <GoStarFill className='w-[15px] sm:w-[18px] md:w-[20px] lg:w-[20px] h-[16px] sm:h-[19px] md:h-[21px] lg:h-[21px]' fill='#5ECA87' />
                          </div>
                        )
                          : null
                      }
                    </div>
                    {/* <div className="flex items-start w-[23px]">
                <GoStarFill className='w-[15px] sm:w-[18px] md:w-[20px] lg:w-[20px] h-[16px] sm:h-[19px] md:h-[21px] lg:h-[21px]' fill='#5ECA87' />
              </div> */}
                  </div>
                </div>
              )
            })
            : Array.from({ length: 6 }).map((_, index) => (
              <div key={index} className='bg-slate-100 team-m-card rounded-[6px] h-[129px] c-white-card skeleton-animation'>

              </div>
            ))
          }

        </div>

        <div className="flex justify-center items-center mb-8 w-full">
          {paginationDataGames?.totalEntries > 0 &&
            <div className="pagination-wrapper">
              <button
                className={page > 1 ? "pagination-left enable" : "pagination-left"}
                onClick={() => {
                  if (page > 1)
                    allGamesChangePage(page - 1);
                  window.scrollTo(0, 0);
                }}
              >
                <img src={arrowleft} alt="arrow left" />
              </button>
              <div className="pagination-number">
                <h5>{page}</h5>
                <span>of {Math.ceil(paginationDataGames?.totalEntries / 10)}{" "}pages</span>
              </div>
              <button
                className={(page < Math.ceil(paginationDataGames?.totalEntries / 10)) ? "pagination-right enable" : "pagination-right"}
                onClick={() => {
                  if (page < Math.ceil(paginationDataGames?.totalEntries / 10))
                    allGamesChangePage(page + 1);
                }}
              >
                <img src={arrowleft} alt="arrow right" />
              </button>
            </div>
          }
        </div>


      </div>
    </div >
  );
};

export default TeamDetails;