import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ErrorBoundary from './containers/ErrorBoundry/ErrorBoundry';
import store from './redux/store/ConfigureStore';
import Routes from './routes';
import './styles/common.css';
import snowFlakeImage from './assets/backgrounds/ice_image.png'
import { HelmetProvider } from 'react-helmet-async';

import OneSignal from 'react-onesignal'

const snowflake1 = document.createElement('img')
snowflake1.src = snowFlakeImage
const snowFlakeImages = [snowflake1]



// OneSignal.init({ appId: "2fb73830-8962-477b-b4a0-344eef7795a8", allowLocalhostAsSecureOrigin: true});
OneSignal.init({ appId: "25ad3c6f-50a4-4b80-9981-d8fc15a5d30b", allowLocalhostAsSecureOrigin: true })
OneSignal.showSlidedownPrompt();

const UnsecuredPage = () => (
  <div className='text-center mt-4 pt-4'>
    You cannot access this page
  </div>
);

// Won't render the application if WebApp is under Clickjacking attack
if (window.self === window.top) {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <HelmetProvider>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </HelmetProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<UnsecuredPage />, document.getElementById('root'));
}