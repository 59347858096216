import React, { useEffect, useState, useRef } from "react";
import "./modal.css";
import PhoneInput from "react-phone-input-2";
import Modal from "./modal";
import loginicon from "../../assets/images/login.svg";
import { useHistory } from "react-router-dom";
import { BASE_URL, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, axiosApiInstance, videoUrl } from "../../helpers/helper";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer, toast } from "react-toastify";
const ContactUsModal = ({ modalid, toggle, setOpenContactUsModal, userInfo,setHasContactUsModalClosed }) => {
 
  const [loaded, setLoaded] = useState(true);
  const history = useHistory();
  const [googleReCaptchaToken, setGoogleReCaptchaToken] = useState("");
  const [planformData, setPlanFormData] = useState({
    companyName: '',
    email: ''
  });
  const [phone, setPhone] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyname: "",
    comments: ""
  });
  const [touched, setTouched] = useState({
    firstname: false,
    lastname: false,
    email: false,
    companyname: false,
  });
  const validateField = (name, value) => {
    let error = "";
    if (name === "firstname" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "lastname" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "email" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }

    else if (name === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
      error = "Invalid Email Address";
    } else if (name === "companyname" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "phone" && phone == "") {
      error = "Please fill out this field";
    }
    else if (name === "phone" && !value) {

      error = "Invalid Phone Number";
    }
    return error;
  };
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true
    }));

    if (name == 'phone') {
      errors[name] = validateField('phone', isValidPhone);
    }
    else {
      errors[name] = validateField(name, formValues[name]);
    }

  };
  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyname: "",
  });
  const validateAllFields = () => {
    const newErrors = {
      firstname: validateField("firstname", formValues.firstname),
      lastname: validateField("lastname", formValues.lastname),
      email: validateField("email", formValues.email),
      companyname: validateField("companyname", formValues.companyname),
      phone: validateField("phone", isValidPhone)
    };
    return newErrors;
  };
  const handleClick = () => {
    history.push("/signin");
    setOpenContactUsModal(false);
  };
  async function handleGoogleCaptcha(value) {
    if (value) {
      setGoogleReCaptchaToken(value);
    }
  }
  useEffect(() => {
    if (userInfo) {
      setFormValues({ firstname: userInfo.data.firstName, lastname: userInfo.data.lastName, email: userInfo.data.email, companyname: userInfo.data.organizationName, comments: "" });
    }
  }, [userInfo])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newTouched = {
      firstname: true,
      lastname: true,
      email: true,
      companyname: true,
      phone: true
    };

    setTouched(newTouched);
    const newErrors = validateAllFields();
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => error === "") && isValidPhone) {
    
      if (googleReCaptchaToken) {
       const response = await axios.post(
        BASE_URL + `/api/user/verify/googlecaptcha`,
        { token: googleReCaptchaToken }
      );

      if (response.data.data === true) {
        const demoRequestObj = {
        firstname: formValues.firstname,
        lastname: formValues.lastname,
        email: formValues.email,
        companyname: formValues.companyname,
        phone: phone,
        comments: formValues.comments
      };

      setLoaded(false);
      
      await axiosApiInstance
        .post(BASE_URL + "/api/demo-request-new/add", demoRequestObj)
        .then(async (response) => {
          if (response && response.status === 200) {
            setOpenContactUsModal(false);
            setFormValues({ firstname: "", lastname: "", email: "", companyname: "", comments: "" });
            setPhone("");
            setTouched({ firstname: false, lastname: false, email: false, companyname: false });
            setErrors({ firstname: "", lastname: "", email: "", companyname: "", phone: "" });
            setLoaded(true);
            setHasContactUsModalClosed(true);
          }
        })
        .catch((error) => {
          failureAlert("Failed to add request");
          setLoaded(true);
        });
    }
     else {
      failureAlert("Please verify you are not a robot");
    }
     }
     else {
      failureAlert("Please verify you are not a robot");
    }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");


    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name == 'firstname' || name == 'lastname' ? trimmedValue.replace(/[^A-Za-z\s]/g, '') : trimmedValue
    }));
    if (touched[name]) {
      validateField(name, trimmedValue);
    }
    if (name == "email") {
      setPlanFormData(prevData => ({
        ...prevData,
        email: value
      }));
    }
    if (name == 'companyname') {
      setPlanFormData(prevData => ({
        ...prevData,
        companyName: value

      }));
    }
  };


  return (
    <>
   
   <LoadingComponent loaded={loaded} />
      <Modal modalid={modalid} toggle={toggle}>
        <div className="modal-body">
          <div
            className="close-icon"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenContactUsModal(false)}
          >
            <div className="close-btn-icon"></div>
          </div>
          <div className="flex flex-col justify-center items-center loginModal">
            <form className="form">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 w-full max-w-md">
                <div className="text-center rounded">
                  <input
                    type="text"
                    className="custom-contactus-input"
                    placeholder="First Name"
                    id="firstname"
                    name="firstname"
                    value={formValues.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled
                  />
                  {(touched.firstname || errors.firstname) && (
                    <div className="errmsg text-left text-red-500">{errors.firstname}</div>
                  )}
                </div>
                <div className="text-center rounded">
                  <input
                    type="text"
                    className="custom-contactus-input"
                    placeholder="Last Name"
                    id="lastname"
                    name="lastname"
                    value={formValues.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    
                  />
                  {(touched.lastname || errors.lastname) && (
                    <div className="errmsg text-left text-red-500">{errors.lastname}</div>
                  )}
                </div>
                <div className="text-center rounded">
                  <input
                    type="text"
                    className="custom-contactus-input"
                    placeholder="Email "
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled
                  />
                  {(touched.email || errors.email) && (
                    <div className="errmsg text-left text-red-500">{errors.email}</div>
                  )}
                </div>
                <div className="text-center rounded">
                  <PhoneInput
                    className="formPhoneInput"
                    type="numbers"
                    placeholder="Enter phone number"
                    country="in"
                    onBlur={handleBlur}
                    inputProps={{
                      name: 'phone',
                      id: 'phone'
                    }}
                    onChange={(val, country, e, formattedValue) => {
                      if (
                        country &&
                        ((country.format &&
                          formattedValue &&
                          formattedValue.length === country.format.length) ||
                          val.length <= country.dialCode.length)
                      ) {
                        setIsValidPhone(true);
                        setPhone(val);
                      } else {
                        setIsValidPhone(false);
                        setPhone(val);
                      }
                    }}
                    value={phone}
                    id="phoneNo"
                  />
                  {(touched.phone || errors.phone) && (
                    <div className="errmsg text-left text-red-500">{errors.phone}</div>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-4 mt-6 w-full max-w-md">
                <div className="text-center rounded">
                  <input
                    type="text"
                    className="custom-contactus-input"
                    placeholder="Company Name"
                    name="companyname"
                    value={formValues.companyname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    disabled
                  />
                  {(touched.companyname || errors.companyname) && (
                    <div className="errmsg text-left text-red-500">{errors.companyname}</div>
                  )}
                </div>
                <div className="text-center rounded">
                  <textarea
                    className="custom-contactus-input"
                    placeholder="Comments"
                    name="comments"
                    value={formValues.comments}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
                <div className="flex justify-center items-center">
                  <ReCAPTCHA
                    sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                    onChange={handleGoogleCaptcha}
                  />
                </div>
              </div>
              <div className="mt-2">
                <button type="button" onClick={handleSubmit} className="btn btn-primary px-6 py-2 rounded min-w-[282px]">
                  Hi, Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

    </>



  );
};

export default ContactUsModal;