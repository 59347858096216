import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { DateRange, DateRangePicker } from 'react-date-range';
import { addDays, format, startOfYear, endOfYear, subYears } from 'date-fns';
import "./rangedate.css"

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { item } from 'synonyms/dictionary';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFilter, getOrgGamesReports } from '../../../redux/actions/dateFilterAction';
import { ca } from 'date-fns/locale';


const DateRangePickerTwo = ({ filterTab, setFilterTab, startDate, endDate, filterTypeInfo }) => {

    const [open, setOpen] = useState(false);
    const [filterType, setFilterType] = useState(filterTypeInfo);
    const [range, setRange] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: 'selection'
        }
    ]);
    // const [endDate, setEndDate] = useState(null);
    // const [value, setValue] = useState(new Date());

    const [width, setWidth] = useState(window.innerWidth);


    const DateFilterValue = useSelector((state) => state.getDateFilterValue);
    const { dateFilter } = DateFilterValue;


    const dispatch = useDispatch();

    useEffect(() => {
        if (DateFilterValue && DateFilterValue?.dateFilter?.startDate && DateFilterValue?.dateFilter?.endDate) {
            setRange([{
                startDate: DateFilterValue?.dateFilter?.startDate,
                endDate: DateFilterValue?.dateFilter?.endDate,
                key: 'selection'
            }])

        }
    }, [DateFilterValue]);


    useEffect(() => {
        const callBack = async () => {
            if (width && width < 640) {
                setFilterType("Custom");
                await dispatch(getDateFilter(new Date(), new Date(), "Custom"))


            }
        }
        if (width && width < 640) {
            callBack()
        }

    }, [width])


    const handleResize = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleClose = (e) => {
        e.preventDefault();
        setFilterTab(false)
    }

    const handleFilterTypeClick = async (e, type) => {
        e.preventDefault();

        setFilterType(type);

        if (type === "Last 7 Days") {
            await dispatch(getDateFilter(new Date(), addDays(new Date(), -6), type))
        }
        else if (type === "Last 30 Days") {
            await dispatch(getDateFilter(new Date(), addDays(new Date(), -29), type))
        }
        else if (type === "Last 90 Days") {
            await dispatch(getDateFilter(new Date(), addDays(new Date(), -89), type))
        } else if (type === "Last Year") {
            const currentDate = new Date();
            const lastYear = subYears(currentDate, 1);
            const lastYearStartDate = startOfYear(lastYear); // 01 January of the last year
            const lastYearEndDate = endOfYear(lastYear);     // 31 December of the last year

            await dispatch(getDateFilter(lastYearEndDate, lastYearStartDate, type))

        }
        else{
            await dispatch(getDateFilter(new Date(), new Date(), type))
        }


    }

    function formatDateToYYYYMMDD(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        // Return in YYYY-MM-DD format
        return `${year}-${month}-${day}`;
    }

    const handleApply = async (e) => {
        e.preventDefault();
        if (range && range[0].startDate && range[0].endDate && filterType) {
            let startDate = formatDateToYYYYMMDD(range[0].startDate);
            let endDate = formatDateToYYYYMMDD(range[0].endDate);
            if (filterType === "Custom") {
                dispatch(getOrgGamesReports(startDate, endDate, filterType));
                await dispatch(getDateFilter(range[0].startDate,  range[0].endDate, filterType))
            }
            else {
                dispatch(getOrgGamesReports(endDate, startDate, filterType));

            }


            setFilterTab(false)
        }
    }

    return (
        <div className="bottom-[-488px] left-0 lg:left-[-540px] z-10 absolute flex flex-row items-start bg-white p-5 pt-0 pl-0 border w-[300px] sm:w-auto md:w-auto lg:w-auto h-auto date-container" >
            <ul className="relative bg-white w-[140px]" style={{ display: width < 640 ? 'none' : '' }}>
                <li className={`text-[16px] p-[10px] pl-[20px] font-sans hover:bg-hoverThemeColor hover:text-black ${filterType === "Last 7 Days" ? 'bg-themeColor text-white' : ''}`} onClick={(e) => handleFilterTypeClick(e, "Last 7 Days")}>Last 7 Days</li>
                <li className={`text-[16px] p-[10px] pl-[20px] font-sans hover:bg-hoverThemeColor hover:text-black ${filterType === "Last 30 Days" ? 'bg-themeColor text-white' : ''}`} onClick={(e) => handleFilterTypeClick(e, "Last 30 Days")}>Last 30 Days</li>
                <li className={`text-[16px] p-[10px] pl-[20px] font-sans hover:bg-hoverThemeColor hover:text-black ${filterType === "Last 90 Days" ? 'bg-themeColor text-white' : ''}`} onClick={(e) => handleFilterTypeClick(e, "Last 90 Days")}>Last 90 Days</li>
                <li className={`text-[16px] p-[10px] pl-[20px] font-sans hover:bg-hoverThemeColor hover:text-black ${filterType === "Last Year" ? 'bg-themeColor text-white' : ''}`} onClick={(e) => handleFilterTypeClick(e, "Last Year")}>Last Year</li>
                <li className={`text-[16px] p-[10px] pl-[20px] font-sans hover:bg-hoverThemeColor hover:text-black ${filterType === "Custom" ? 'bg-themeColor text-white' : ''}`} onClick={(e) => handleFilterTypeClick(e, "Custom")}>Custom</li>

            </ul>
            <div className="flex flex-col justify-start items-start w-[300px] sm:w-auto md:w-auto lg:w-auto h-full">
                <DateRange
                    // onChange={(item) => {
                    //     if (filterType === "Custom") {
                    //         setRange([item.selection])

                    //     }
                    // }}

                    onChange={(item) => {

                        if (filterType === "Custom") {
                            setRange([item.selection]);
                        }
                    }}
                    editableDateInputs={true}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    months={width < 1024 ? 1 : 2}
                    maxDate={new Date()}
                    minDate={
                        filterType === "Last Year"
                            ? (range[0].endDate instanceof Date && !isNaN(range[0].endDate))
                                ? range[0].endDate
                                : undefined
                            : filterType !== "Custom"
                                ? (range[0].endDate instanceof Date && !isNaN(range[0].endDate))
                                    ? range[0].endDate
                                    : undefined
                                : undefined
                    }
                    direction='horizontal'
                    className=''
                />
                {/* <DateRangePicker  showDateDisplay/> */}
                <div className="flex flex-row-reverse items-center mt-2 w-full">
                    <button className='w-[90px] btn btn-primary' onClick={handleApply}>Apply</button>
                    <button className='mr-3 btn btn-secondry' onClick={handleClose}>Close</button>

                </div>
            </div>

        </div>
    );
};

export default DateRangePickerTwo;
