import React, { useEffect, useState, useRef } from "react";
import "./modal.css";
import PhoneInput from "react-phone-input-2";
import Modal from "./modal";
import loginicon from "../../assets/images/login.svg";
import { useHistory } from "react-router-dom";
import { BASE_URL, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, axiosApiInstance, videoUrl } from "../../helpers/helper";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { ToastContainer, toast } from "react-toastify";
import img3 from '../../assets/icons/28states.jpg';
import virtual from '../../assets/icons/iconvirtual.png';
import time from '../../assets/icons/icon_time.png';
import participant from '../../assets/icons/icon_participants.png';
import { Link } from 'react-router-dom';
const IndividualGameModal = ({ modalid, toggle, setOpenModal, setSingeGameModalClosed, game }) => {

  const highlightLastWord = (text) => {
    const words = text?.split(' ');
    const lastWord = words?.pop();
    const restOfText = words?.join(' ');

    return (
      <>
        {restOfText} <span className="highlight">{lastWord}</span>
      </>
    );
  };
  const [loaded, setLoaded] = useState(true);
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 270; // Number of characters to truncate before "Read More"

  const truncatedDescription = game?.description?.length > maxLength
    ? game?.description.slice(0, maxLength) + '...'
    : game?.description;
  const formatGameType = (gameType) => {
    if (gameType === "ONLINE") {
      return "Online";
    }
    if (gameType === "ONSITE") {
      return "Onsite";
    }
    if (gameType === "HYBRID") {
      return "Hybrid";
    }
    return gameType; // Return other types as-is
  };
  return (
    <>

      <LoadingComponent loaded={loaded} />
      <Modal modalid={modalid} toggle={toggle}>
        <div className="modal-body bg-white ">
          <div
            className="close-icon"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenModal(false)}
          >
            <div className="close-btn-icon"></div>
          </div>
          <div className="flex flex-col lg:flex-row items-start p-6 space-y-4 lg:space-y-0 lg:space-x-6 modal-body-mobile1">
            {/* Image Section */}
            <div className="flex-shrink-0 w-full sm:w-32 lg:w-48">

              <img
                src={S3_BASE_URL + game?.coverMedia[1]}
                alt="Description"
                className="gameCard object-cover  h-auto rounded"
              />

            </div>

            {/* Text Section */}
            <div className="flex flex-col w-full">
              <h2 className="singlegamemodaltitle text-xl lg:text-2xl font-bold  lg:text-left">
                {highlightLastWord(game?.title)}
              </h2>

              <div className="mt-0 flex flex-wrap lg:flex-row lg:space-x-6 singleGameIconcontainer show-on-desktop">
             
                <div className="flex items-center w-1/2 lg:w-auto mb-4 lg:mb-0">
                  <img
                    src={virtual}
                    alt="Format"
                    className="h-10 w-10 rounded-full object-cover mr-2"
                  />
                  <span className="singlegamemodalitem1">
                    <span className="font-bold capitalize">Format</span>: {formatGameType(game?.gameType)}

                  </span>
                </div>

              
                <div className="flex items-center w-1/2 lg:w-auto mb-4 lg:mb-0">
                  <img
                    src={time}
                    alt="Duration"
                    className="h-10 w-10 rounded-full object-cover mr-2"
                  />
                  <span className="singlegamemodalitem1">
                    <span className="font-bold">Duration</span>: {game?.duration} Mins
                  </span>
                </div>

               
                <div className="flex items-center w-full lg:w-auto">
                  <img
                    src={participant}
                    alt="Headcount"
                    className="h-10 w-10 rounded-full object-cover mr-2"
                  />
                  <span className="singlegamemodalitem1">
                    <span className="font-bold">Headcount</span>: {game?.maxPlayers}
                  </span>
                </div>
              </div>
              <div className="flex sm:flex-row flex-col sm:flex-wrap justify-between items-center mb-3 p-0 sm:p-0 show-on-mobile">
                        <div className="flex items-center mb-4 sm:mb-0 w-full sm:w-1/3 bgPink">
                          <img src={virtual} alt="Logo 1" className="mr-1 gameLogo object-cover" />
                          <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                            <span className="font-bold">Format</span>: {formatGameType(game?.gameType)}
                          </span>
                        </div>
                        <div className="flex items-center mb-4 sm:mb-0 w-full sm:w-1/3 bgPink">
                          <img src={participant} alt="Logo 2" className="mr-1 gameLogo object-cover" />
                          <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                            <span className="font-bold">Duration</span>: {game?.duration} Mins
                          </span>
                        </div>
                        <div className="flex items-center w-full sm:w-1/3 bgPink">
                          <img src={time} alt="Logo 3" className="mr-1 gameLogo object-cover" />
                          <span className="text-[13px] text-center sm:text-left self-center singlegamemodalitem">
                            <span className="font-bold">HeadCount</span>: {game?.maxPlayers}
                          </span>
                        </div>
                      </div>

              <div className="flex flex-col items-start">
                <p className="singlemodalPara mt-4 text-justify max-w-full lg:max-w-[680px] inline">
                  {isExpanded ? game.description : truncatedDescription}

                  {/* Render "Read More" button at the end of the string inside <p> */}
                  {game?.description?.length > maxLength && (
                    <button
                      className="showMoreBtn ml-1 inline"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      {isExpanded ? 'Show Less' : 'Show More'}
                    </button>
                  )}
                </p>
              </div>

              {/* Button */}
              <div className="mt-2 text-center lg:text-left">
                {/* <button className="text-white px-4 py-2 rounded btn btn-primary w-[155px]">
                  Play Now
                </button> */}
                <div className="mt-0 text-center lg:text-left">
                  <Link
                    to={`/game-detail/${game?.id}/${game?.title.replace(/ /g, "-")}`}
                    className="text-white px-4 py-2 rounded btn btn-primary w-[155px] transition-opacity duration-300 hover:opacity-80"
                  >
                    Play Now
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Modal>



    </>



  );
};

export default IndividualGameModal;